<template>
  <div class="main-index">


    <el-descriptions class="margin-top" title="用户信息" :column="1" size="medium" border>
      <template slot="extra">
        <el-button type="warning" size="small" @click="onLogout">退出登录</el-button>
      </template>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          用户名
        </template>
        {{ userInfo.username }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          昵称
        </template>

        <WeworkUserShow type="userName" :openid="userInfo.wework_userid"></WeworkUserShow>

      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user-solid"></i>
          角色
        </template>
        {{ roleMap[userInfo.wework_role] }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-office-building"></i>
          所属企业
        </template>
        {{ companyInfo.corp_name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-s-shop"></i>
          所属行业
        </template>
        {{ companyInfo.corp_industry }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-s-claim"></i>
          所属子行业
        </template>
        {{ companyInfo.corp_sub_industry }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-location"></i>
          联系地址
        </template>
        {{ companyInfo.location }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-date"></i>
          企业授权截止时间
        </template>
        {{ companyInfo.end_date }}
        <el-button type="primary" @click="toContinueAuth" size="mini">立即续期</el-button>

      </el-descriptions-item>

    </el-descriptions>
    <el-divider></el-divider>

    <el-descriptions v-if="userInfo.wework_role === 1" class="margin-top" title="账户信息" :column="1" size="medium" border>
      <template slot="extra">
        <el-button type="warning" size="small" @click="bankShow=true">更 改 信 息</el-button>
      </template>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          当前余额
        </template>
        ￥ 7797
        <el-button type="primary" size="mini">明 细</el-button>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          账户名称
        </template>
        {{ bankInfo.name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-bank-card"></i>
          账号
        </template>
        {{ bankInfo.account }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-s-shop"></i>
          开户行
        </template>
        {{ bankInfo.bank_name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-money"></i>
          税号
        </template>
        {{ bankInfo.tax_number }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-office-building"></i>
          单位地址
        </template>
        {{ bankInfo.address }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-phone"></i>
          电话
        </template>
        {{ bankInfo.tel_number }}
      </el-descriptions-item>
    </el-descriptions>

    <el-dialog
        title="更新账户"
        :visible.sync="bankShow"
        width="98%"
        custom-class="bank-dialog"
    >
      <el-form :model="bankInfo" label-width="80px" size="mini">
        <el-form-item label="账户名称">
          <el-input v-model="bankInfo.name"></el-input>
        </el-form-item>
        <el-form-item label="账号">
          <el-input v-model="bankInfo.account"></el-input>
        </el-form-item>
        <el-form-item label="开户行">
          <el-input v-model="bankInfo.bank_name"></el-input>
        </el-form-item>
        <el-form-item label="税号">
          <el-input v-model="bankInfo.tax_number"></el-input>
        </el-form-item>
        <el-form-item label="单位地址">
          <el-input v-model="bankInfo.address"></el-input>
        </el-form-item>
        <el-form-item label="电话">
          <el-input v-model="bankInfo.tel_number"></el-input>
        </el-form-item>

      </el-form>
      <div class="tip">账户信息非常重要，所有星团与贵公司结算、开票信息均以此页面信息为准，请准确填写，确保无误！由于账户信息错误导致的打款延迟，开票错误等由贵公司自行承担。</div>
      <div slot="footer" class="dialog-footer">
        <el-button size="mini" @click="bankShow = false">取 消</el-button>
        <el-button size="mini" type="primary" @click="setUpBankInfo">确 认</el-button>
      </div>
    </el-dialog>


  </div>
</template>


<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import WeworkUserShow from "@/views/xingtuan/components/WeworkUserShow";
import {getBankInfoDataApi, postBankInfoDataApi} from "@/services/weworkApi";

export default {
  name: "ProfileIndex",
  components: {
    WeworkUserShow
  },
  data() {
    return {
      bankShow: false,
      input: '',
      roleMap: {
        0: '总裁办',
        1: '超级管理员',
        2: '管理员',
        3: '员工',
        4: '未授权人员',
      },
      bankInfo: {}

    }
  },
  computed: {
    ...mapGetters(['token', 'userInfo', 'isSupervisor']),
    ...mapState("companys", ["companyInfo"]),
  },
  created() {

  },
  mounted() {
    this.getBankInfo()

  },
  methods: {
    ...mapActions("user", ["logout"]),

    getBankInfo() {
      // postBankInfoDataApi
      getBankInfoDataApi().then(res => {
        this.bankInfo = res.data.data
      })

    },
    setUpBankInfo() {
      postBankInfoDataApi(this.bankInfo).then(res => {

        this.$message('更新成功')
        this.bankShow = false
        this.getBankInfo()
      }).catch(err => {
        this.$message.error('更新失败')
        this.bankShow = false
        this.getBankInfo()
      })
    },
    onLogout() {
      this.logout();
    },
    toContinueAuth() {
      this.$router.push({name: 'pay_for_auth'})
    }

  },
}
</script>


<style lang="scss" scoped>
.main-index {
  display: inline-block;
  max-width: 1200px;
  width: 98%;

  .margin-top {
    margin: 10px 10px;
  }

  .tip {
    font-size: 12px;
    color: #c69500;

  }

}

/deep/ .bank-dialog {
  max-width: 600px;
  border-radius: 10px;
  background-color: rgba(40, 30, 45, 0.8);

}
</style>
