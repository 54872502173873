<template>
  <div class="main-QrLoginBack">
    <!--    用户登录授权回调页面-->

    <div class="login-box">
      <div class="logo-box">
        <img src="https://img.xingtuangroup.com/web/brand2.png" alt="">
      </div>


      <div v-if="bingNeed">
        <el-row class="login-item" style="font-size: 12px;">
          <div>请绑定手机号</div>
        </el-row>

        <el-row class="login-item">
          <svg-icon iconname="icon-yonghuming" class="icon"></svg-icon>
          <el-input
              class="input"
              placeholder="手机号"
              v-model="form.username"
              clearable>
          </el-input>
        </el-row>
        <el-row class="login-item">
          <svg-icon iconname="icon-mima" class="icon"></svg-icon>
          <el-input
              class="input"
              placeholder="验证码"
              v-model="form.vcode"
              clearable>
          </el-input>
        </el-row>

        <div style="text-align: right;padding: 2px 2px;">
          <el-button style="display: inline-block;" type="primary" size="mini" @click="onSuccess">发送验证码</el-button>
        </div>

        <!--        <el-row class="login-item">-->

        <!--          <slide-verify :l="42"-->
        <!--                        :r="10"-->
        <!--                        :w="260"-->
        <!--                        :h="125"-->
        <!--                        slider-text="向右滑动发送验证码"-->
        <!--                        @success="onSuccess"-->
        <!--                        @fail="onFail"-->
        <!--                        @refresh="onRefresh"-->
        <!--          ></slide-verify>-->
        <!--          <div>{{ msg }}</div>-->
        <!--        </el-row>-->
        <!--        <el-row class="login-item" style="font-size: 10px;">-->
        <!--          <div>默认密码：123456780，请尽快修改密码！</div>-->
        <!--        </el-row>-->


        <el-row class="login-item">
          <el-button type="primary" size="mini" class="btn" @click="bindPhone">绑 定</el-button>
        </el-row>

      </div>
      <div v-if="!bingNeed">
        {{ err_msg }}


      </div>
      <div  v-if="!bingNeed">
        <el-button type="primary" size="mini" class="btn" @click="back">返 回</el-button>
      </div>


    </div>


  </div>
</template>


<script>

import {bindWeworkUser, getWeworkUserInfo} from "@/services/weworkApi";
import {sendVCodeApi} from "@/services/baseApi";

export default {
  name: "QrLoginBack",
  components: {},
  data() {
    return {
      bingNeed: false,
      err_msg: '正在登陆...',
      loading: true,
      weworkInfo: {},
      form: {
        username: '',
        vcode: '',
      },
      msg: '',
      vCode: '',
    }
  },
  computed: {},
  created() {


  },
  mounted() {

    let auth_code = this.$route.query.auth_code
    let js_code = this.$route.query.code

    if (auth_code) {
      console.log('web')
      getWeworkUserInfo({auth_code: auth_code}).then(res => {
        this.loading = false
        console.log(res.data)
        this.weworkInfo = res.data.data

        if (res.data.data.exist === 0) {
          // 绑定手机号
          this.bingNeed = true
        } else {
          this.qrLogin({
            wework_userid: res.data.data.userid,
            wework_corpid: res.data.data.corpid
          })

        }


      }).catch(err => {
        this.loading = false
        this.status = '授权出现错误'
        this.err_msg = '授权出现错误'
      })
    } else if (js_code) {
      console.log('js')
      getWeworkUserInfo({auth_code: js_code, type: 'js'}).then(res => {
        this.loading = false
        console.log(res.data)
        this.weworkInfo = res.data.data

        if (res.data.data.exist === 0) {
          // 绑定手机号
          this.bingNeed = true
        } else {
          this.qrLogin({
            wework_userid: res.data.data.userid,
            wework_corpid: res.data.data.corpid
          })

        }


      }).catch(err => {
        this.loading = false
        this.status = '授权出现错误'
        this.err_msg = '授权出现错误'
      })
    }


  },
  methods: {
    qrLogin(obj) {
      this.$store.dispatch('user/weworkQrCodeLogin', obj)
          .then(success => {
            if (success) {
              this.$router.push({
                path: '/guide'
              });
            }
          })
    },
    onSuccess() {
      if (this.form.username && this.form.username.length === 11) {
        sendVCodeApi({phone: this.form.username, new: 1}).then(res => {
          this.msg = '发送成功'
          this.$message.success('发送成功')
          this.vCode = res.data.data
        }).catch(err => {
          this.msg = err.data.data
        })
      } else {
        this.$message.error('请输入正确的手机号码')
      }


    },
    onFail() {
      this.msg = ''
    },
    onRefresh() {
      this.msg = ''
    },
    bindPhone() {
      bindWeworkUser({userinfo: this.form, weworkInfo: this.weworkInfo}).then(res => {
        this.qrLogin({
          wework_userid: res.data.data.wework_userid,
          wework_corpid: res.data.data.wework_corpid
        })
      })

    },
    back(){
      this.$router.push('/login')
    }

  },
}
</script>


<style lang="scss" scoped>
.main-QrLoginBack {
  padding: 80px 0 0 0;
}

.login-box {
  border-radius: 5px;
  max-width: 300px;
  box-shadow: rgba(11, 127, 235, 0.87) 0 0 10px inset;

  padding: 40px 20px;
  margin: 0 auto;


  .logo-box {

    text-align: center;
    height: 80px;

    img {
      display: inline-block;
      //width: 60px;
      height: 60px;
    }
  }

  .type-check {
    color: #aaa;
    height: 60px;
    line-height: 60px;
    font-size: 14px;

    span {
      cursor: pointer;
    }

    span:hover {
      color: rgb(100, 50, 120);
      transform: scale(1.1);
    }


  }

  .login-item {
    margin-top: 10px;

    .icon {
      width: 30px;
      height: 30px;
      margin: 5px 5px;
      float: left

    }

    .input {
      height: 40px;
      width: 220px;

    }

    .btn {
      background-color: rgb(0, 39, 225);
      width: 100%;
    }

  }

  .allow {
    font-size: 12px;
  }


}
</style>
