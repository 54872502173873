
import request from "@/libs/apiRequest";

export function createWxPay(data) {
    return request({
        url: "/xingtuan/wx_pay",
        method: "post",
        data: data,
    });
}

export function getOpenId(params) {
    return request({
        url: "/wx_open_id",
        method: "get",
        params: params,
    });
}


export function getOpenInfo(params) {
    return request({
        url: "/wx_open_info",
        method: "get",
        params: params,
    });
}


export function checkOrderApi(params) {
    return request({
        url: "/check_order",
        method: "get",
        params: params,
    });
}


// export function checkWxTradeStatus(params) {
//     return request({
//         url: "/xingtuan/wx_pay",
//         method: "get",
//         params: params,
//     });
// }

