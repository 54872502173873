<template>
  <div class="main-activeDes">

    <el-row class="ac">
      <div class="ac-title">活动须知：</div>
      <div class="ac-detail">
        <div v-html="content"></div>
      </div>
    </el-row>
  </div>
</template>


<script>

export default {
  name: "activeDes",
  components: {},
  data() {
    return {}
  },
  props:{
    content:{
      type: String
    }
  },
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {},
}
</script>


<style lang="scss" scoped>

  .ac {
    color: #eeeeee;


    text-align: left;
    padding: 0 10px 10px;


    .ac-title {
      font-weight: bold;
      font-size: 18px;
    }

    .ac-detail {
      font-size: 14px;


      .cell{
        display: inline-block;
        height: 40px;
      }

      .head-image{
        float: left;
        padding: 5px 5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
        }
      }
      .name{
        padding-left: 10px;
        line-height: 40px;
        font-size: 12px;
      }
      .time{
        float: right;
        font-size: 10px;
        color: #bbb;
        padding-right: 10px;
        line-height: 40px
      }


    }
    .cell-box{
      padding-bottom: 6px;
      border-bottom: 1px solid rgba(100, 100, 100, 0.3);
    }
  }
</style>
