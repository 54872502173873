<template>
  <div class="main-clientRoot">

    <div v-if="wxUserInfo">
      <clientActiveIndex v-if="page==='activity'" :wxUserInfo="wxUserInfo"></clientActiveIndex>
      <clientHome v-if="page==='index'" :wxUserInfo="wxUserInfo"></clientHome>
      <clientTable v-if="page==='table'" :wxUserInfo="wxUserInfo"></clientTable>
      <clientWifi v-if="page==='wifi'" :wxUserInfo="wxUserInfo"></clientWifi>
    </div>

    <div class="userinfo-cover" v-if="!wxUserInfo.unionid" @click="permissionGet"></div>


  </div>
</template>


<script>

import {getOpenId, getOpenInfo} from "@/services/wxApi";
import clientHome from "@/views/xingtuan/clientPages/clientHome";
import clientActiveIndex from "@/views/xingtuan/clientPages/actives/clientActiveIndex";
import clientTable from "@/views/xingtuan/clientPages/clientTable";
import clientWifi from "@/views/xingtuan/clientPages/clientWifi";

export default {
  name: "clientRoot",
  components: {
    clientHome,
    clientActiveIndex,
    clientTable,
    clientWifi
  },
  data() {
    return {
      wxUserInfo: {},

    }
  },
  computed: {
    wx_code() {
      return this.$route.query.code
    },
    page() {
      if (this.$route.path === '/client/activity') {
        return 'activity'
      } else if (this.$route.path === '/client/table') {
        return 'table'
      } else if (this.$route.path === '/client/wifi') {
        return 'wifi'
      } else {
        return 'index'
      }
    }

  },
  created() {

  },
  mounted() {

    this.wxProcess()
  },
  methods: {
    getWx() {
      if (this.wx_code) {
        getOpenId({code: this.wx_code}).then(res => {
          let access_info = res.data.data
          getOpenInfo({token: access_info.access_token, open_id: access_info.openid}).then(res => {
            this.wxUserInfo = res.data.data
          }).catch(e => {
            const local_url = window.location.href;
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3e56662e1635e163&redirect_uri=' + local_url + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'

          })
        })
      } else {
        const local_url = window.location.href;
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3e56662e1635e163&redirect_uri=' + local_url + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
      }
    },
    wxProcess() {
      console.log('wx')

      if (!this.wxUserInfo.unionid) {
        this.getWx()

      }


    },
    permissionGet() {
      this.$message.warning('需要使用服务，请先授权')
      const local_url = window.location.href;
      window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3e56662e1635e163&redirect_uri=' + local_url + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'

    }

  },
}
</script>


<style lang="scss" scoped>
.main-clientRoot {
  position: relative;
}

.userinfo-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
