import * as route from '@/router';

function hasPermission(permissions, route) {
    if (route.meta && route.meta.permissions) {
        return permissions.some(role => route.meta.permissions.indexOf(role) >= 0)
    } else {
        return true
    }
}

function filterAsyncRouter(asyncRouter, permissions) {
    const accessRouters = asyncRouter.filter(route => {
        if (hasPermission(permissions, route)) {
            if (route.children && route.children.length) {
                route.children = filterAsyncRouter(route.children, permissions)
            }
            return true
        }
        return false
    })
    return accessRouters
}

const permission = {
    namespaced: 'true',
    state: {
        router: route.constantRoutes,
        addRoutes: [],
    },
    mutations: {
        SET_ROUTERS: (state, {routers, limitedRouter}) => {
            state.addRoutes = routers;
            if (!limitedRouter) {
                state.router = route.constantRoutes.concat(routers);
            }
        },
        RESET_ROUTES: (state) => {
            state.router = route.constantRoutes;
            state.addRoutes = [];
        }
    },
    actions: {
        generateRoute({commit}, userInfo) {
            return new Promise((resolve) => {
                const permissions = userInfo.permissions;
                let accessRouters;
                if (permissions.indexOf('ADMIN') >= 0) {
                    accessRouters = route.asyncRoutes;
                    commit('SET_ROUTERS', {routers: accessRouters});
                } else {
                    accessRouters = filterAsyncRouter(route.asyncRoutes, permissions)
                    commit('SET_ROUTERS', {routers: accessRouters});
                }
                resolve();
            })
        },
        hasPermission({rootState}, permission) {
            const permissions = rootState.user.userInfo.permissions;
            if (permissions.indexOf('ADMIN') >= 0) {
                return true
            } else {
                return permissions.some(role => role == permission)
            }
        }
    }
}

export default permission
