<template>
  <div class="main-ticketDetail">

    <div class="t-name">{{ ticketInfo.ticket_info.name }}</div>
    <div class="qr-code">

      <qrCode v-show="ticketInfo.status==='已领取'" :value="ticketInfo.code" :width="120" :height="120"></qrCode>
    </div>

    <div class="ticket-code" :class="ticketInfo.status!=='已领取' ? 'del' : ''">{{ ticketInfo.code }}</div>
    <div class="ticket-status">{{ ticketInfo.status }}</div>
    <div class="ticket-time">领取时间：{{ formatDate(ticketInfo.created_at) }}</div>
    <div class="ticket-time"> 有效期:
      <span v-if="ticketInfo.active_time">{{ticketInfo.active_time}} ~ </span>
      {{ ticketInfo.dead_time }}</div>
    <el-divider></el-divider>
    <div class="ticket-des">
      使用须知：
      <div v-html="ticketInfo.ticket_info.desc">

      </div>
    </div>

  </div>
</template>


<script>

import qrCode from "@/views/xingtuan/components/qrCode";
import moment from "moment";

export default {
  name: "ticketDetail",
  components: {
    qrCode
  },
  data() {
    return {}
  },
  props: {
    ticketInfo: {
      type: Object,
      required: true
    }
  },
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {
    formatDate(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>


<style lang="scss" scoped>
.main-ticketDetail {
  text-align: center;
  color: #eeeeee;

  .t-name{
    font-weight: bold;
    font-size: 14px;
  }

  .qr-code {
    display: inline-block;
    width: 120px;
    height: 120px;
    border: 1px solid #ccc;


  }

  .ticket-time {
    text-align: left;
    font-size: 10px;
    color: #00bc8c;
  }

  .ticket-code {

    font-weight: bold;
  }

  .ticket-des {
    text-align: left;
  }
}

.del{
  text-decoration:line-through
}

</style>
