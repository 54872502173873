<template>
  <div class="main-ShopMemberPermission" v-if="userInfo.wework_role<=1">


    <el-descriptions class="margin-top" title="权限管理" :column="1" size="small" border>
      <template slot="extra">
        <el-button style="margin: 2px 10px;" size="mini" type="danger" @click="addMemberShow = true">超级管理员转让</el-button>
      </template>


      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-office-building"></i>
          超级管理员
        </template>

        <WeworkUserShow class="mtag" type="userName" :openid="userInfo.wework_userid"></WeworkUserShow>

      </el-descriptions-item>

      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          授权管理员
        </template>

        <span v-for="member in companyAdmins" :key="member">
          <el-tag
              size="mini"
              class="mtag"
              v-if="member!==userInfo.wework_userid"
          >

          <WeworkUserShow type="userName" :openid="member"></WeworkUserShow>

        </el-tag>
        </span>

        <el-button type="primary" size="mini" @click="addMemberShow = true">管 理</el-button>
      </el-descriptions-item>


    </el-descriptions>


    <!--成员管理dialog-->
    <el-dialog

        title="成员管理"
        :visible.sync="addMemberShow"
        append-to-body>
      <MemberTree v-model="companyAdmins"></MemberTree>

      <span slot="footer" class="dialog-footer">
          <el-button type="primary"  size="mini" @click="changeAdmins">确 认</el-button>
        </span>
    </el-dialog>

    <!--super dialog-->
    <el-dialog

        title="选择超级管理员"
        :visible.sync="superCheckShow"
        append-to-body>
      <MemberTree v-model="companySuper" :single="true"></MemberTree>

      <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="mini" @click="superCheckShow = false">确 认</el-button>
        </span>
    </el-dialog>


  </div>
  <div class="ban" v-else>权限不足</div>
</template>


<script>

import {mapGetters, mapState} from "vuex";
import MemberTree from "@/views/xingtuan/components/MemberTree";
import WeworkUserShow from "@/views/xingtuan/components/WeworkUserShow";
import {getWeworkPermission, postWeworkPermission} from "@/services/weworkApi";

export default {
  name: "ShopMemberPermission",
  components: {
    MemberTree,
    WeworkUserShow
  },
  data() {
    return {
      addMemberShow: false,
      superCheckShow: false,
      companyAdmins: [],
      companySuper: null

    }
  },
  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
    ...mapState("companys", ["companyInfo"]),


  },
  created() {

  },
  mounted() {
    this.getCompanyAdmins()

  },
  methods: {

    changeAdmins() {
      postWeworkPermission({members: this.companyAdmins}).then(res => {
        this.$message.success('更新成功')
      }).catch(err => {
        this.$message.error('更新失败')
      })
    },
    getCompanyAdmins() {
      getWeworkPermission({corpid: this.userInfo.wework_corpid}).then(res => {
        this.companyAdmins = res.data.data
      })
    }

  },
}
</script>


<style lang="scss" scoped>
.ban {
  text-align: center;
  padding-top: 20px;
}

.margin-top {
  padding: 10px 10px;
}

.mtag {
  margin: 0 5px;
}

.btn {
  text-align: right;
}

/deep/ .dialog-box .el-dialog {
  background-color: rgba(20, 50, 80, 0.9);
}

/deep/ .el-dialog {
  background-color: rgba(20, 50, 80, 0.9) !important;
}


</style>
