<template>
  <div class="main-DataCustomer">
    <div class="h2 title">
      粉丝群数据
    </div>
    <div class="total">
      <!--      粉丝群-->
      <groupData></groupData>


    </div>


    <div class="total">

      <el-row class="total-cell total-main table-box">
        <div class="user-list">
          <div class="btns">
            <el-button-group>
              <el-button type="primary" size="mini" @click="daysChange(7)" :disabled="days===7">最近7日</el-button>
              <el-button type="primary" size="mini" @click="daysChange(15)" :disabled="days===15">最近15日</el-button>
              <el-button type="primary" size="mini" @click="daysChange(30)" :disabled="days===30">最近30日</el-button>
            </el-button-group>
          </div>
        </div>

        <div class="customer-list">
          <el-row class=" table-box  chart-box"
          v-loading="groupDataLoading" element-loading-background="rgba(0, 80, 80, 0.5)">
            <div class="title">按时间统计：</div>
            <GroupBar
                :base-data="groupChartData"
                :padding="[50, 'auto', 70]"
                lineName=""
            ></GroupBar>

          </el-row>


        </div>

        <div  class="customer-list"
              v-loading="userGroupDataLoading" element-loading-background="rgba(0, 80, 80, 0.5)">
          <div class="user-list">
          选择成员：
          <el-radio-group v-model="currentUserId" v-loading="userLoading" @change="getCurrentUserInfo">
            <el-radio class="user-item" v-for="member in companyFollowUsers" :label="member" :key="member">
              <WeworkUserShow class="user-name" type="userName" :openid="member"></WeworkUserShow>
            </el-radio>
          </el-radio-group>


        </div>

          <el-row class=" table-box chart-box">
            <div class="title">群信息：</div>

            <el-table
                size="mini"
                :data="userGroupData"
                style="height: 320px;overflow: auto;"
            >
              <el-table-column
                  label="创建日期">
                <template slot-scope="scope">
                  {{ processTimeStamp(scope.row.create_time) }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="name"
                  label="群名称">
              </el-table-column>
              <el-table-column
                  prop="owner"
              label="群主">
              </el-table-column>
            </el-table>

            <div style="text-align: center;">
              <el-pagination

      @current-change="handleCurrentChange"
      :current-page.sync="page"
      :page-size="10"
      layout="prev, pager, next, jumper"
      :total="total">
    </el-pagination>
            </div>


          </el-row>

        </div>
      </el-row>
    </div>

  </div>
</template>


<script>

import GroupBar from "@/components/charts/GroupBar";
import {mapActions, mapGetters, mapState} from "vuex";
import groupData from "@/views/xingtuan/pages/index/components/groupData";
import {getGroupDataApi, getGroupUserDataApi} from "@/services/xingtuan";
import WeworkUserShow from "@/views/xingtuan/components/WeworkUserShow";

export default {
  name: "DataFans",
  components: {
    WeworkUserShow,
    groupData,
    GroupBar,

  },
  data() {
    return {
      days: 7,
      groupDataLoading: false,
      groupData: [],

      userLoading: false,
      currentUserId: '',
      page: 1,
      total: 0,

      userGroupDataLoading: [],
      userGroupData: [],


      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
    }
  },
  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
    ...mapState("companys", ["companyInfo", "companyFollowUsers"]),
    groupChartData() {
      let _ = []
      if (this.groupData) {
        this.groupData.forEach(item => {
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.data.chat_total,
            'name': '总计',
          })
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.data.new_chat_cnt,
            'name': '新增',
          })
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.data.chat_has_msg,
            'name': '活跃',
          })
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.data.new_member_cnt,
            'name': '新增人数',
          })
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.data.member_total,
            'name': '总人数',
          })
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.data.member_has_msg,
            'name': '活跃成员数',
          })

        })


      }
      return _
    }
  },
  created() {

  },
  mounted() {
    this.getCompanyUser()
    this.getGroupData()
    this.getCurrentUserInfo()

  },
  methods: {
    ...mapActions("companys", ["getCompanyFollowUsers"]),
    handleCurrentChange(val){
      console.log(val)
      this.page = val
      this.getCurrentUserInfo()
    },
    processTimeStamp(t) {
      const date = new Date(t * 1000);  // 参数需要毫秒数，所以这里将秒数乘于 1000
      const Y = date.getFullYear() + '-';
      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      const D = date.getDate() + ' ';
      const h = date.getHours() + ':';
      const m = date.getMinutes() + ':';
      const s = date.getSeconds();
      return Y + M + D

    },
    daysChange(d) {
      this.days = d
      this.getGroupData()
    },
    getCompanyUser() {
      this.getCompanyFollowUsers({corpid: this.userInfo.wework_corpid,})
    },
    getGroupData() {
      this.groupDataLoading = true
      getGroupDataApi({days: this.days,}).then(res => {
        this.groupData = res.data.data.group_data
      }).finally(e => {
        this.groupDataLoading = false
      })
    },
    getCurrentUserInfo(){
      // currentUserId
      this.userGroupDataLoading = true
      getGroupUserDataApi({userid: this.currentUserId, page: this.page}).then(res=>{
        this.userGroupData = res.data.data.chat_list
        this.total = res.data.data.total
      }).finally(e => {
        this.userGroupDataLoading = false
      })


    }


  },
}
</script>


<style lang="scss" scoped>

.total {
  text-align: center;
  padding-top: 20px;

  .total-cell {
    margin: 10px 10px 0;


    display: inline-block;
    width: calc(50% - 20px);

    .title {
      font-weight: bold;
      color: #00bc8c;
    }


  }

  .total-main {
    width: calc(100% - 20px);
  }


}

.btns {
  padding: 0 10px 10px;
  text-align: left;
}

.icon {
  width: 40px;
  height: 40px;
}

.cups {
  display: inline-block;
  width: 160px;
  padding: 10px 10px;

  .title {
    //height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
    color: #00EAFF;
  }

  .content {
    font-size: 24px;
  }

  .total {
    color: #67C23A;
  }

  .new {
    color: #00bc8c;
  }

  .bad {
    color: #9a6e3a;
  }


}

.user-list {

  font-size: 14px;
  padding: 10px 10px;
  text-align: left;
}

.customer-list {

  font-size: 14px;
  padding: 10px 10px;
  text-align: left;

  .total-cell {
    height: 370px;
  }
}

/deep/ .el-pagination button{
  background-color: rgba(50,50,50, 0.3);
}
/deep/ .el-pagination button:disabled{
  background-color: rgba(50,50,50, 0.3);
}
/deep/ .el-pager li{
  background-color: rgba(50,50,50, 0.2);
}
@media only screen and (max-width: 767px) {

  .chart-box {
    width: calc(100% - 20px) !important;
  }


}

</style>
