<template>
  <div class="main-NewFriendsCircle">

    <div class="group-msg table-box">
      <div>客户消息群发</div>

      <el-form :model="ruleForm"
               :rules="rules"
               size="mini"
               ref="ruleForm" label-width="100px"
               class="demo-ruleForm">


        <el-form-item label="发送成员" prop="visible_range.sender_list.user_list">
          不选择默认所有客户可见，选择成员仅成员客户可见
          <MemberTree v-model="ruleForm.visible_range.sender_list.user_list"></MemberTree>
        </el-form-item>

        <el-form-item label="朋友圈内容" prop="text.content">
          <el-input v-model="ruleForm.text.content"></el-input>
        </el-form-item>

        <el-form-item label="添加附件" prop="name">
          <el-radio-group v-model="attachments">
            <el-radio label="image">图片</el-radio>
            <el-radio label="link">链接</el-radio>
            <el-radio label="video">视频</el-radio>
          </el-radio-group>

        </el-form-item>


        <el-form-item label="图片" prop="name" v-if="attachments==='image'">
          <el-upload
              class="upload-demo"
              multiple
              :action="media_url"
              :data="{prefix: 'friends_img', corpid: userInfo.wework_corpid, type: 'image'}"
              :limit="9"
              :on-success="img_picSuccess"
              :on-progress="onProgress"
              :on-error="errFunc"
              :before-upload="beforeLicenseUpload"
              :accept="accept_type.join(',')"
              :show-file-list="false"
              list-type="picture">
            <el-button size="mini" :loading="uploading" type="primary">点击上传</el-button>
          </el-upload>

          <div class="img-box"
               v-for="(img, index) in imgMsgUrl" :key="index"
          >
            <el-image
                class="img-cell"
                :src="img.url"
                fit="contain">
            </el-image>

            <el-button
                type="text"
                size="mini"
                class="img-btn"
                icon="el-icon-delete"
                @click="removeImage(img.url)"
            ></el-button>
          </div>

        </el-form-item>

        <el-form-item label="链接" prop="link" v-if="attachments==='link'">
          图文消息标题
          <el-input v-model="linkMsg.link.title"></el-input>
          图文消息的链接
          <el-input v-model="linkMsg.link.url"></el-input>
          图片链接封面
          <el-upload
              v-if="!linkMsg.link.media_id"
              class="upload-demo"
              multiple
              :action="media_url"
              :data="{prefix: 'friends_img', corpid: userInfo.wework_corpid, type: 'image'}"
              :limit="1"
              :on-success="link_Success"
              :on-progress="onProgress"
              :on-error="errFunc"
              :accept="accept_type.join(',')"
              :show-file-list="false"
              list-type="picture">
            <el-button size="mini" :loading="uploading" type="primary">点击上传</el-button>
          </el-upload>


          <div class="img-box"
               v-if="linkMsg.link.media_id"
          >
            <el-image
                class="img-cell"
                :src="linkMsgUrl"
                fit="contain">
            </el-image>

            <el-button
                type="text"
                size="mini"
                class="img-btn"
                icon="el-icon-delete"
                @click="removeLinkImg"
            ></el-button>
          </div>


        </el-form-item>


        <el-form-item label="视频" prop="link" v-if="attachments==='video'">


          <el-upload
              v-if="!videoMsg.video.media_id"
              class="upload-demo"
              multiple
              :action="media_url"
              :data="{prefix: 'friends_img', corpid: userInfo.wework_corpid, type: 'video'}"
              :limit="1"
              :on-success="video_Success"
              :on-progress="onProgress"
              :on-error="errFunc"


              :show-file-list="false"
              list-type="picture">
            <el-button size="mini" :loading="uploading" type="primary">点击上传</el-button>
          </el-upload>

           <div class="img-box"
               v-if="videoMsg.video.media_id"
          >
            <video
                class="img-cell"
                :src="videoMsgUrl"
                >
            </video>

            <el-button
                type="text"
                size="mini"
                class="img-btn"
                icon="el-icon-delete"
                @click="removeVideo"
            ></el-button>
          </div>


        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>


    </div>

  </div>
</template>


<script>

import {mapGetters} from "vuex";
import MemberTree from "@/views/xingtuan/components/MemberTree";
import {postFriendsCircleApi} from "@/services/weworkApi";

export default {
  name: "NewFriendsCircle",
  components: {
    MemberTree
  },
  data() {
    return {
      uploading: false,
      rules: {},
      ruleForm: {
        visible_range: {
          sender_list: {
            user_list: [],
          }
        },
        text: {
          content: ''
        },
        attachments: []
      },
      attachments: '',
      imgMsg: [],
      imgMsgUrl: [],
      linkMsgUrl: '',
      linkMsg: {
        "msgtype": "link",
        "link": {
          title: '',
          url: '',
          media_id: '',
        }
      },
      videoMsgUrl: '',
      videoMsg: {
        "msgtype": "video",
        "video": {
          "media_id": ""
        }
      },
      accept_type: ['.jpg', '.png', '.bmp', '.gif', '.jpeg'],


    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    media_url() {
      return process.env.VUE_APP_API_HOST + '/api/v1/wework_media_upload'
    },
  },
  created() {

  },
  mounted() {

  },
  methods: {
    removeVideo() {
      this.videoMsg.video.media_id = ''
      this.videoMsgUrl = ''
    },
    removeLinkImg() {
      this.linkMsg.link.media_id = ''
      this.linkMsgUrl = ''
    },
    removeImage(u) {
      let _ = []
      let media_id = ''
      this.imgMsgUrl.forEach(item => {
        if (item.url !== u) {
          _.push(item)
        } else {
          media_id = item.media_id
        }
      })

      this.imgMsgUrl = _

      let __ = []
      this.imgMsg.forEach(item1 => {
        if (item1.image.media_id !== media_id) {
          __.push(item1)
        }
      })
      this.imgMsg = __


    },
    beforeLicenseUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isLt2M;
    },
    img_picSuccess(res, file) {
      this.uploading = false
      console.log(res.data)
      this.imgMsgUrl.push({
        media_id: res.data.media_id,
        url: URL.createObjectURL(file.raw)
      })

      this.imgMsg.push({
        "msgtype": "image",
        "image": {
          "media_id": res.data.media_id
        }
      })

    },
    errFunc() {

      this.uploading = false
    },
    onProgress() {
      this.uploading = true
    },
    video_Success(res, file) {
      this.uploading = false
      if (res.data.media_id) {
        this.videoMsg.video.media_id = res.data.media_id
        this.videoMsgUrl = URL.createObjectURL(file.raw)
      } else {
        this.$message.error('上传错误');
      }


    },
    link_Success(res, file) {
      this.uploading = false
      if (res.data.media_id) {
        this.linkMsg.link.media_id = res.data.media_id
        this.linkMsgUrl = URL.createObjectURL(file.raw)
      } else {
        this.$message.error('上传错误');
      }

    },
    submitForm(formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm['corpid'] = this.userInfo.wework_corpid
          if (this.attachments === 'image'){
            this.ruleForm.attachments = this.imgMsg
          }else if (this.attachments === 'link'){
            this.ruleForm.attachments = this.linkMsg
          }else if (this.attachments === 'video'){
            this.ruleForm.attachments = this.videoMsg
          }

          console.log(this.ruleForm)
          postFriendsCircleApi(this.ruleForm).then(res => {

            this.$message.success('创建成功')
            this.backIndex()

          }).catch(err => {
            this.$message.error('创建失败，请检查输入')
          })


        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    backIndex() {
      this.$emit('backIndex')
    },
  },
}
</script>


<style lang="scss" scoped>

.main-NewFriendsCircle {
  padding: 10px 10px;

  .group-msg {
    text-align: left;
    padding: 10px 10px;

    max-width: 900px;
  }


  .img-box {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    padding: 2px 2px;
    text-align: center;

    .img-cell {
      width: 100%;
      height: 100%;


    }

    .img-btn {
      position: absolute;
      left: calc(100% - 18px);
      top: calc(100% - 28px);

    }
  }

}

</style>
