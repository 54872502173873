<template>
  <div class="main-headBanner">
    <el-row>
      <el-col :xs="24" :sm="24" :md="24">
        <div class="block indexBanner">
          <el-carousel height="250px">
            <el-carousel-item v-for="(item,index) in imgList" :key="index">
              <img
                  ref="bannerHeight"
                   class="indexBannerImg"
                  :src=" 'https://img.xingtuangroup.com/'+ item"
              >
            </el-carousel-item>
          </el-carousel>
        </div>
      </el-col>
    </el-row>

  </div>
</template>


<script>

export default {
  name: "headBanner",
  components: {},
  data() {
    return {

    }
  },
  props:{
    imgList:{
      type: Array,
      default:()=>{
        return []
      }
    }
  },
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {},
}
</script>


<style lang="scss" scoped>
.main-headBanner {

  .indexBanner {
    overflow: hidden;
  }

  .indexBannerImg {
    width: 100%;
    height: auto;
  }
}

</style>
