<template>
  <div class="main-zhekou">


    <!--    列表窗口-->

    <div class="list-box" v-if="!detailShow">

      <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          class="info-table"
          :data="filterTicket"
          element-loading-background="rgba(10, 0, 30, 0.7)"
          header-row-class-name="table_title"
          header-cell-class-name="table_title_cell"
          row-class-name="table_cell"

          style="width: 100%">


        <el-table-column
            label="创建时间"
            sortable
            :formatter="formatterDate"
            prop="created_at">
        </el-table-column>
        <el-table-column
            label="卡券名称"
            prop="name">
        </el-table-column>
        <el-table-column
            label="卡券类型"
            :formatter="formatterType"
            prop="type">
        </el-table-column>
        <el-table-column
            label="门店通用"
            prop="shopall">
          <template slot-scope="scope">
            <span v-if="scope.row.shopall">是</span>
            <span v-if="!scope.row.shopall">否</span>
          </template>
        </el-table-column>
        <el-table-column
            label="卡券数量">
          <template slot-scope="scope">
            <span v-if="scope.row.countall">不限量</span>
            <span v-if="!scope.row.countall">{{ scope.row.count }}</span>
          </template>
        </el-table-column>
        <el-table-column
            label="状态">
          <template slot-scope="scope">
            <span style="color: red;" v-if="scope.row.isdeleted">已停发</span>
            <span v-if="!scope.row.isdeleted">正常</span>
          </template>
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="clickTicket(scope.row)">查看</el-button>
            <!--            <el-button type="primary">主要按钮</el-button>-->
          </template>
        </el-table-column>


      </el-table>

      <el-pagination
          style="padding-top: 10px;"

          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="ticketList.length">
      </el-pagination>

    </div>

    <div v-if="detailShow" class="detail-box table-box">
      <el-form label-position="left" inline class="demo-table-expand ">
        <el-form-item label="卡券名称">
          <span>{{ currentTicket.name }}</span>
        </el-form-item>
        <el-form-item label="创建时间">
          <span>{{ formatDate(currentTicket.created_at) }}</span>
        </el-form-item>
        <el-form-item label="卡券类型">
          <span>{{ formatterType(currentTicket) }}</span>
        </el-form-item>
        <el-form-item label="有价卡券">
          <span>{{ currentTicket.pay ? '是' : '否' }} </span>

          <span v-if="currentTicket.pay">
                  <span style="font-size:10px;color: #3cb371;display: inline-block;padding-left: 20px;"> 优惠价：</span>
                  {{ currentTicket.price }}</span>
          <span v-if="currentTicket.pay">
                  <span style="font-size:10px;color: #3cb371"> 原价：</span>
                  {{ currentTicket.old_price }}</span>

        </el-form-item>


        <el-form-item label="门店通用">
          <span v-if="currentTicket.shopall">是</span>
          <span v-if="!currentTicket.shopall">否</span>
        </el-form-item>
        <el-form-item label="发行数量">
          <span v-if="currentTicket.countall">不限量</span>
          <span v-if="!currentTicket.countall">{{ currentTicket.count }}</span>
        </el-form-item>


        <el-form-item label="有效期">
          <div v-if="currentTicket.active_type">领取后{{ formatActiveTime(currentTicket) }}有效</div>
          <div v-if="!currentTicket.active_type">
            {{ currentTicket.live_time }}~{{ currentTicket.dead_time }}
            有效
          </div>
        </el-form-item>
        <el-form-item label="卡券描述">
          <div v-html="currentTicket.desc"></div>
        </el-form-item>

        <el-form-item label="适用门店" v-if="!currentTicket.shopall">
          <div v-for="shop in currentTicket.shops" :key="shop.id">
            <el-tag>{{ shop.shop_name }}</el-tag>
          </div>
        </el-form-item>

        <!--              <el-form-item label="卡券码">-->
        <!--                <qrCode :value="createTicketCode(currentTicket.id)"></qrCode>-->
        <!--              </el-form-item>-->



      </el-form>

      <div style="padding: 10px 10px">
        <div class="tip">预览：</div>
        <ticketCell :ticket-info="currentTicket"></ticketCell>
      </div>


      <el-button

          size="mini"
          type="warning"
          @click="detailShow=false">返回
      </el-button>

      <el-button
          v-if="!currentTicket.isdeleted"
          size="mini"
          type="danger"
          @click="handleDelete(currentTicket)">停发
      </el-button>
      <el-button
          v-if="currentTicket.isdeleted"
          size="mini"
          type="danger"
          @click="restartTicket(currentTicket)">重新发布
      </el-button>

    </div>


  </div>
</template>


<script>


import {mapGetters, mapState} from "vuex";
import {deleteTicketApi, getTicketApi, postTicketApi} from "@/services/xingtuan";
import moment from "moment";
import common from "@/views/xingtuan/pages/coupon/components/common";
import ticketCell from "@/views/xingtuan/clientPages/clientComponents/ticketCell";


export default {
  name: "ticketBase",
  components: {
    ticketCell
  },
  props: {
    ticketType: {
      type: String,
      default: 'zhekou'
    }
  },
  data() {
    return {
      common: common,
      loading: false,
      detailShow: false,
      currentTicket: {},
      pageSize: 10,
      ticketList: [],
      currentPage: 1


    }
  },
  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
    ...mapState("companys", ["companyInfo"]),
    ticketMap() {
      let _ = common.ticketTypes
      let _map = {}
      _.forEach(item => {
        _map[item.value] = item.label
      })
      return _map

    },
    filterTicket() {
      return this.ticketList.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    }
  },
  created() {

  },
  mounted() {
    this.getTickets()


  },
  methods: {
    // createTicketCode(val) {
    //   return 'https://www.xingtuangroup.com/ticket?code=' + val
    // },
    clickTicket(row) {
      this.detailShow = true
      row.status = '已领取'
      row.time = '2022-01-01'
      this.currentTicket = row

    },

    handleCurrentChange(val) {
      this.currentPage = val
    },
    handleSizeChange() {

    },
    formatterDate(row, col) {
      return this.formatDate(row['created_at'])
    },
    formatterType(row, col) {
      return this.ticketMap[row['type']]
    },
    formatDate(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
    formatActiveTime(row) {
      if (row.active_time_default === 0) {
        return row.active_time + '天'
      } else {
        let _ = {
          '-1': '长期',
          '-2': '一年',
          '-3': '半年',
          '-4': '三个月',
          '-5': '一个月',

        }
        return _[row.active_time_default]
      }

    },
    getTickets() {
      this.loading = true
      getTicketApi({type: this.ticketType}).then(res => {
        this.ticketList = res.data.data
      }).finally(f => {
        this.loading = false
      })
    },

    handleEdit() {

    },
    handleDelete(row) {

      // 已停发但用户已领取的卡券只要在有效期依然有效！
      this.$confirm(' 已停发但用户已领取的卡券只要在有效期依然有效！是否继续?')
          .then(_ => {
            deleteTicketApi({id: row.id}).then(res => {
              this.$message.success('已停发')
            }).catch(err => {
              this.$message.error('操作失败')
            }).finally(r => {
              this.getTickets()
              this.detailShow=false
            })
          })
          .catch(_ => {
          });


    },
    restartTicket(row) {
      postTicketApi({id: row.id, isdeleted: false}).then(res => {
        this.$message.success('已重新发布')
      }).catch(err => {
        this.$message.error('操作失败')
      }).finally(r => {
        this.getTickets()
        this.detailShow=false
      })
    }


  },
}
</script>


<style lang="scss" scoped>
.btn {
  padding: 10px 10px;
  width: 100%;
  text-align: left;


}


.list-box {
  width: 100%;
  padding: 10px 10px;


}

.detail-box{
  max-width: 900px;
  width: 98%;
  margin: 0 auto;
  text-align: left;
  padding: 10px 10px;


}

.font-color{
  width: 20px;
  height: 20px;
  display: inline-block;

}

.tip {
  font-size: 10px;
  color: #3cb371;

}

/deep/ .el-table__expanded-cell {
  padding: 10px 10px !important;
}

/deep/ .el-pagination button:disabled {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pager li {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pagination .btn-next {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pagination .btn-prev {
  background-color: rgba(100, 10, 100, 0.1);
}
</style>
