import request from "@/libs/apiRequest";

export function loginWithUserName(data) {
    return request({
        url: "/auth/login",
        method: "post",
        data: data
    });
}

export function loginWithWeworkQrCode(data) {
    return request({
        url: "/auth/qr_wework_login",
        method: "post",
        data: data
    });
}
