<template>
  <div class="main-DataCustomer">
    <div class="h2 title">
      客户数据
    </div>
    <div class="total">
      <el-select
          style="padding: 10px 10px;"
          v-model="currentShop" clearable placeholder="请选择门店"
                 @change="getCustomerData(true)">
        <el-option
            v-for="item in shopOptions"
            :key="item.id"
            :label="item.shop_name"
            :value="item.id">
        </el-option>
      </el-select>
      <!--      客户-->
      <customerData
          :customerDataLoading="customerDataLoading"
          :customerData="customerData"
      ></customerData>


    </div>
    <div class="total">
      <div class="btns">
        <el-button-group>
          <el-button type="primary" size="mini" @click="daysChange(7)" :disabled="days===7">最近7日</el-button>
          <el-button type="primary" size="mini" @click="daysChange(15)" :disabled="days===15">最近15日</el-button>
          <el-button type="primary" size="mini" @click="daysChange(30)" :disabled="days===30">最近30日</el-button>
        </el-button-group>
      </div>


      <el-row class="total-cell table-box chart-box">
        <customerChart
            :loading="customerChartLoading"
            :customerChartData="customerChartData">

        </customerChart>

      </el-row>

      <el-row class="total-cell table-box chart-box">
        <customerExchangeChart
            :loading="customerChartLoading"
            :data="customerExchangeData">
        </customerExchangeChart>
      </el-row>
    </div>

    <div class="total">
      <div class="h3">粉丝数据</div>
      <el-row class="total-cell total-main table-box">
        <div class="user-list">
          选择成员：
          <el-radio-group v-model="currentUserId" v-loading="userLoading" @change="getCurrentUserInfo">
            <el-radio class="user-item" v-for="member in companyFollowUsers" :label="member" :key="member">
              <WeworkUserShow class="user-name" type="userName" :openid="member"></WeworkUserShow>
            </el-radio>
          </el-radio-group>


        </div>

        <div class="customer-list">
          <el-row class="total-cell table-box  chart-box"
                  v-loading="currentDataLoading" element-loading-background="rgba(0, 80, 80, 0.5)">
            <div class="title">成员客户趋势图</div>
            <GroupBar
                :base-data="currentUserChartData"
                :padding="[50, 'auto', 70]"
                lineName=""
            ></GroupBar>

          </el-row>
          <el-row class="total-cell table-box chart-box"
                  v-loading="currentDataLoading" element-loading-background="rgba(0, 80, 80, 0.5)">
            <div class="title">粉丝列表</div>

            <el-table
                size="mini"
                max-height="350"
                :data="currentData.customers"
                style="height: 320px;overflow: auto;"
            >
              <el-table-column

                  label="日期">
                <template slot-scope="scope">

                  {{ processTimeStamp(scope.row.follow_info.createtime) }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="external_contact.name"
                  label="昵称">
              </el-table-column>
              <el-table-column
                  prop="external_contact.type"
                  label="类型">
                <template slot-scope="scope">

                  {{ scope.row.external_contact.type === 1 ? '微信用户' : '企业微信用户' }}
                </template>
              </el-table-column>

              <el-table-column
                  prop="follow_info.add_way"
                  label="客户来源">
                <template slot-scope="scope">

                  {{ processFrom(scope.row.follow_info.add_way) }}
                </template>
              </el-table-column>

            </el-table>

          </el-row>

        </div>
      </el-row>
    </div>

  </div>
</template>


<script>

import GroupBar from "@/components/charts/GroupBar";
import WeworkUserShow from "@/views/xingtuan/components/WeworkUserShow";
import {mapActions, mapGetters, mapState} from "vuex";
import customerData from "@/views/xingtuan/pages/index/components/customerData";
import {getCustomerDataApi} from "@/services/xingtuan";
import customerChart from "@/views/xingtuan/pages/index/components/customerChart";
import customerExchangeChart from "@/views/xingtuan/pages/index/components/customerExchangeChart";
import {getWeworkShop} from "@/services/weworkApi";

export default {
  name: "DataCustomer",
  components: {
    customerData,
    GroupBar,
    WeworkUserShow,
    customerChart,
    customerExchangeChart

  },
  data() {
    return {

      customerDataLoading: false,
      customerChartLoading: false,
      currentDataLoading: false,
      customerData: {
        customer_count: 1000,
        new: 1000,
        release: 1000,
      },
      currentData: {
        customers: [],
        behavior: [],
      },

      currentShop: null,
      shopOptions: [],

      days: 7,

      userLoading: false,
      currentUserId: '',
      departmentUserList: [],
      currentCustomerList: [],
      tableData: [{
        date: '2016-05-02',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1518 弄'
      }, {
        date: '2016-05-04',
        name: '王小虎',
        address: '上海市普陀区金沙江路 1517 弄'
      }],

      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
    }
  },
  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
    ...mapState("companys", ["companyInfo", "companyFollowUsers"]),
    customerChartData() {
      let _ = []
      if (this.customerData.customer_active_data) {
        this.customerData.customer_active_data.forEach(item => {
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.new_contact_cnt,
            'name': '新增',
          })
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.negative_feedback_cnt,
            'name': '流失',
          })
        })
      }

      return _

    },
    customerExchangeData() {
      let _ = []
      if (this.customerData.customer_active_data) {
        this.customerData.customer_active_data.forEach(item => {
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.chat_cnt,
            'name': '聊天总数',
          })
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.message_cnt,
            'name': '发送消息数',
          })
        })
      }

      return _

    },
    currentUserChartData() {
      let _ = []
      if (this.currentData.behavior.length > 0) {
        this.currentData.behavior.forEach(item => {
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.new_contact_cnt,
            'name': '新增',
          })
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.negative_feedback_cnt,
            'name': '流失',
          })
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.chat_cnt,
            'name': '聊天总数',
          })
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.message_cnt,
            'name': '发送消息数',
          })

        })

      }
      return _

    }

  },
  created() {

  },
  mounted() {
    this.getShopOptions()
    this.getCompanyUser()
    this.getCustomerData()

  },
  methods: {
    ...mapActions("companys", ["getCompanyFollowUsers"]),
    getShopOptions() {
      getWeworkShop({corpid: this.userInfo.wework_corpid}).then(res => {
        this.shopOptions = res.data.data
      })

    },
    processTimeStamp(t) {
      const date = new Date(t * 1000);  // 参数需要毫秒数，所以这里将秒数乘于 1000
      const Y = date.getFullYear() + '-';
      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      const D = date.getDate() + ' ';
      return Y + M + D

    },
    processFrom(k) {
      let _map = {
        0: '未知来源',
        1: '扫描二维码',
        2: '搜索手机号',
        3: '名片分享',
        4: '群聊',
        5: '手机通讯录',
        6: '微信联系人',
        8: '安装第三方应用时自动添加的客服人员',
        9: '搜索邮箱',
        10: '视频号添加',
        11: '通过日程参与人添加',
        12: '通过会议参与人添加',
        13: '添加微信好友对应的企业微信',
        14: '通过智慧硬件专属客服添加',
        15: '通过上门服务客服添加',
        201: '内部成员共享',
        202: '管理员/负责人分配',
      }
      return _map[k]
    },
    getCompanyUser() {
      this.getCompanyFollowUsers({corpid: this.userInfo.wework_corpid,})

    },
    daysChange(d) {
      this.days = d
      this.getCustomerData(false)

    },
    getCustomerData(one = true) {
      if (one) {
        this.customerDataLoading = true
      }
      this.customerChartLoading = true
      getCustomerDataApi({days: this.days, shop_id: this.currentShop}).then(res => {
        this.customerData = res.data.data
      }).finally(e => {
        if (one) {
          this.customerDataLoading = false
        }
        this.customerChartLoading = false
      })
    },
    getCurrentUserInfo() {
      // currentUserId
      this.currentDataLoading = true
      getCustomerDataApi({userid: this.currentUserId,}).then(res => {
        this.currentData = res.data.data
      }).finally(e => {

        this.currentDataLoading = false
      })
    }


  },
}
</script>


<style lang="scss" scoped>

.total {
  text-align: center;
  padding-top: 20px;

  .total-cell {
    margin: 10px 10px 0;

    display: inline-block;
    width: calc(50% - 20px);

    .title {
      font-weight: bold;
      color: #00bc8c;
    }


  }

  .total-main {
    width: calc(100% - 20px);
  }


}

.btns {
  padding: 0 10px 10px;
  text-align: left;
}

.icon {
  width: 40px;
  height: 40px;
}

.cups {
  display: inline-block;
  width: 160px;
  padding: 10px 10px;

  .title {
    //height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
    color: #00EAFF;
  }

  .content {
    font-size: 24px;
  }

  .total {
    color: #67C23A;
  }

  .new {
    color: #00bc8c;
  }

  .bad {
    color: #9a6e3a;
  }


}

.user-list {

  font-size: 14px;
  padding: 10px 10px;
  text-align: left;
}

.customer-list {

  font-size: 14px;
  padding: 10px 10px;
  text-align: left;

  .total-cell {
    height: 370px;
  }
}


@media only screen and (max-width: 767px) {

  .chart-box {
    width: calc(100% - 20px) !important;
  }

}

</style>
