<template>
  <div class="main-ticketTip">
    <div>
      <div v-if="ticketInfo.active_type">
        有效期：领取后
        <span v-if="ticketInfo.active_time_default">{{ timeMap[ticketInfo.active_time_default] }}</span>
        <span v-else>{{ ticketInfo.active_time }}天</span>
        有效。
      </div>
      <div v-else>有效期：{{ticketInfo.live_time}} - {{ticketInfo.dead_time}}</div>

    </div>
    <div>
      <span v-if="ticketInfo.shopall">适用所有门店</span>
      <div v-else>
        <div>
          适用门店:
          <span v-for="(item,index) in ticketInfo.shops" :key="index">
            {{ item.shop_name }};
          </span>
        </div>
      </div>

    </div>


  </div>
</template>

<script>

export default {
  name: "ticketTip",
  components: {},
  data() {
    return {
      timeMap: {
        '-1': '长期',
        '-2': '一年',
        '-3': '半年',
        '-4': '三个月',
        '-5': '一个月',
      }
    }
  },
  props: {

    ticketInfo: {
      type: Object,
      required: true
    }
  },
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {},
}
</script>


<style lang="scss" scoped>

.main-ticketTip {
  margin: 6px 0;
  display: inline-block;
  width: 100%;
  max-width: 360px;
  padding: 10px 10px 0;
  color: #aaaaaa;
  font-size: 10px;
  text-align: left;

}
</style>
