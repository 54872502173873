<template>
  <div id="app">
    <div id="real_app">
      <router-view name="header"></router-view>
      <div id="bo">
        <FadeTransition origin="center" mode="out-in" :duration="250">
          <router-view/>
        </FadeTransition>
      </div>

      <router-view name="footer"></router-view>
    </div>

  </div>
</template>

<script>
import {FadeTransition} from "vue2-transitions";

export default {
  name: 'App',
  components: {
    FadeTransition
  }
}
</script>

<style>
#app {
  margin-top: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
  'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /*color: #2c3e50;*/
  /*color: #fff;*/
  /*background: rgba(25, 25, 25, 0.9);*/

  color: #000;
  /*background: rgba(230, 230, 240, 0.9);*/
  background: whitesmoke;


  /*background-image: url('https://img.xingtuangroup.com/web/dash-bg.png');*/
  /*background-image: url('https://img.xingtuangroup.com/img/5333b89e38c63fcc46ef333524bab785.jpg');*/
  /*background-size: cover;*/
  /*  background-repeat: no-repeat;*/
  /*background-size: 100% 1450px;*/

}

#real_app {
  /*background-color: rgba(10, 10, 10, 0.2);*/
}

#bo {
  min-height: calc(100vh - 30px);
}
</style>
