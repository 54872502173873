import {getCompanyUserApi, getWeworkCompanyInfo,} from "@/services/weworkApi";

import {router} from '@/router'

export default {
    namespaced: true,
    state: {
        userLoading: false,
        companyInfoLoading: false,
        companyInfo: {},
        companyFollowUsers: []

    },
    getters: {},
    mutations: {

        updateCompanyInfoLoading(state, data) {
            state.companyInfoLoading = data
        },
        updateUserLoading(state, data) {
            state.userLoading = data
        },
        setCompanyInfo(state, payload) {
            state.companyInfo = payload
        },
        setFollowUsers(state, payload) {
            state.companyFollowUsers = payload
        },

    },
    actions: {
        getCompanyInfo({commit, state}, query = {}) {
            commit("updateCompanyInfoLoading", true)
            getWeworkCompanyInfo(query).then(res => {
                commit("setCompanyInfo", res.data.data)
            }).catch(err=>{
                router.push('/company_auth')
            }).finally(res => {
                commit("updateCompanyInfoLoading", false)
            })
        },
        getCompanyFollowUsers({commit, state}, query = {}) {
            commit("updateUserLoading", true)
            getCompanyUserApi(query).then(res => {
                commit("setFollowUsers", res.data.data)
            }).finally(res => {
                commit("updateUserLoading", true)
            })
        }


    }
}

