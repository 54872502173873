<template>
  <div class="main-clientActiveGet">

    <!--    banner-->
    <headBanner :imgList="activityInfo.imgs"></headBanner>

    <!--koulin-->
    <div class="koulin-input">
      <el-input
          v-model="koulinInput"
          placeholder="请输入口令"
          @keyup.enter="checkInput"
          @change="checkInput"
      ></el-input>
    </div>

    <!--    ticket-->
    <div v-if="ticketShow">
      <div v-for="(item,index) in activityInfo.tickets" :key="index">
        <div @click="ticketClick(item)">
          <ticketCell :ticketInfo="item"></ticketCell>
        </div>
        <ticketTip :ticketInfo="item"></ticketTip>
        <ticketPay v-if="item.pay" :ticketInfo="item" :wxUserInfo="wxUserInfo"></ticketPay>
        <el-divider></el-divider>
      </div>
    </div>


    <!--    &lt;!&ndash;    领取人&ndash;&gt;-->
    <!--    <getUserList></getUserList>-->
    <!--    <el-divider></el-divider>-->


    <!--    活动详情-->
    <activeDes :content="activityInfo.desc"></activeDes>

    <el-dialog
        :title="currentTicket.ticket_name"
        :visible.sync="ticketDetailShow"
        width="98%"
        custom-class="ticket-dialog"
    >
      <ticketPreview :ticket-info="currentTicket" :wxUserInfo="wxUserInfo"></ticketPreview>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="ticketDetailShow = false">关 闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>


<script>

import headBanner from "@/views/xingtuan/clientPages/actives/components/headBanner";
import activeDes from "@/views/xingtuan/clientPages/actives/components/activeDes";
import ticketCell from "@/views/xingtuan/clientPages/clientComponents/ticketCell";
import ticketTip from "@/views/xingtuan/clientPages/actives/components/ticketTip";
import ticketPay from "@/views/xingtuan/clientPages/actives/components/ticketPay";
import ticketPreview from "@/views/xingtuan/clientPages/actives/components/ticketPreview";

export default {
  name: "clientActiveKouLin",
  components: {
    headBanner,
    ticketCell,
    ticketTip,
    ticketPay,
    ticketPreview,
    activeDes,

  },
  data() {
    return {
      ticketShow: false,
      ticketDetailShow: false,
      currentTicket: {},

      koulinInput: '',

    }
  },
  props: {
    activityInfo: {
      type: Object,
      required: true
    },
    wxUserInfo: {
      type: Object,
      required: true
    },
  },
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {
    checkInput(){
      if (this.koulinInput === this.activityInfo.koulin){
        this.ticketShow = true
      }else{
        this.$message.warning('请输入正确口令')
      }
    },
    ticketClick(val) {
      console.log(val)
      this.ticketDetailShow = true
      this.currentTicket = val
    },
  },
}
</script>


<style lang="scss" scoped>
.main-clientActiveGet {

  min-height: 100vh;
  background-image: linear-gradient(to bottom right, rgba(18, 1, 89, 0.7), rgba(82, 10, 53, 0.3));
  color: #222;

  .koulin-input {
    max-width: 300px;
    width: 90%;
    //display: inline-block;
    margin: 10px auto;
  }


}

/deep/ .ticket-dialog {

  border-radius: 10px;
  background-color: rgba(40, 30, 45, 0.9);

}
</style>
