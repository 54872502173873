<template>
  <div class="main-companyAuth">


    <div class="login-box">
      <div class="logo-box">
        <img src="https://img.xingtuangroup.com/web/brand2.png" alt="logo">
      </div>

      <div class="type-check">
        <span @click="loginType='phone'">请使用企业微信管理员授权</span>
      </div>


      <div>

        <el-button
            class="btn"
            type="primary"
            v-if="authUrl"
            :loading="loading"
            @click="toAuth"
        >{{ btn_msg }}
        </el-button>

      </div>

      <el-row class="login-item allow">
        <el-checkbox v-model="checked"></el-checkbox>
        阅读并接受
        <el-button type="text" size="mini">企业微信应用授权用户协议</el-button>
      </el-row>


    </div>

  </div>
</template>


<script>

import {getCompanyAuth, postPermanentCode} from "@/services/weworkApi";

export default {
  name: "companyAuth",
  components: {},
  data() {
    return {
      loading: false,
      checked: true,
      authUrl: '',
      btn_msg: '企业微信应用授权'

    }
  },
  computed: {},
  created() {


  },
  mounted() {

    let auth_code = this.$route.query.auth_code
    console.log(auth_code)

    if (auth_code) {
      this.btn_msg = '正在授权'
      this.loading = true
      postPermanentCode({auth_code: auth_code}).then(res => {
        this.loading = false
        this.btn_msg = '授权成功'
        this.$router.push('/login')

      }).catch(err => {
        this.loading = false
        this.btn_msg = '授权出现错误'
      })
    } else {
      getCompanyAuth().then(res => {
        console.log(1)
        console.log(res.data.data)
        let SUITE_ID=res.data.data.SUITE_ID
        let PRE_AUTH_CODE=res.data.data.PRE_AUTH_CODE
        let REDIRECT_URI=res.data.data.REDIRECT_URI

        this.authUrl = 'https://open.work.weixin.qq.com/3rdapp/install?suite_id='+SUITE_ID+'&pre_auth_code='
            +PRE_AUTH_CODE+'&redirect_uri='+ encodeURIComponent(REDIRECT_URI)+'&state=STATE&lang=zh'
      })
    }


  },
  methods: {
    toAuth() {
      if (this.checked) {

        window.location.href = this.authUrl
      } else {
        this.$message({
          message: '请先阅读并同意企业微信应用授权用户协议！',
          type: 'warning'
        });
      }

    }
  },
}
</script>


<style lang="scss" scoped>
.main-companyAuth {
  height: 600px;
  padding-top: 60px;

  width: 100%;
  text-align: center;

  .btn {
    display: block;
    margin: 0 auto;

  }

}


.login-box {
  border-radius: 5px;
  max-width: 300px;
  box-shadow: rgba(11, 127, 235, 0.87) 0 0 10px inset;

  padding: 40px 20px;
  margin: 0 auto;


  .logo-box {

    text-align: center;
    height: 80px;

    img {
      display: inline-block;
      width: 260px;
      height: 80px;
    }
  }

  .type-check {
    color: #aaa;
    height: 60px;
    line-height: 60px;
    font-size: 14px;

    span {
      cursor: pointer;
    }

    span:hover {
      color: rgb(100, 50, 120);
      transform: scale(1.1);
    }


  }

  .login-item {
    margin-top: 10px;

    .icon {
      width: 30px;
      height: 30px;
      margin: 5px 5px;
      float: left

    }

    .input {
      height: 40px;
      width: 220px;

    }

    .btn {
      background-color: rgb(0, 39, 225);
      width: 100%;
    }

  }

  .allow {
    font-size: 12px;
  }


}
</style>
