<template>
  <div class="main-Vip">

    <div class="list-box" v-if="pageShow==='index'">

      <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          class="info-table"
          :data="filterVips"
          element-loading-background="rgba(10, 0, 30, 0.7)"
          header-row-class-name="table_title"
          header-cell-class-name="table_title_cell"
          row-class-name="table_cell"
          style="width: 100%">


        <el-table-column
            label="创建时间"

            :formatter="formatterDate"
            prop="created_at">
        </el-table-column>
        <el-table-column
            label="客户名"
            prop="wx_name">
        </el-table-column>
        <el-table-column
            label="头像"
            prop="wx_header_img">

          <template slot-scope="scope">
            <div class="img-box"

          >
            <el-image
                class="img-cell"
                :src="scope.row.wx_header_img"
                fit="contain">
              <div slot="error" class="image-slot">
        <i class="el-icon-picture-outline"></i>
      </div>
            </el-image>

          </div>
          </template>



        </el-table-column>

        <el-table-column
            label="用户等级"
            prop="count">
          <template slot-scope="scope">
            <div style="display: inline-block;width: 100px;height: 30px;line-height: 30px;text-align:center;">
              <svg-icon :iconname="icon_map[userLevel(scope.row.use_count)]" class="icon"></svg-icon>
            </div>
          </template>
        </el-table-column>

        <!--        <el-table-column-->
        <!--            label="操作">-->
        <!--          <template slot-scope="scope">-->
        <!--&lt;!&ndash;            <el-button slot="reference" type="primary" size="mini" @click="clickVip(scope.row)">查看</el-button>&ndash;&gt;-->

        <!--            &lt;!&ndash;            <el-popconfirm&ndash;&gt;-->
        <!--            &lt;!&ndash;                title="确定删除吗？"&ndash;&gt;-->
        <!--            &lt;!&ndash;                style="padding-left: 10px"&ndash;&gt;-->
        <!--            &lt;!&ndash;                @confirm="delFriends(scope.row)"&ndash;&gt;-->
        <!--            &lt;!&ndash;            >&ndash;&gt;-->
        <!--            &lt;!&ndash;              <el-button slot="reference" type="primary" size="mini">删除</el-button>&ndash;&gt;-->
        <!--            &lt;!&ndash;            </el-popconfirm>&ndash;&gt;-->
        <!--          </template>-->
        <!--        </el-table-column>-->


      </el-table>

      <el-pagination
          style="padding-top: 10px; text-align: center"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="vipList.length">
      </el-pagination>

    </div>

  </div>
</template>


<script>

import {mapGetters} from "vuex";
import moment from "moment";
import {getVipsApi} from "@/services/weworkApi";

export default {
  name: "Vips",
  components: {},
  data() {
    return {

      loading: false,
      pageShow: 'index',
      vipList: [],
      currentPage: 1,
      pageSize: 10,
      currentVip: {},
      icon_map: {
        1: 'icon-chuangxiangqingtongvip',
        2: 'icon-chuangxiangbaiyinvip',
        3: 'icon-chuangxianghuangjinvip',
        4: 'icon-chuangxiangzijinvip',
        5: 'icon-chuangxiangzuanshivip',
        6: 'icon-icon-test',
      }

    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    filterVips() {
      return this.vipList.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)

    }
  },
  created() {

  },
  mounted() {
    this.getDatas()

  },
  methods: {
    userLevel(use_count) {
      let _ = 1 + use_count

      console.log(Math.ceil(_ / 5))
      return Math.ceil(_ / 5)

    },
    formatterDate(row, col) {
      return this.formatDate(row['created_at'])
    },
    formatDate(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
    clickVip(row) {
      this.pageShow = 'detail'
      this.currentVip = row
      console.log(this.currentVip)

    },
    getDatas() {
      getVipsApi({corpid: this.userInfo.wework_corpid}).then(res => {
        this.vipList = res.data.data
        console.log(this.vipList)
      })
    }
  },
}
</script>


<style lang="scss" scoped>
.img-box {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 40px;
  padding: 2px 2px;
  text-align: center;

  .img-cell {
    width: 100%;
    height: 100%;


  }

  .img-btn {
    position: absolute;
    left: calc(100% - 18px);
    top: calc(100% - 28px);

  }
}
</style>
