import request from "@/libs/apiRequest";


export function postCompanyLogo(data) {
    return request({
        url: "/xingtuan/company_info",
        method: "post",
        data: data
    });
}


export function getCustomerDataApi(params) {
    return request({
        url: "/xingtuan/customer",
        method: "get",
        params: params
    });
}

export function getShortUrlApi(params) {
    return request({
        url: "/xingtuan/short_url",
        method: "get",
        params: params
    });
}


export function getTicketChartDataApi(params) {
    return request({
        url: "/xingtuan/ticket_chart_data",
        method: "get",
        params: params
    });
}

export function getTicketDetailApi(params) {
    return request({
        url: "/xingtuan/ticket_detail",
        method: "get",
        params: params
    });
}


export function getGroupDataApi(params) {
    return request({
        url: "/xingtuan/group",
        method: "get",
        params: params
    });
}

export function getGroupUserDataApi(params) {
    return request({
        url: "/xingtuan/group_user",
        method: "get",
        params: params
    });
}


export function postActivePeopleApi(data) {
    return request({
        url: "/xingtuan/active_people",
        method: "post",
        data: data
    });
}

export function getActiveChartDataApi(params) {
    return request({
        url: "/xingtuan/active_people",
        method: "get",
        params: params
    });
}

export function getActiveDataApi(params) {
    return request({
        url: "/xingtuan/active_data",
        method: "get",
        params: params
    });
}

export function getTicketDataApi(params) {
    return request({
        url: "/xingtuan/ticket_data",
        method: "get",
        params: params
    });
}


export function postTicketApi(data) {
    return request({
        url: "/xingtuan/ticket",
        method: "post",
        data: data
    });
}

export function getTicketApi(params) {
    return request({
        url: "/xingtuan/ticket",
        method: "get",
        params: params
    });
}

export function postTicketCodeApi(data) {
    return request({
        url: "/xingtuan/ticket_code",
        method: "post",
        data: data
    });
}

export function deleteTicketApi(data) {
    return request({
        url: "/xingtuan/ticket",
        method: "delete",
        data: data
    });
}


