<template>

  <div id="footer">之芩文化
    <a href="https://beian.miit.gov.cn" target="_blank">沪ICP备20002466号-4</a>
  </div>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped lang="scss">

#footer {
  width: 100%;
  font-size: 12px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
</style>
