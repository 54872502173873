<template>
  <div class="main-activeTableBase">


    <!--    列表窗口-->

    <div class="list-box" v-if="detailShow==='index'">

      <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          class="info-table"
          :data="filterActive"
          element-loading-background="rgba(10, 0, 30, 0.7)"
          header-row-class-name="table_title"
          header-cell-class-name="table_title_cell"
          row-class-name="table_cell"

          style="width: 100%">


        <el-table-column
            label="创建时间"
            sortable
            :formatter="formatterDate"
            prop="created_at">
        </el-table-column>
        <el-table-column
            label="活动名称"
            prop="name">
        </el-table-column>

        <el-table-column
            label="短链接"
            prop="short_url">
        </el-table-column>
        <el-table-column
            label="活动类型"
            :formatter="formatterType"
            prop="type">
        </el-table-column>
        <el-table-column
            label="海报"
        >
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="posterTicket(scope.row)">预览海报</el-button>

          </template>
        </el-table-column>
        <el-table-column
            label="活动状态"

            prop="is_stop">
          <template slot-scope="scope">
            <el-tag type="success" v-if="!scope.row.is_stop">进行中</el-tag>
            <el-tag type="warning" v-if="scope.row.is_stop">已结束</el-tag>
          </template>
        </el-table-column>
        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="clickTicket(scope.row)">查看</el-button>
            <!--            <el-button type="primary">主要按钮</el-button>-->
          </template>
        </el-table-column>


      </el-table>

      <el-pagination
          style="padding-top: 10px;"

          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="activeList.length">
      </el-pagination>

    </div>

    <div v-if="detailShow==='detail'" class="detail-box">
      <el-form label-position="left" inline class="demo-table-expand table-box">
        <el-form-item label="活动名称">
          <span>{{ currentActive.name }}</span>
        </el-form-item>
        <el-form-item label="短链接">
          <span>{{ currentActive.short_url }}</span>
        </el-form-item>
        <el-form-item label="口令" v-if="currentActive.type==='koulin'">
          <span>{{ currentActive.koulin }}</span>
        </el-form-item>
        <el-form-item label="创建时间">
          <span>{{ formatDate(currentActive.created_at) }}</span>
        </el-form-item>
        <el-form-item label="开始时间">
          <span>{{ formatDate(currentActive.active_time) }}</span>
        </el-form-item>
        <el-form-item label="结束时间">
          <span>{{ formatDate(currentActive.dead_time) }}</span>
        </el-form-item>
        <el-form-item label="活动类型">
          <span>{{ formatterType(currentActive) }}</span>
        </el-form-item>

        <el-form-item label="活动码">
          <div class="tip">扫码直达互动页</div>
          <qrCode :value="createActiveCode(currentActive.id)"></qrCode>
        </el-form-item>
        <el-form-item label="活动状态">

          <el-tag type="success" v-if="!currentActive.is_stop">进行中</el-tag>
          <el-tag type="warning" v-if="currentActive.is_stop">已结束</el-tag>

        </el-form-item>

        <el-form-item label="活动描述">
          <div v-html="currentActive.desc"></div>
        </el-form-item>
        <div style="height: 6px;"></div>

        <el-divider></el-divider>
        <div class="title h3">关联卡券</div>
        <div v-for="(item, index) in currentActive.tickets" :key="index">
          <el-form-item label="卡券名称" width="100%">

            <span>{{ item.name }}</span>
            <span> <span class="tip"> 抽奖数量：</span> {{ currentLimit[item.id] }}</span>

          </el-form-item>
        </div>
        <el-divider></el-divider>
        <div class="title h3">关联封面</div>
        <div>
          <div class="active-img" v-for="(item, index) in currentActive.imgs" :key="index+10">

            <el-image
                style="width: 100%; height: 100%"
                :preview-src-list="processImgList(currentActive.imgs)"
                :src="'https://img.xingtuangroup.com/'+item"
                fit="contain"></el-image>

          </div>
        </div>


      </el-form>

      <el-button
          size="mini"
          type="warning"
          @click="detailShow='index'">返回
      </el-button>
      <el-button
          size="mini"
          type="primary"
          @click="handleEdit">编辑
      </el-button>
      <el-button
          v-if="!currentActive.is_stop"
          size="mini"
          type="danger"
          @click="handleDelete(currentActive)">停止活动
      </el-button>
      <el-button
          v-if="currentActive.is_stop"
          size="mini"
          type="danger"
          @click="restartActive(currentActive)">重新发布
      </el-button>
    </div>


    <div v-if="detailShow==='edit'">
      <NewActive :activeInfo="currentActive" @backPage="backPage"></NewActive>

    </div>

    <!--    posterShow-->

    <el-dialog title="海报预览" width="500px" :visible.sync="posterShow">
      <!--      currentActive-->

      <div class="poster-box">
        <div class="poster-back">

          <div class="corp-logo-box">
            <div class="corp-logo">
              <img :src="'https://img.xingtuangroup.com/'+currentActive.corpinfo.diy_logo" alt="">

            </div>
          </div>

          <div class="corp-name">
            {{ currentActive.corpname }}

          </div>

          <div class="active-name">
            {{ currentActive.name }}
          </div>


          <div class="qr-box">
            <div class="active-qrcode">
              <qrCode :value="createActiveCode(currentActive.id)" :width="120" :height="120"></qrCode>

            </div>
          </div>

          <div class="go">
            扫码立享
          </div>


        </div>


      </div>


    </el-dialog>

  </div>
</template>


<script>


import {mapGetters, mapState} from "vuex";

import moment from "moment";
import common from "@/views/xingtuan/pages/coupon/components/common";
import {deleteActivityApi, getActivityApi, postActivityApi} from "@/services/client";
import qrCode from "@/views/xingtuan/components/qrCode";
import NewActive from "@/views/xingtuan/pages/active/NewActive";


export default {
  name: "activeTableBase",
  components: {
    qrCode,
    NewActive
  },
  props: {
    activeType: {
      type: String,
      default: 'get'
    }
  },
  data() {
    return {
      common: common,
      loading: false,
      posterShow: false,
      detailShow: 'index',
      currentActive: {corpinfo: ''},
      pageSize: 10,
      activeList: [],
      currentPage: 1,


    }
  },
  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
    ...mapState("companys", ["companyInfo"]),
    activeMap() {
      let _ = common.activeTypes
      let _map = {}
      _.forEach(item => {
        _map[item.value] = item.label
      })
      return _map

    },
    currentLimit() {
      let _ = {}
      this.currentActive.limit.forEach(item => {
        _[item.ticket_id] = item.limit_count
      })
      return _

    },
    filterActive() {
      return this.activeList.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    }
  },
  created() {

  },
  mounted() {
    this.getActives()


  },
  methods: {

    backPage() {
      this.detailShow = 'index'
      this.getActives()
    },
    clickTicket(row) {
      this.detailShow = 'detail'
      this.currentActive = row

    },
    posterTicket(row) {
      this.posterShow = true
      this.currentActive = row

    },
    processImgList(l) {
      let _ = []
      l.forEach(item => {
        _.push('https://img.xingtuangroup.com/' + item)
      })
      return _

    },
    toPage(t) {
      let _map = {
        'danpin': 'coupon_danpin',
        'zhekou': 'coupon_zhekou',
        'manjian': 'coupon_manjian',
        'tuangou': 'coupon_tuangou',
      }
      if (_map[t]) {
        this.$emit('toPage', _map[t])
      }

    },
    createActiveCode(val) {
      return 'https://www.xingtuangroup.com/client/activity?activity_id=' + val
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },

    formatterDate(row, col) {
      return this.formatDate(row['created_at'])
    },
    formatterType(row, col) {

      return this.activeMap[row['type']]
    },


    formatDate(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss')
    },

    getActives() {
      this.loading = true
      getActivityApi({type: this.activeType}).then(res => {
        console.log(res.data)
        this.activeList = res.data.data
      }).finally(f => {
        this.loading = false
      })
    },

    handleEdit() {
      this.detailShow = 'edit'
    },
    handleDelete(row) {

      // 已停发但用户已领取的卡券只要在有效期依然有效！
      this.$confirm(' 将结束活动！是否继续?')
          .then(_ => {
            deleteActivityApi({id: row.id}).then(res => {
              this.$message.success('已结束活动')
            }).catch(err => {
              this.$message.error('操作失败')
            }).finally(r => {
              this.getActives()
              this.detailShow = 'index'
            })
          })
          .catch(_ => {
          });


    },
    restartActive(row) {
      row.is_stop = false
      postActivityApi({base: row}).then(res => {
        this.$message.success('已重新发布')
      }).catch(err => {
        this.$message.error('操作失败')
      }).finally(r => {
        this.getActives()
        this.detailShow = 'index'
      })
    }


  },
}
</script>


<style lang="scss" scoped>
.main-activeTableBase {
  padding-top: 20px;
}

.btn {
  padding: 10px 10px;
  width: 100%;
  text-align: left;


}


.list-box {
  width: 100%;
  padding: 10px 10px;


}

.active-img {
  display: inline-block;
  margin: 4px 6px;
  width: 180px;
  height: 180px;
}

.title {
  padding-top: 2px !important;
}

.tip {
  font-size: 10px;
  color: #3cb371;
}

.detail-box {

  max-width: 900px;
  width: 98%;
  margin: 0 auto;
  text-align: left;
  padding: 10px 10px;


}

/deep/ .el-table__expanded-cell {
  padding: 10px 10px !important;
}

/deep/ .el-pagination button:disabled {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pager li {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pagination .btn-next {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pagination .btn-prev {
  background-color: rgba(100, 10, 100, 0.1);
}

.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 100%;
}

.poster-box {
  text-align: center;
}

.poster-back {
  display: inline-block;
  width: 380px;
  height: 520px;
  background-image: url('https://img.xingtuangroup.com/web/active-backimg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;


  .corp-logo {
    margin-top: 50px;
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .corp-name {
    width: 100%;
    margin-top: 20px;
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: whitesmoke;

  }

  .active-name {
    width: 100%;
    margin-top: 20px;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    color: whitesmoke;
  }

  .active-qrcode {
    margin-top: 40px;
    display: inline-block;
    width: 120px;
    height: 120px;
  }
  .go{
    text-align: center;
    width: 100%;
    margin-top: 8px;
    display: inline-block;
    font-size: 30px;
    font-weight: bold;
    color: #c69500;
  }

}
</style>
