<template>
  <!--      客户-->
  <el-row class="main-cell table-box" v-loading="customerDataLoading"
          element-loading-background="rgba(0, 80, 80, 0.5)">
    <div class="cups">
      <div>
        <svg-icon class="icon" iconname="icon-kehu"></svg-icon>
      </div>
      <div class="title">客户总数</div>
      <div class="content">
        <ICountUp class="content total" :endVal="customerData.customer_count" :options="options"/>
      </div>

    </div>

    <div class="cups">
      <div>
        <svg-icon class="icon" iconname="icon-kehudengjiicon"></svg-icon>
      </div>
      <div class="title">昨日新增</div>
      <div class="content">
        <ICountUp class="content new" :endVal="customerData.new" :options="options"/>
      </div>

    </div>
    <div class="cups">
      <div>
        <svg-icon class="icon" iconname="icon-kehuqudaoicon"></svg-icon>
      </div>
      <div class="title">昨日流失</div>
      <div class="content">
        <ICountUp class="content bad" :endVal="customerData.release" :options="options"/>
      </div>

    </div>


  </el-row>
</template>


<script>

import ICountUp from "vue-countup-v2";
import {getCustomerDataApi} from "@/services/xingtuan";

export default {
  name: "customerData",
  components: {
    ICountUp
  },
  data() {
    return {
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      }

    }
  },
  props:{
    customerDataLoading:{
      type: Boolean,
      readOnly: true
    },customerData:{
      type: Object,
      readOnly: true
    }

  },
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {

  },
}
</script>


<style lang="scss" scoped>



  .main-cell {

    //border: 1px solid rgba(130, 0, 200, 0.2);
    //background-color: rgba(130, 0, 150, 0.2);

    display: inline-block;
    width: 100%;
    margin: 0;

    .title {
      font-weight: bold;
      color: #00bc8c;
    }


  }




.icon {
  width: 40px;
  height: 40px;
}

.cups {
  display: inline-block;
  width: 160px;
  padding: 10px 10px;

  .title {
    //height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
    color: #00EAFF;
  }

  .content {
    font-size: 24px;
  }

  .total {
    color: #67C23A;
  }

  .new {
    color: #00bc8c;
  }

  .bad {
    color: #9a6e3a;
  }


}


.detail {
  text-align: center;
  margin: 10px 10px;
  background-color: rgba(130, 0, 150, 0.2);
}


</style>
