<template>
  <div class="main-msgTable">

    <div class="list-box" v-if="detailShow==='index'">

      <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          class="info-table"
          :data="filterMsgs"
          element-loading-background="rgba(10, 0, 30, 0.7)"
          header-row-class-name="table_title"
          header-cell-class-name="table_title_cell"
          row-class-name="table_cell"

          style="width: 100%">


        <el-table-column
            label="创建时间"

            :formatter="formatterDate"
            prop="created_at">
        </el-table-column>
        <el-table-column
            label="消息类型"
            prop="type">
        </el-table-column>

        <el-table-column
            label="消息状态"
            prop="status">
        </el-table-column>

        <el-table-column
            label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="clickMsg(scope.row)">查看</el-button>
            <!--            <el-button type="primary">主要按钮</el-button>-->
          </template>
        </el-table-column>


      </el-table>

      <el-pagination
          style="padding-top: 10px; text-align: center"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="msgList.length">
      </el-pagination>

    </div>

    <div v-if="detailShow==='detail'" class="detail-box">
      <el-form label-position="left" inline class="demo-table-expand">
        <el-form-item label="创建时间">
          <span>{{ formatDate(currentMsg.created_at) }}</span>
        </el-form-item>


        <el-form-item label="消息类型">
          <span>{{ currentMsg.type }}</span>
        </el-form-item>
        <el-form-item label="消息状态">
          <span>{{ currentMsg.status }}</span>
        </el-form-item>
        <el-form-item label="发送人">
          <WeworkUserShow
                type="userName"
                :openid="currentMsg.content.sender"
            ></WeworkUserShow>
        </el-form-item>

        <el-form-item label="消息内容">
          <span>{{ currentMsg.content.text.content }}</span>
        </el-form-item>

        <el-form-item label="附件">
          <div>

            <div class="img-box"
                 v-for="(item, index) in currentMsg.content.attachments" :key="index"
            >

              <div v-if="item.msgtype==='image'">
                图文：
                <el-image

                    class="img-cell"
                    :src="item.image.pic_url"
                    fit="contain">
                </el-image>
              </div>
              <div v-if="item.msgtype==='link'">
                链接：
                <el-row>
                  <el-col :span="8">消息标题：</el-col>
                  <el-col :span="16">{{ item.title }}</el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">消息封面：</el-col>
                  <el-col :span="16">{{ item.picurl }}</el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">消息描述：</el-col>
                  <el-col :span="16">{{ item.desc }}</el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">消息链接：</el-col>
                  <el-col :span="16">{{ item.url }}</el-col>
                </el-row>
              </div>
              <div v-if="item.msgtype==='miniprogram'">
                小程序：
                <el-row>
                  <el-col :span="8">小程序标题：</el-col>
                  <el-col :span="16">{{ item.title }}</el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">小程序消息封面：</el-col>
                  <el-col :span="16">{{ item.pic_media_id }}</el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">小程序appid：</el-col>
                  <el-col :span="16">{{ item.appid }}</el-col>
                </el-row>
                <el-row>
                  <el-col :span="8">小程序page：</el-col>
                  <el-col :span="16">{{ item.page }}</el-col>
                </el-row>
              </div>
              <div v-if="item.msgtype==='video'">
                视频：
                <el-row>
                  <el-col :span="8">视频 media_id：</el-col>
                  <el-col :span="16">{{ item.media_id }}</el-col>
                </el-row>

              </div>
              <div v-if="item.msgtype==='file'">
                文件：
                <el-row>
                  <el-col :span="8">文件 media_id：</el-col>
                  <el-col :span="16">{{ item.media_id }}</el-col>
                </el-row>

              </div>


            </div>

          </div>


        </el-form-item>
        <el-form-item label="不可及名单">
          <WeworkUserShow
              v-for="(item, index) in currentMsg.fail_list"
              :key="index"
              type="userName"
              :openid="item"
          ></WeworkUserShow>
        </el-form-item>


      </el-form>

      <el-button
          size="mini"
          type="primary"
          @click="tixingSent">提醒成员发送
      </el-button>
      <el-button
          size="mini"
          type="warning"
          @click="detailShow='index'">返回
      </el-button>

    </div>


  </div>
</template>


<script>

import {mapGetters} from "vuex";
import {getGroupMsgApi, tixingGroupMsgApi} from "@/services/weworkApi";
import moment from "moment";
import WeworkUserShow from "@/views/xingtuan/components/WeworkUserShow";

export default {
  name: "msgTable",
  components: {
    WeworkUserShow
  },
  data() {
    return {

      loading: false,
      detailShow: 'index',
      msgList: [],
      currentPage: 1,
      pageSize: 10,

      currentMsg: {}
    }
  },
  computed: {
    ...mapGetters(['userInfo']),

    filterMsgs() {
      return this.msgList.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)
    }
  },
  created() {

  },
  mounted() {
    this.getGroupMsg()

  },
  methods: {
    tixingSent(){
      tixingGroupMsgApi({msgid: this.currentMsg.msgid}).then(res=>{
        this.$message.success('提醒成功')
      }).catch(err=>{
        this.$message.warning('提醒失败')
      })

    },
    formatterDate(row, col) {
      return this.formatDate(row['created_at'])
    },
    formatDate(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
    clickMsg(row) {
      this.detailShow = 'detail'
      this.currentMsg = row


    },
    getGroupMsg() {
      getGroupMsgApi({corpid: this.userInfo.wework_corpid}).then(res => {
        this.msgList = res.data.data
      })
    }

  },
}
</script>


<style lang="scss" scoped>

.main-msgTable {
  padding: 10px 10px;
  text-align: left;


  .img-box {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    padding: 2px 2px;
    text-align: center;

    .img-cell {
      width: 100%;
      height: 100%;


    }

    .img-btn {
      position: absolute;
      left: calc(100% - 18px);
      top: calc(100% - 28px);

    }
  }
}

/deep/ .el-table__expanded-cell {
  padding: 10px 10px !important;
}

/deep/ .el-pagination button:disabled {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pager li {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pagination .btn-next {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pagination .btn-prev {
  background-color: rgba(100, 10, 100, 0.1);
}
</style>
