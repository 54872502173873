import Vue from 'vue';
import App from './App.vue';


//引入 iconfont
import "@/assets/vendor/iconfont/iconfont.js";

import store from './store';
// Import Bootstrap and BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import $ from "jquery";
import BootstrapVue, {IconsPlugin} from "bootstrap-vue";

//全局注册组件
import SvgIcon from "@/components/SvgIcon.vue";
import {router} from "./router";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import '@/assets/element-variables.scss';
import '@/assets/public.scss';

Vue.config.productionTip = false;
window.router = router;

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
window.$ = $;
ElementUI.Tooltip.props.effect.default = 'light';
Vue.component("svg-icon", SvgIcon);


Vue.use(ElementUI);

import SlideVerify from 'vue-monoplasty-slide-verify';

Vue.use(SlideVerify);


import "froala-editor/css/froala_editor.pkgd.min.css";
import "froala-editor/css/froala_style.min.css";
import "froala-editor/js/froala_editor.pkgd.min.js";
import "froala-editor/js/languages/zh_cn.js";
import "froala-editor/js/plugins.pkgd.min.js";
import VueFroala from "vue-froala-wysiwyg";

Vue.use(VueFroala);


const Bus = new Vue();

let app = new Vue({
    data: {eventBus: Bus},
    router,
    store,
    render: h => h(App)
}).$mount("#app");

window.eventBus = Bus;//注册全局事件对象

export default app;
