<template>
  <div class="main-clientWifi"
       :style="'background-image: url(\' ' +backImg+ '\')'"
  >

    <div class="main-box">
      <div class="shop-img">
        <svg-icon class="icon" iconname="icon-diancan"></svg-icon>
      </div>

      <div class="pt60 pb40 f20 main-t">欢迎光临</div>
      <div class="pb40 f20 main-t">{{ tableInfo.shop_info.shop_name }}</div>

      <div style="text-align: center;">

        <div v-show="is_exists">

          <wx-open-launch-weapp
              id="launch-btn"
              appid="wxd6f7c7d6b97b55a1"
              :path="tableInfo.mini_page"
          >
            <script type="text/wxtag-template">
              <button class="btn"
                      style="
                      padding: 10px 30px;
                      background-color: #00ff80;
                      color: white;
                      font-weight: bold;
                      border: none;
                      border-radius: 10px;
                      font-size: 16px;
                      text-align: center;
                      text-decoration: none;
                      cursor: pointer;
                      transition: background-color 0.3s ease;
                    "
                      type="primary"
                      size="mini"
              >连接WiFi
              </button>
            </script>

          </wx-open-launch-weapp>

        </div>


        <div v-if="!is_exists" class="main-ac">识别下方二维码点餐</div>

        <div v-if="!is_exists" class="img-box">
          <el-image
              class="img-cell"
              :src="tableInfo.qr_code"
              style="width: 100%; height: 100%"
              fit="contain">
          </el-image>
        </div>
      </div>

    </div>


  </div>


</template>


<script>
import {getWifiUserStatusApi} from "@/services/table";
import {getWxPayConfigApi} from "@/services/weworkApi";

export default {
  name: "clientWifi",
  components: {},
  data() {
    return {
      is_exists: true,
      tableInfo: {shop_info: {}},
      backImg: '/img/table_back.jpg',
    }
  },
  props: {

    wxUserInfo: {
      type: Object,
      required: true
    },
  },
  computed: {
    wifi_id() {
      return this.$route.query.wifi_id
    },
  },
  created() {


  },
  mounted() {
    this.getUserStatus()
    this.weworkInit()

  },
  watch: {
    wxUserInfo() {
      this.getUserStatus()
    }
  },
  methods: {

    async weworkInit() {
      getWxPayConfigApi({url: window.location.href}).then(res => {
        this.config(res.data)
      })

    },
    async config(config) {
      wx.config(config);
      wx.ready(function () {
        //config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
      });
      wx.error(function (res) {
        console.log('res', res);
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      })


    },

    getUserStatus() {
      if (!this.wifi_id) {
        this.$message.error('二维码已失效')
      } else {
        if (this.wxUserInfo.openid && this.wxUserInfo.unionid) {
          getWifiUserStatusApi({
            user_id: this.wxUserInfo.openid,
            unionid: this.wxUserInfo.unionid,
            wifi_id: this.wifi_id
          }).then(res => {
            this.is_exists = res.data.data.is_exists
            this.tableInfo = res.data.data
            if (res.data.data.back_img) {
              this.backImg = res.data.data.back_img
            }
          })
        }

      }

    }

  },
}
</script>


<style lang="scss" scoped>

.main-clientWifi {

  min-height: calc(100vh - 30px);

  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  text-align: center;
  position: relative;



  .main-box {
    background-color: rgba(10, 10, 10, 0.5);
    min-height: calc(100vh - 30px);
  }


  .shop-img {
    display: inline-block;
    margin-top: 140px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;


  }

  .main-t {
    font-weight: bold;
    color: #c69500;
  }


  .main-ac {
    color: #ccc;
  }

  .table-name {
    color: #00ff80;
  }

  .img-box {
    display: inline-block;
    width: 120px;
    height: 120px;
  }
}

</style>
