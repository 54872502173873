<template>
  <div class="main-clientActiveIndex">
    <el-row class="header">
      <el-col :span="20" class="back">
        <!--        <svg-icon iconname="icon-back3" class="icon"></svg-icon>-->
      </el-col>
      <!--      <el-col :span="18" class="title"> </el-col>-->
      <el-col :span="4" class="home">
        <el-button @click="toIndex" type="text">
          <svg-icon iconname="icon-home" class="icon"></svg-icon>
        </el-button>

      </el-col>
    </el-row>

    <!--    活动结束蒙版-->
    <div class="stop-box" v-if="stopBox">
      活动已结束
    </div>

    <div v-if="wxUserInfo">
      <clientActiveGet v-if="ActiveType==='get'"
                       :activityInfo="activityInfo"
                       :wxUserInfo="wxUserInfo"
      ></clientActiveGet>
      <clientActiveKouLin v-if="ActiveType==='koulin'" :activityInfo="activityInfo"
                          :wxUserInfo="wxUserInfo"></clientActiveKouLin>
      <clientActiveChouJiang v-if="ActiveType==='choujiang'" :activityInfo="activityInfo"
                             :wxUserInfo="wxUserInfo"></clientActiveChouJiang>
      <clientActivePingShouQi v-if="ActiveType==='ping'" :activityInfo="activityInfo"
                              :wxUserInfo="wxUserInfo"></clientActivePingShouQi>
      <clientActiveMiaoSha v-if="ActiveType==='miaosha'" :activityInfo="activityInfo"
                           :wxUserInfo="wxUserInfo"></clientActiveMiaoSha>

    </div>

  </div>
</template>


<script>

import {getActivityApi} from "@/services/client";
import clientActiveGet from "@/views/xingtuan/clientPages/actives/clientActiveGet";
import clientActiveKouLin from "@/views/xingtuan/clientPages/actives/clientActiveKouLin";
import clientActiveChouJiang from "@/views/xingtuan/clientPages/actives/clientActiveChouJiang";
import clientActivePingShouQi from "@/views/xingtuan/clientPages/actives/clientActivePingShouQi";
import clientActiveMiaoSha from "@/views/xingtuan/clientPages/actives/clientActiveMiaoSha";
import {postActivePeopleApi} from "@/services/xingtuan";


export default {
  name: "clientActiveIndex",
  components: {
    clientActiveGet,
    clientActiveKouLin,
    clientActiveChouJiang,
    clientActivePingShouQi,
    clientActiveMiaoSha

  },
  data() {
    return {
      ActiveType: null,
      activityInfo: null,
      ActiveInfo: {},

    }
  },
  props: {

    wxUserInfo: {
      type: Object,
      required: true
    },
  },
  watch: {
    activeId() {
      this.getActive()
    }
  },
  computed: {

    activeId() {
      return this.$route.query.activity_id
    },

    stopBox() {
      if (this.activityInfo) {
        return this.activityInfo.status === 'stop' && this.activityInfo.type !== 'choujiang';
      } else {
        return false
      }

    }

  },
  created() {

  },
  mounted() {
    this.getActive()
    this.postActivePeople()



  },
  methods: {

    toIndex() {
      this.$router.push({name: 'client_home'})

    },
    getActive() {
      getActivityApi({id: this.activeId}).then(res => {
        this.activityInfo = res.data
            document.title =  this.activityInfo.corpname
        this.ActiveType =  this.activityInfo.type
      })
    },
    postActivePeople() {
      postActivePeopleApi({
        activity_id: this.activeId,
        user_info: this.wxUserInfo,
      })

    }
  },
}
</script>


<style lang="scss" scoped>
.main-clientActiveIndex {
  min-height: 100vh;
  background-image: linear-gradient(to bottom right, rgba(18, 1, 89, 0.7), rgba(82, 10, 53, 0.3));
  color: #222;
  position: relative;

  .header {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    color: #cccccc;
    height: 36px;
    line-height: 36px;
    text-align: center;
    padding: 0 4px;


    .back {
      text-align: left;
      padding-top: 3px;
      cursor: pointer;
    }

    .title {
      text-align: center;
      font-weight: bold;
    }

    .home {
      text-align: right;
      padding-top: 0px;
      cursor: pointer;

      &:hover {
        color: #0d8092;
      }
    }

    .icon {

      width: 30px;
      height: 30px;


    }
  }

}


/deep/ .el-divider--horizontal {
  margin: 10px 0;
}

.stop-box {
  width: 100%;
  height: calc(100vh - 250px);
  color: #cccccc;
  padding-top: 10px;
  position: absolute;
  background-color: rgba(50, 0, 50, 0.7);
  top: 250px;
  left: 0;
  z-index: 100;
}

</style>
