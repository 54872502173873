<template>
  <div class="main-CustomerQrCode">

    <div class="active-box text-left">
      <el-button type="primary" size="mini" @click="showPage='add'" v-if="showPage==='table'">新增</el-button>
      <el-button type="warning" size="mini" @click="showPage='table'" v-if="showPage==='add'">返回</el-button>
      <el-button type="warning" size="mini" @click="downloadShow=true">导出点餐码</el-button>
    </div>
    <div class=" add-box text-left box-shadow1" v-if="showPage==='add'">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
               size="mini" label-width="100px" class="demo-ruleForm">
        <el-form-item label="桌号名称" prop="table_name">
          <el-input v-model="ruleForm.table_name"></el-input>
        </el-form-item>

        <el-form-item label="封面图" prop="back_img">
          <el-upload
              v-if="ruleForm.back_img===''"
              class="upload-demo"
              :action="file_url"
              :data="{prefix: 'customer_img'}"
              :limit="1"
              :on-success="backSuccess"
              :on-progress="onProgress"
              :on-error="errFunc"
              :file-list="back_pics"
              :accept="accept_type.join(',')"
              :show-file-list="false"
              list-type="picture">
            <el-button size="mini" :loading="uploading" type="primary">点击上传</el-button>
          </el-upload>
          <div class="img-box"
               v-if="ruleForm.back_img"
          >
            <el-image
                class="img-cell"
                :src="'https://img.xingtuangroup.com/'+ruleForm.back_img"
                fit="contain">
            </el-image>

            <el-button
                type="text"
                size="mini"
                class="img-btn"
                icon="el-icon-delete"
                @click="removeBackImage"
            ></el-button>
          </div>
        </el-form-item>


        <el-form-item label="门店" prop="shop_id">
          <el-select v-model="ruleForm.shop_id" placeholder="请选择门店">

            <el-option v-for="(item,index) in shopOptions"
                       :key="index"
                       :label="item.shop_name" :value="item.id"></el-option>

          </el-select>
        </el-form-item>
        <el-form-item label="引流成员" prop="corp_user_id">
          <el-button size="mini" type="primary" @click="newShowManagerShow=true">选择引流成员</el-button>
        </el-form-item>

        <el-form-item label="欢迎语" prop="welcome_message">
          <el-input v-model="ruleForm.welcome_message"></el-input>
        </el-form-item>
        <el-form-item label="点餐类型" prop="type">
          <el-radio-group v-model="ruleForm.type">
            <el-radio label="H5"></el-radio>
            <el-radio label="小程序"></el-radio>

          </el-radio-group>
        </el-form-item>


        <el-form-item label="点餐URL" prop="url" required error="请填写点餐URL" v-if="ruleForm.type==='H5'">
          <el-input v-model="ruleForm.url"></el-input>
        </el-form-item>

        <el-form-item label="小程序点餐码" prop="mini_qr_code" v-if="ruleForm.type!=='H5'">
          <el-upload
              v-if="ruleForm.mini_qr_code===''"
              class="upload-demo"
              :action="file_url"
              :data="{prefix: 'customer_img'}"
              :limit="1"
              :on-success="qrCodeSuccess"
              :on-progress="onProgress"
              :on-error="errFunc"
              :file-list="code_pics"
              :accept="accept_type.join(',')"
              :show-file-list="false"
              list-type="picture">
            <el-button size="mini" :loading="uploading" type="primary">点击上传</el-button>
          </el-upload>
          <div class="img-box"
               v-if="ruleForm.mini_qr_code"
          >
            <el-image
                class="img-cell"
                :src="'https://img.xingtuangroup.com/'+ruleForm.mini_qr_code"
                fit="contain">
            </el-image>

            <el-button
                type="text"
                size="mini"
                class="img-btn"
                icon="el-icon-delete"
                @click="removeCodeImage"
            ></el-button>
          </div>
        </el-form-item>

        <el-form-item label="小程序URL" prop="mini_url" required error="请填写小程序URL" v-if="ruleForm.type!=='H5'">
          <el-input v-model="ruleForm.mini_url"></el-input>
          <div class="tip">小程序请先关联到企业微信应用，小程序必须在微信公众平台通过审核和发布的，否则发送会失败</div>
        </el-form-item>
        <el-form-item label="小程序标题" prop="mini_title" required error="请填写小程序标题" v-if="ruleForm.type!=='H5'">
          <el-input v-model="ruleForm.mini_title"></el-input>
        </el-form-item>
        <el-form-item label="小程序appid" prop="mini_appid" required error="请填写小程序appid" v-if="ruleForm.type!=='H5'">
          <el-input v-model="ruleForm.mini_appid" placeholder="必须是关联到企业微信的小程序应用"></el-input>
        </el-form-item>
        <el-form-item label="小程序page" prop="mini_page" required error="请填写小程序page" v-if="ruleForm.type!=='H5'">
          <el-input v-model="ruleForm.mini_page"></el-input>
        </el-form-item>
        <el-form-item label="小程序封面图" prop="mini_cover" v-if="ruleForm.type!=='H5'">
          <el-upload
              v-if="ruleForm.mini_cover===''"
              class="upload-demo"
              :action="file_url"
              :data="{prefix: 'customer_img'}"
              :limit="1"
              :on-success="coverSuccess"
              :on-progress="onProgress"
              :on-error="errFunc"
              :file-list="cover_pics"
              :accept="accept_type.join(',')"
              :show-file-list="false"
              list-type="picture">
            <el-button size="mini" :loading="uploading" type="primary">点击上传</el-button>
          </el-upload>
          <div class="img-box"
               v-if="ruleForm.mini_cover"
          >
            <el-image
                class="img-cell"
                :src="'https://img.xingtuangroup.com/'+ruleForm.mini_cover"
                fit="contain">
            </el-image>

            <el-button
                type="text"
                size="mini"
                class="img-btn"
                icon="el-icon-delete"
                @click="removeCoverImage"
            ></el-button>
          </div>
        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>

    </div>

    <div v-if="showPage==='table'">
      <el-table

          :data="showTableData"
          style="width: 100%">
        <el-table-column
            prop="shop_info.shop_name"
            label="门店"
            width="180">
        </el-table-column>
        <el-table-column
            prop="table_name"
            label="桌号"
            width="180">
        </el-table-column>
        <el-table-column
            prop="corp_user_id"
            label="引流成员"
        >
          <template slot-scope="scope">
            <WeworkUserShow type="userName" :openid="scope.row.corp_user_id"></WeworkUserShow>

          </template>

        </el-table-column>

        <el-table-column
            prop="type"
            label=类型
        >
        </el-table-column>
        <el-table-column
            prop="user_num"
            label=引流好友数
        >
        </el-table-column>
        <el-table-column
            prop="del_num"
            label=流失数
        >
        </el-table-column>

        <el-table-column
            prop=""
            label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="readClick(scope.row)">查看</el-button>
            <el-button type="primary" size="mini" @click="editClick(scope.row)">编辑</el-button>

            <el-popconfirm
                title="确定删除吗？"
                style="padding-left: 10px"
                @confirm="delClick(scope.row)"
            >

              <el-button slot="reference" type="primary" size="mini">删除</el-button>

            </el-popconfirm>
          </template>

        </el-table-column>
      </el-table>

      <el-pagination
            style="padding-top: 10px;"

            @current-change="handleCurrentChange"
            :current-page.sync="currentPage"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="tableData.length">
        </el-pagination>
    </div>


    <div v-if="showPage==='detail'">
      <div style="text-align: left;">

        <el-button type="primary" @click="showPage='table'" size="mini">返回</el-button>

      </div>


      <el-form label-position="left" inline class="demo-table-expand table-box">
        <el-form-item label="桌号名称">
          <span>{{ currentTable.table_name }}</span>
        </el-form-item>
        <el-form-item label="封面图">
          <div class="img-box"
               v-if="currentTable.back_img"
          >
            <el-image
                class="img-cell"
                :src="'https://img.xingtuangroup.com/'+currentTable.back_img"
                fit="contain">
            </el-image>
          </div>
          <div v-else>无封面图</div>
        </el-form-item>
        <el-form-item label="门店">
          <el-select v-model="currentTable.shop_id" placeholder="">

            <el-option v-for="(item,index) in shopOptions"
                       :key="index"
                       :label="item.shop_name" :value="item.id"></el-option>

          </el-select>
        </el-form-item>
        <el-form-item label="引流成员">
          <WeworkUserShow type="userName" :openid="currentTable.corp_user_id"></WeworkUserShow>

        </el-form-item>
        <el-form-item label="欢迎语">
          <span>{{ currentTable.welcome_message }}</span>
        </el-form-item>
        <el-form-item label="点餐类型">
          <span>{{ currentTable.type }}</span>
        </el-form-item>
        <el-form-item label="引流好友数">
          <span>{{ currentTable.user_num }}</span>
        </el-form-item>
        <el-form-item label="流失数">
          <span>{{ currentTable.del_num }}</span>
        </el-form-item>
        <div v-if="currentTable.type==='H5'">
          <el-form-item label="点餐URL">
            <span>{{ currentTable.url }}</span>
          </el-form-item>
        </div>
        <div v-else>
          <el-form-item label="小程序点餐码">
            <div class="img-box"
                 v-if="currentTable.mini_qr_code"
            >
              <el-image
                  class="img-cell"
                  :src="'https://img.xingtuangroup.com/'+currentTable.mini_qr_code"
                  fit="contain">
              </el-image>

            </div>
            <div v-else>无小程序点餐码</div>
          </el-form-item>
          <el-form-item label="小程序URL">
            <span>{{ currentTable.mini_url }}</span>
          </el-form-item>
          <el-form-item label="小程序标题">
            <span>{{ currentTable.mini_title }}</span>
          </el-form-item>
          <el-form-item label="小程序appid">
            <span>{{ currentTable.mini_appid }}</span>
          </el-form-item>
          <el-form-item label="小程序page">
            <span>{{ currentTable.mini_page }}</span>
          </el-form-item>
          <el-form-item label="小程序封面图">
            <div class="img-box"
                 v-if="currentTable.mini_cover"
            >
              <el-image
                  class="img-cell"
                  :src="'https://img.xingtuangroup.com/'+currentTable.mini_cover"
                  fit="contain">
              </el-image>

            </div>
            <div v-else>无小程序封面图</div>
          </el-form-item>


        </div>

        <el-form-item label="系统点餐码">
          <qrCode :value="createTableCode(currentTable.id)"></qrCode>
        </el-form-item>


      </el-form>
    </div>

    <el-dialog
        title="选择引流成员"
        :visible.sync="newShowManagerShow"
        append-to-body>
      <MemberTree :single="true" v-model="ruleForm.corp_user_id"></MemberTree>

      <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="mini" @click="newShowManagerShow = false">完 成</el-button>
        </span>
    </el-dialog>


    <el-dialog
        title="导出点餐码"
        :visible.sync="downloadShow"
        append-to-body>

      <div class="cover-list">

        <div>请选择模板：</div>
        <el-radio-group v-model="qrCodeModelId">


          <div class="cover-box">
            <el-image
                class="img-cell"
                src="/qr/qr1.jpg"
                fit="contain">
            </el-image>

            <el-radio :label="1"></el-radio>
          </div>


          <div class="cover-box">
            <el-image
                class="img-cell"
                src="/qr/qr2.jpg"
                fit="contain">
            </el-image>

            <el-radio :label="2"></el-radio>
          </div>
          <div class="cover-box">
            <el-image
                class="img-cell"
                src="/qr/qr3.jpg"
                fit="contain">
            </el-image>

            <el-radio :label="3"></el-radio>
          </div>
          <div class="cover-box">
            <el-radio :label="0">不使用模板，只下载二维码</el-radio>
          </div>

          <div style="text-align: center">
            <el-button type="warning" size="mini" @click="downloadQrCode">立 即 下 载</el-button>

          </div>
        </el-radio-group>


      </div>


      <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="mini" @click="downloadShow = false">关 闭</el-button>
        </span>
    </el-dialog>

  </div>
</template>


<script>

import {getWeworkShop} from "@/services/weworkApi";
import MemberTree from "@/views/xingtuan/components/MemberTree";
import {delCustomerQrCodeApi, getCustomerQrCodeApi, postCustomerQrCodeApi} from "@/services/customer";
import WeworkUserShow from "@/views/xingtuan/components/WeworkUserShow";
import qrCode from "@/views/xingtuan/components/qrCode";
import {mapGetters} from "vuex";

export default {
  name: "CustomerTable",
  components: {
    qrCode,
    MemberTree,
    WeworkUserShow
  },
  data() {
    return {
      uploading: false,
      newShowManagerShow: false,
      downloadShow: false,
      currentPage: 1,
      pageSize: 10,
      qrCodeModelId: 1,
      back_pics: [],
      code_pics: [],
      cover_pics: [],
      accept_type: ['.jpg', '.png', '.bmp', '.gif', '.jpeg'],
      showPage: 'table',
      shopOptions: [],
      currentTable: {},
      ruleForm: {
        table_name: '',
        back_img: '',
        shop_id: '',
        corp_user_id: '',
        tip_id: '',
        welcome_message: '',
        type: 'H5',
        url: '',
        mini_qr_code: '',
        mini_url: '',
        mini_title: '',
        mini_appid: '',
        mini_page: '',
        mini_cover: ''
      },
      rules: {
        table_name: [
          {required: true, message: '请输入桌号名称', trigger: 'blur'},
          {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
        ],
        shop_id: [
          {required: true, message: '请选择门店', trigger: 'change'}
        ],
        corp_user_id: [
          {required: true, message: '请选择引流成员', trigger: 'change'}
        ],
        type: [
          {required: true, message: '请选择点餐方式', trigger: 'change'}
        ],

      },
      tableData: []

    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    file_url() {
      return process.env.VUE_APP_API_HOST + '/api/v1/oss_upload'
    },
    showTableData() {
      return this.tableData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)

    },
  },
  created() {

  },
  mounted() {
    this.getShopOptionsData()
    this.getData()
  },
  methods: {
handleCurrentChange(val) {
      this.currentPage = val

    },
    downloadQrCode() {
      window.location.href = process.env.VUE_APP_API_HOST + '/api/v1/xingtuan/download_qr_code?id='
          + this.qrCodeModelId + '&corpid=' + this.userInfo.wework_corpid


    },
    createTableCode(val) {
      return 'https://www.xingtuangroup.com/client/table?table_id=' + val
    },
    readClick(row) {
      this.showPage = 'detail'
      this.currentTable = row
    },
    editClick(row) {
      this.showPage = 'add'
      this.ruleForm = row
    },
    delClick(row) {
      delCustomerQrCodeApi(this.ruleForm).then(res => {
        this.$message.success('删除成功')
        this.showPage = 'table'
      }).catch(err => {
        this.$message.success('删除失败,请检查相关信息状态或重试')
      })

    },
    getData() {
      getCustomerQrCodeApi().then(res => {
        this.tableData = res.data.data
      })
    },
    getShopOptionsData() {
      getWeworkShop().then(res => {
        this.shopOptions = res.data.data
      })

    },
    errFunc() {
      this.$message.error('上传错误');
      this.uploading = false
    },
    onProgress() {
      this.uploading = true
    },
    removeBackImage() {
      this.ruleForm.back_img = ''
    },
    removeCodeImage() {
      this.ruleForm.mini_qr_code = ''
    },
    removeCoverImage() {
      this.ruleForm.mini_cover = ''
    },

    backSuccess(res, file) {
      this.ruleForm.back_img = res.data.key
      this.uploading = false
    },
    qrCodeSuccess(res, file) {
      this.ruleForm.mini_qr_code = res.data.key
      this.uploading = false
    },
    coverSuccess(res, file) {
      this.ruleForm.mini_cover = res.data.key
      this.uploading = false
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          postCustomerQrCodeApi(this.ruleForm).then(res => {
            this.$message.success('创建成功')
            this.showPage = 'table'
          }).catch(err => {
            this.$message.success('创建失败,请检查相关信息状态或重试')
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
}
</script>


<style lang="scss" scoped>
.main-CustomerQrCode {
  padding: 10px 10px;

  .active-box {

    padding: 10px 10px;
  }

  .add-box {
    width: 96%;
    max-width: 600px;
    margin: 2% 2%;
    padding: 10px 10px;
    border: 1px solid rgba(100, 10, 120, 0.8);
  }


  .img-box {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    padding: 2px 2px;
    text-align: center;

    .img-cell {
      width: 100%;
      height: 100%;


    }

    .img-btn {
      position: absolute;
      left: calc(100% - 18px);
      top: calc(100% - 28px);

    }
  }

}


/deep/ .dialog-box .el-dialog {
  background-color: rgba(20, 50, 80, 0.9);
}

/deep/ .el-dialog {
  background-color: rgba(20, 50, 80, 0.9) !important;
}

.tip {
  font-size: 12px;
  color: #c69500;

}

.demo-table-expand {
  text-align: left;
  max-width: 900px;
}


.cover-box {
  position: relative;
  display: inline-block;
  width: 220px;
  height: 220px;
  padding: 2px 2px;
  text-align: center;

  .img-cell {
    width: 100%;
    height: 100%;
  }


}


/deep/ .el-pagination button:disabled {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pager li {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pagination .btn-next {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pagination .btn-prev {
  background-color: rgba(100, 10, 100, 0.1);
}
</style>
