
import request from "@/libs/apiRequest";





export function getActivityApi(params) {
    return request({
        url: "/xingtuan/activity",
        method: "get",
        params: params
    });
}

export function getWxActiveStatusApi(params) {
    return request({
        url: "/xingtuan/activity_wx_status",
        method: "get",
        params: params
    });
}


export function postWxActiveStatusApi(data) {
    return request({
        url: "/xingtuan/activity_wx_status",
        method: "post",
        data: data
    });
}


export function postActivityApi(data) {
    return request({
        url: "/xingtuan/activity",
        method: "post",
        data: data
    });
}



export function deleteActivityApi(data) {
    return request({
        url: "/xingtuan/activity",
        method: "delete",
        data: data
    });
}

export function getActiveWxUserApi(params) {
    return request({
        url: "/xingtuan/activity_wx_user",
        method: "get",
        params: params
    });
}


export function postActiveWxUserApi(data) {
    return request({
        url: "/xingtuan/activity_wx_user",
        method: "post",
        data: data
    });
}



export function openActivityApi(data) {
    return request({
        url: "/xingtuan/open_activity",
        method: "post",
        data: data
    });
}



export function getUserTicketApi(params) {
    return request({
        url: "/xingtuan/user_ticket",
        method: "get",
        params: params
    });
}

export function getUserOrderApi(params) {
    return request({
        url: "/xingtuan/user_order",
        method: "get",
        params: params
    });
}



export function hexiaoApi(data) {
    return request({
        url: "/xingtuan/hexiao",
        method: "post",
        data: data
    });
}


