<template>

  <div class="qr-box" :style="'width: '+ width +'px;height: '+ height +'px;'">
    <div class="ticket_code"  ref="qrCodeDiv"></div>
  </div>


</template>


<script>

import QRCode from "qrcodejs2";

export default {
  name: "qrCode",
  components: {

  },
  props:{
    value:{
      type: String,
      default: ''
    },
    width:{
      type: Number,
      default: 160
    },
    height:{
      type: Number,
      default: 160
    },
    colorDark:{
      type: String,
      default: '#333333'
    },
    colorBack:{
      type: String,
      default: '#ffffff'
    },

  },
  data() {
    return {

    }
  },
  computed: {


  },
  created() {

  },
  mounted() {
    this.bindQRCode()
  },
  methods: {
    bindQRCode() {
      setTimeout(() => {
        new QRCode(this.$refs.qrCodeDiv, {
          text: this.value,
          width: this.width-12,
          height: this.height-12,
          colorDark: this.colorDark, //二维码颜色
          colorLight: this.colorBack, //二维码背景色
          correctLevel: QRCode.CorrectLevel.L//容错率，L/M/H
        })
      }, 200)

    },

  },
}
</script>


<style lang="scss" scoped>
.qr-box{

  padding:6px 6px;
  overflow: hidden;
  background-color: white;
}
.ticket_code{
  margin: 0;



}
</style>
