import { render, staticRenderFns } from "./ActiveChoujiang.vue?vue&type=template&id=555ba1a3&scoped=true&"
import script from "./ActiveChoujiang.vue?vue&type=script&lang=js&"
export * from "./ActiveChoujiang.vue?vue&type=script&lang=js&"
import style0 from "./ActiveChoujiang.vue?vue&type=style&index=0&id=555ba1a3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "555ba1a3",
  null
  
)

export default component.exports