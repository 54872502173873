<template>
  <div class="main-friendsCircle">
    <el-button v-if="!newShow" type="primary" size="mini" @click="newShow=true">新建</el-button>
    <el-button v-if="newShow" type="primary" size="mini" @click="newShow=false">返回</el-button>
    <NewFriendsCircle v-if="newShow" @backIndex="backIndex"></NewFriendsCircle>
    <FriendsCircleTable v-if="!newShow"></FriendsCircleTable>


  </div>
</template>


<script>

import NewFriendsCircle from "@/views/xingtuan/pages/we/components/NewFriendsCircle";
import FriendsCircleTable from "@/views/xingtuan/pages/we/components/FriendsCircleTable";

export default {
  name: "friendsCircle",
  components: {
    NewFriendsCircle,
    FriendsCircleTable
  },
  data() {
    return {
      newShow: false

    }
  },
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {
    backIndex() {
      this.newShow = false
    }
  },
}
</script>


<style lang="scss" scoped>
.main-friendsCircle {
  text-align: left;
  padding: 10px 10px;
}
</style>
