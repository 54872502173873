<template>
  <div class="main-Index">
    <el-container>
      <el-aside class="side-menu" width="260px">

        <div class="logo-box">
          <div class="logo"><img src="https://img.xingtuangroup.com/img/%E6%98%9F%E5%9B%A2-10.png" alt=""></div>

        </div>

        <div class="menu-box">
          <el-collapse class="side-menu-box" v-model="activeName">
            <el-collapse-item :title="item.label" :name="item.name" v-for="item in showMenu" :key="item.name">
              <div class="menu-item"
                   :class="{active : activeBox===cell.value}"
                   v-for="cell in item.menu"
                   @click="activeBox=cell.value"
                   :key="cell.value">
                {{ cell.label }}
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>

      </el-aside>
      <el-main class="main-body">
        <div class="header">
          <div class="header-left">
            <el-dropdown>
              <span class="el-dropdown-link">
                <i class="el-icon-menu"></i><i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu class="dropdown" slot="dropdown">
                <el-collapse class="side-menu-box" v-model="activeName" accordion>
                  <el-collapse-item :title="item.label" :name="item.name" v-for="item in showMenu" :key="item.name">
                    <div class="menu-item"
                         :class="{active : activeBox===cell.value}"
                         v-for="cell in item.menu"
                         @click="activeBox=cell.value"
                         :key="cell.value">
                      {{ cell.label }}
                    </div>
                  </el-collapse-item>
                </el-collapse>
              </el-dropdown-menu>
            </el-dropdown>

          </div>


          <div class="header-right">


            <!--            <img class="user" :src="userInfo.head_img" alt="" @click="activeBox='profile'">-->
            <!--            <span class="user username" @click="activeBox='profile'">{{ userInfo.username }}</span>-->
            <span class="welcome">欢迎您！</span>
            <span @click="activeBox='profile'">
              <WeworkUserShow class="user username" type="userName"
                              :openid="userInfo.wework_userid"></WeworkUserShow>
            </span>


            <span class="user username end-time" @click="activeBox='profile'">企业授权截止时间 {{
                companyInfo.end_date
              }}</span>
          </div>

        </div>

        <div class="main">


          <!--主页-->
          <WeworkIndex v-if="activeBox==='index_index'" @toPage="toPage"></WeworkIndex>
          <DataAll v-if="activeBox==='index_data'"></DataAll>
          <IndexGuide v-if="activeBox==='index_guide'"></IndexGuide>


          <!--卡券-->
          <NewTicket v-if="activeBox==='coupon_new'"></NewTicket>
          <ZheKou v-if="activeBox==='coupon_zhekou'" @toPage="toPage"></ZheKou>
          <ManJian v-if="activeBox==='coupon_manjian'" @toPage="toPage"></ManJian>
          <DaiJing v-if="activeBox==='coupon_daijin'" @toPage="toPage"></DaiJing>
          <DanPin v-if="activeBox==='coupon_danpin'" @toPage="toPage"></DanPin>
          <TuanGou v-if="activeBox==='coupon_tuangou'" @toPage="toPage"></TuanGou>
          <Hexiao v-if="activeBox==='coupon_hexiao'" @toPage="toPage"></Hexiao>

          <!--活动-->
          <NewActive v-if="activeBox==='active_new'" @toPage="toPage"></NewActive>
          <ActiveGet v-if="activeBox==='active_get'" @toPage="toPage"></ActiveGet>
          <ActiveMiaosha v-if="activeBox==='active_miaosha'" @toPage="toPage"></ActiveMiaosha>
          <ActiveChoujiang v-if="activeBox==='active_choujiang'" @toPage="toPage"></ActiveChoujiang>
          <ActivePing v-if="activeBox==='active_pingshouqi'" @toPage="toPage"></ActivePing>
          <ActiveKoulin v-if="activeBox==='active_koulin'" @toPage="toPage"></ActiveKoulin>


          <!--引流拓客-->
          <Vips v-if="activeBox==='vip'"></Vips>
          <CustomerTable v-if="activeBox==='customer_table'"></CustomerTable>
          <CustomerWifi v-if="activeBox==='customer_wifi'"></CustomerWifi>
          <!--门店管理-->
          <ShopIndex v-if="activeBox==='shop'"></ShopIndex>
          <ShopMemberPermission v-if="activeBox==='shop_member'"></ShopMemberPermission>

          <!--企微-->
          <GroupIndex v-if="activeBox==='group_sent'"></GroupIndex>
          <friendsCircle v-if="activeBox==='customer_circle'"></friendsCircle>
          <GroupWelcome v-if="activeBox==='group_welcome'"></GroupWelcome>
          <CustomerWelcome v-if="activeBox==='customer_welcome'"></CustomerWelcome>
          <LinkMeCode v-if="activeBox==='linkme_code'"></LinkMeCode>

          <!--数据分析-->
          <DataCustomer v-if="activeBox==='data_customer'"></DataCustomer>
          <DataFans v-if="activeBox==='data_fans'"></DataFans>
          <DataTicket v-if="activeBox==='data_ticket'"></DataTicket>
          <DataActive v-if="activeBox==='data_active'"></DataActive>

          <!--我的信息-->
          <ProfileIndex v-if="activeBox==='profile'"></ProfileIndex>


        </div>


      </el-main>
    </el-container>
  </div>
</template>


<script>

import {mapActions, mapGetters, mapState} from "vuex";
import ProfileIndex from "@/views/xingtuan/pages/profile/ProfileIndex";
import ShopIndex from "@/views/xingtuan/pages/shop/ShopIndex";
import WeworkIndex from "@/views/xingtuan/pages/index/WeworkIndex";

import WeworkUserShow from "@/views/xingtuan/components/WeworkUserShow";
import ShopMemberPermission from "@/views/xingtuan/pages/shop/ShopMemberPermission";
import ZheKou from "@/views/xingtuan/pages/coupon/ZheKou";
import ManJian from "@/views/xingtuan/pages/coupon/ManJian";
import DaiJing from "@/views/xingtuan/pages/coupon/DaiJing";
import DanPin from "@/views/xingtuan/pages/coupon/DanPin";
import TuanGou from "@/views/xingtuan/pages/coupon/TuanGou";
import NewTicket from "@/views/xingtuan/pages/coupon/NewTicket";
import DataAll from "@/views/xingtuan/pages/index/DataAll";
import DataCustomer from "@/views/xingtuan/pages/dataAnalysis/DataCustomer";
import DataFans from "@/views/xingtuan/pages/dataAnalysis/DataFans";
import DataTicket from "@/views/xingtuan/pages/dataAnalysis/DataTicket";
import DataActive from "@/views/xingtuan/pages/dataAnalysis/DataActive";
import IndexGuide from "@/views/xingtuan/pages/index/IndexGuide";
import ActiveGet from "@/views/xingtuan/pages/active/ActiveGet";
import NewActive from "@/views/xingtuan/pages/active/NewActive";
import ActiveMiaosha from "@/views/xingtuan/pages/active/ActiveMiaosha";
import ActiveChoujiang from "@/views/xingtuan/pages/active/ActiveChoujiang";
import ActivePing from "@/views/xingtuan/pages/active/ActivePing";
import ActiveKoulin from "@/views/xingtuan/pages/active/ActiveKoulin";
import Hexiao from "@/views/xingtuan/pages/coupon/Hexiao";
import {getOpenDataConfigApi} from "@/services/weworkApi";
import Vue from 'vue'
import GroupIndex from "@/views/xingtuan/pages/we/GroupIndex";
import CustomerTable from "@/views/xingtuan/pages/customer/CustomerTable";
import friendsCircle from "@/views/xingtuan/pages/we/friendsCircle";
import GroupWelcome from "@/views/xingtuan/pages/we/GroupWelcome";
import CustomerWelcome from "@/views/xingtuan/pages/we/CustomerWelcome";
import LinkMeCode from "@/views/xingtuan/pages/we/LinkMeCode";
import CustomerWifi from "@/views/xingtuan/pages/customer/CustomerWifi";
import Vips from "@/views/xingtuan/pages/customer/Vips";



export default {
  name: "XingIndex",
  components: {
    WeworkIndex,
    DataAll,
    IndexGuide,

    NewTicket,
    ZheKou,
    ManJian,
    DaiJing,
    DanPin,
    TuanGou,
    Hexiao,

    NewActive,
    ActiveGet,
    ActiveMiaosha,
    ActiveChoujiang,
    ActivePing,
    ActiveKoulin,

    Vips,
    CustomerTable,
    CustomerWifi,

    GroupIndex,
    CustomerWelcome,
    friendsCircle,
    GroupWelcome,
    LinkMeCode,


    ProfileIndex,
    ShopIndex,
    WeworkUserShow,
    ShopMemberPermission,
    DataCustomer,
    DataFans,
    DataTicket,
    DataActive
  },
  data() {
    return {
      baseConfig: null,
      agentConfig: null,
      activeStatus: null,
      activeName: '1',
      activeBox: 'index_index',
      menuList: [
        {
          label: '首页', name: '1', menu: [
            {label: '企业主页', value: 'index_index'},
            {label: '数据总览', value: 'index_data', permission: 'admin'},
            // {label: '平台指引', value: 'index_guide'},

          ]
        },
        {
          label: '卡券管理', name: '2', permission: 'clerk', menu: [
            {label: '新增卡券', value: 'coupon_new', permission: 'admin'},
            {label: '折扣券', value: 'coupon_zhekou', permission: 'admin'},
            {label: '满减券', value: 'coupon_manjian', permission: 'admin'},
            // {label: '代金券', value: 'coupon_daijin', permission: 'admin'},
            {label: '单品券', value: 'coupon_danpin', permission: 'admin'},
            {label: '团购券', value: 'coupon_tuangou', permission: 'admin'},
            {label: '卡券核销', value: 'coupon_hexiao', permission: 'clerk'},

          ]
        },
        {
          label: '活动管理', name: '3', permission: 'admin', menu: [
            {label: '创建活动', value: 'active_new'},
            {label: '直接领取', value: 'active_get'},
            {label: '定点秒杀', value: 'active_miaosha'},
            {label: '抽奖活动', value: 'active_choujiang'},
            {label: '拼手气', value: 'active_pingshouqi'},
            {label: '口令活动', value: 'active_koulin'},
            // {label: '九宫格', value: 'active_nine'},

          ]
        },

        {
          label: '企微管理', name: '4', permission: 'admin', menu: [
            // {label: '客户管理', value: 'customer'},
            // {label: '客户生命周期', value: 'customer_zhouqi'},
            {label: '好友欢迎语', value: 'customer_welcome'},
            {label: '群发朋友圈', value: 'customer_circle'},
            // {label: '客户标签', value: 'customer_tag'},
            // {label: '客户专属券', value: 'customer_ticket'},

            // {label: '客户群管理', value: 'group'},
            // {label: '客户消息群发', value: 'customer_group_msg'},
            {label: '客户消息群发', value: 'group_sent'},
            // {label: '添加入群欢迎语', value: 'group_welcome'},
            // {label: '联系我二维码创建', value: 'linkme_code'},
            // {label: '群抽奖', value: 'group_choujiang'},

          ]
        },
        {
          label: '引流拓客', name: '5', permission: 'admin', menu: [
            {label: '卡券客户管理', value: 'vip'},
            {label: 'wifi 引流', value: 'customer_wifi'},
            {label: '扫码点餐引流', value: 'customer_table'},
            // {label: '渠道引流', value: 'customer_pipe'},
            // {label: '拼团引流', value: 'customer_tuan'},
            // {label: '抽奖引流', value: 'customer_y'},
            // {label: '引流码', value: 'customer_m'},

          ]
        },

        {
          label: '数据分析', name: '6', permission: 'admin', menu: [
            {label: '客户', value: 'data_customer'},
            {label: '粉丝群', value: 'data_fans'},
            {label: '卡券', value: 'data_ticket'},
            {label: '活动', value: 'data_active'},

          ]
        },
        {
          label: '门店管理', name: '7', permission: 'admin', menu: [
            {label: '门店', value: 'shop'},
            {label: '成员继承', permission: 'admin', value: 'member_extend'},


          ]
        },
        {
          label: '我的信息', name: '8', menu: [
            {label: '我的信息', value: 'profile'},
            {label: '应用成员权限', permission: 'super', value: 'shop_member'},


          ]
        },

      ],

    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapGetters('wxClient', [['baseConfig', 'agentConfig']]),
    ...mapState("companys", ["companyInfo"]),
    showMenu() {

      let _new = []
      this.menuList.forEach(f_item => {
        if (this.checkPermission(f_item.permission)) {
          let c_item = {
            label: f_item.label,
            name: f_item.name,
            menu: [],
          }
          f_item.menu.forEach(m_item => {
            if (this.checkPermission(m_item.permission)) {
              c_item.menu.push(m_item)
            }
          })
          _new.push(c_item)
        }
      })
      return _new
    }

  },
  created() {


  },
  mounted() {
    this.initConfig()
    this.checkAuth()
    // 获取企业账号激活状态

    this.getCompanyInfo({corpid: this.userInfo.wework_corpid})

  },
  methods: {

    ...mapActions("companys", ["getCompanyInfo"]),

    initConfig() {
      let ua = window.navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        getOpenDataConfigApi({
          url: window.location.href,
          corpid: this.userInfo.wework_corpid,
          type: 'base'
        }).then(res => {
          this.baseConfig = res.data

          Vue.prototype.$wxBaseConfig = this.baseConfig;

          getOpenDataConfigApi({
            url: window.location.href,
            corpid: this.userInfo.wework_corpid,
          }).then(res => {
            this.agentConfig = res.data
            Vue.prototype.$wxAgentConfig = this.agentConfig;
            this.processConfig()

          })
        })

      }


    },
    async processConfig() {
      let ua = window.navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        await this.config(this.baseConfig)
      }

    },
    async config(config) {

      return new Promise((resolve, reject) => {
        console.info('wx.config', config)
        wx.config(config)
        wx.ready(resolve)
        wx.error(reject)
      }).then(() => {
        console.info('wx.ready')


      }, error => {
        console.error('wx.error', error)
        throw error
      })
    },

    checkPermission(val) {
      const permission_map = {
        'me': 0,
        'super': 1,
        'admin': 2,
        'clerk': 3,
      }
      if (val) {
        return this.userInfo.wework_role <= permission_map[val]
      } else {
        return true
      }


    },

    toPage(val) {
      this.activeBox = val
    },

    checkAuth() {
      // 检查登陆者角色和公司状态赋予权限
      console.log(this.userInfo)

      if (new Date(this.companyInfo.end_date) < new Date()) {
        this.$message({
          message: '当前企业授权已过期，请自助或联系您的服务人员续期。',
          type: 'warning'
        });
        setTimeout(() => {
          this.$router.push('/guide')
        }, 2000)


      } else {
        if (this.userInfo.wework_role == 4) {

          this.$message({
            message: '您的账号未获取企业超级管理员授权，请联系您所在企业超级管理员进行授权。',
            type: 'warning'
          });

          setTimeout(() => {
            this.$router.push('/guide')
          }, 2000)

        }


      }


    },


  },
}
</script>


<style lang="scss" scoped>

/deep/ .title {
  padding-top: 20px;
  font-weight: bold;
  color: #111;
}

.side-menu {
  height: calc(100vh - 30px);
  overflow: auto;
  background-color: rgba(0, 20, 20, 0.1);
}

.main-body {
  height: calc(100vh - 30px);
  overflow: auto;
}

.logo-box {
  height: 160px;

  .logo {
    width: 100%;

    img {
      width: 100%;
      padding: 20px 70px;
    }

  }
}

.menu-box {

  padding: 5px 10px;


  .side-menu-box {

  }


}

.menu-item {

  height: 40px;
  line-height: 40px;
  text-align: center;

  cursor: pointer;

  &:hover {
    font-weight: bold;
    color: #0d8092;
    transform: scale(1.1);
  }

}

.active {

  color: #3cb371;
  font-weight: bold;
  font-size: 16px;
}

.main-body {
  padding: 0 0 0 5px;


  .header {


    height: 50px;
    line-height: 50px;
    background-color: rgba(0, 100, 100, 0.1);

    .header-left {
      display: none;
      text-align: left;
      padding: 0 10px;
    }

    .header-right {
      display: inline-block;
      width: 100%;
      text-align: right;
      padding: 0 10px;

      .user {
        cursor: pointer;

        &:hover {
          color: #0d8092;
          transform: scale(1.1);
        }
      }

      .welcome {
        font-size: 10px;
      }


      .username {
        display: inline-block;
        padding: 0 10px;

        &:hover {
          color: #0d8092;
          transform: scale(1.1);
        }

      }

      .end-time {
        font-size: 10px
      }
    }

    img {
      width: 30px;
      height: 30px;
      border-radius: 50%;
    }
  }

  .main {
    //background-color: rgba(0, 0, 0, 0.2);
    min-height: calc(100vh - 80px);
    text-align: center;
  }
}


/deep/ .el-collapse-item {
  .el-collapse-item__header {
    padding: 0 10px;
    color: #111;
    border-color: rgba(222, 222, 222, 0.2);
    background-color: rgba(0, 40, 40, 0.1);
  }

  .el-collapse-item__wrap {
    background-color: rgba(0, 40, 40, 0.1);
    border-bottom: 1px solid rgba(222, 222, 222, 0.2);
  }

  .el-collapse-item__content {
    color: #111;
    background-color: rgba(0, 40, 40, 0.1);
  }

}

/deep/ .el-collapse {
  border-top: 1px solid rgba(222, 222, 222, 0.2);
  border-bottom: 1px solid rgba(222, 222, 222, 0.2);
}

/deep/ .el-dropdown-menu {

  background-color: rgba(0, 40, 40, 0.1) !important;
}

.dropdown {
  width: 220px;
  min-height: 408px;
  max-height: 600px;
  overflow: auto;
  border-color: rgba(222, 222, 222, 0.2);
  background-color: rgba(0, 40, 40, 0.1) !important;


}


@media only screen and (max-width: 767px) {
  .side-menu {
    display: none;
  }

  .main-body {
    padding: 0 0 0 0;

    .header-left {
      display: inline-block !important;
      width: 40%;
      text-align: left;
    }

    .header-right {
      display: inline-block;
      width: 50% !important;
      text-align: right;

      .end-time {
        display: none !important;
      }
    }
  }

  /deep/ .el-collapse-item {
    .el-collapse-item__header {
      padding: 0 10px;
      color: #eee;
      border-color: rgba(222, 222, 222, 0.2);
      background-color: rgba(50, 30, 100, 0.9);
    }

    .el-collapse-item__wrap {
      background-color: rgba(50, 30, 100, 0.9);
      border-bottom: 1px solid rgba(222, 222, 222, 0.2);
    }

    .el-collapse-item__content {
      color: #eee;
      background-color: rgba(50, 30, 100, 0.9);
    }

  }


}

/deep/ .el-table {
  color: #111;
  background-color: rgba(50, 40, 80, 0.1) !important;
  border: 1px solid rgba(150, 150, 150, 0.3) !important;
}

/deep/ .el-table tbody tr:hover > td {
  background-color: rgba(0, 0, 100, 0.1) !important;
}

/deep/ .table_title {
  background-color: rgba(0, 39, 225, 0.1);
  border-bottom: 1px solid rgba(150, 150, 150, 0.3);
}

/deep/ .table_title_cell {
  background-color: rgba(0, 39, 225, 0.1);
  //border: 1px solid rgba(150, 150, 150, 0.3) !important;
}

/deep/ .table_cell {
  background-color: rgba(0, 39, 225, 0.1);
}

/deep/ .el-table th.el-table__cell {
  background-color: rgba(50, 40, 80, 0.1) !important;
}

/deep/ .el-table--border .el-table__cell {
  border: 1px solid rgba(150, 150, 150, 0.3) !important;
}

/deep/ .el-table--border {
  border-bottom: 1px solid rgba(150, 150, 150, 0.3) !important;
  border-right: 1px solid rgba(150, 150, 150, 0.3) !important;
}

/deep/ .el-table--border::after {
  width: 0 !important;
}

/deep/ .el-table::before {
  height: 0 !important;
}

/deep/ .el-table__fixed::before, .el-table__fixed-right::before {
  height: 0 !important;
}

/deep/ .el-table td.el-table__cell {
  border-bottom: 1px solid rgba(150, 150, 150, 0.3) !important;
}

/deep/ .el-table th.el-table__cell.is-leaf {
  border-bottom: 1px solid rgba(150, 150, 150, 0.3) !important;
}

/deep/ .el-table tr {
  background-color: rgba(50, 40, 80, 0.1) !important;
}

/deep/ .el-table__expanded-cell {
  background-color: rgba(50, 40, 80, 0.1) !important;
}

/deep/ .demo-table-expand {
  font-size: 0;
}

/deep/ .demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

/deep/ .demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}

/deep/ .el-descriptions__body {
  background-color: rgba(50, 0, 50, 0.1) !important;
}

/deep/ .el-descriptions-item__cell {
  border: 1px solid rgba(50, 50, 150, 0.3) !important;
  background-color: rgba(0, 0, 50, 0.1) !important;
}

/deep/ .el-descriptions .is-bordered .el-descriptions-item__cell {
  border: 1px solid rgba(50, 150, 150, 0.3) !important;
}


/deep/ .el-descriptions .is-bordered .el-descriptions-item__cell {
  border: 1px solid rgba(50, 150, 150, 0.3) !important;
}


/deep/ .el-pagination button:disabled {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pager li {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pagination .btn-next {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pagination .btn-prev {
  background-color: rgba(100, 10, 100, 0.1);
}

</style>
