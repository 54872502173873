<template>
  <div class="main-Hexiao">

    <div class="hexiao-box">
      <div class="h2 title">卡券核销</div>

      <el-input class="code-input" v-model="input" placeholder="请输入卡券码" @change="checkTicket"></el-input>
      <div>
        <el-button type="primary" size="mini" @click="scanNow">扫一扫</el-button>
        <el-button type="primary" size="mini"
                   :disabled="!ticketShow || currentTicket.status !=='已领取'"
                   @click="hexiaoTicket"
        >立即核销</el-button>
      </div>
      <el-divider></el-divider>

      <div class="ticket-info" v-if="ticketShow">
        卡券信息

        <div class="ticket-info-box">
          <div class="info-label">卡券名称 :</div>
          <div class="info-value">{{ currentTicket.ticket_info.name }}</div>
        </div>
        <div class="ticket-info-box">
          <div class="info-label">卡券码 :</div>
          <div class="info-value">{{ currentTicket.code }}</div>
        </div>
        <div class="ticket-info-box">
          <div class="info-label">卡券状态 :</div>
          <div class="info-value">{{ currentTicket.status }}</div>
        </div>

        <div class="ticket-info-box" >
          <div class="info-label">领取时间 :</div>
          <div class="info-value">{{ formatDate(currentTicket.created_at) }}</div>
        </div>
        <div class="ticket-info-box" v-if="currentTicket.status==='已使用'">
          <div class="info-label">核销时间 :</div>
          <div class="info-value">{{ formatDate(currentTicket.updated_at) }}</div>
        </div>


        <div class="ticket-info-box">
          <div class="info-label">有效期 :</div>
          <div class="info-value">
            <span>  {{ currentTicket.active_time }} ~ </span>
            {{ currentTicket.dead_time }}

          </div>
        </div>

        <div class="ticket-desc">
          <div class="desc-title">卡券说明：</div>
          <div v-html="currentTicket.ticket_info.desc"></div>

        </div>


      </div>

    </div>

  </div>
</template>


<script>

import {getUserTicketApi, hexiaoApi} from "@/services/client";
import {mapGetters, mapState} from "vuex";
import moment from "moment";

export default {
  name: "Hexiao",
  components: {},
  data() {
    return {
      input: '',
      ticketShow: false,
      currentTicket: null
    }
  },
  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
    ...mapState("companys", ["companyInfo"]),
  },
  created() {

  },
  mounted() {
    // console.log(this.userInfo)

  },
  methods: {
    formatDate(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
    scanNow() {
      let that = this
      wx.scanQRCode({
        desc: 'scanQRCode desc',
        needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是条形码（一维码），默认二者都有
        success: function (res) {
          // 回调
          that.input = res.resultStr
          that.checkTicket()
          // var result = res.resultStr;//当needResult为1时返回处理结果
        },
        error: function (res) {
          if (res.errMsg.indexOf('function_not_exist') > 0) {
            alert('版本过低请升级')
          }
        }
      });


    },
    checkTicket() {

      getUserTicketApi({code: this.input}).then(res => {
        if (res.data.data.ticket_info.corpid === this.userInfo.wework_corpid) {
          this.currentTicket = res.data.data
          this.ticketShow = true
        } else {
          this.ticketShow = false
          this.$message.warning('非本店卡券')
        }

      }).catch(err => {
        this.ticketShow = false
      })


    },
    hexiaoTicket(){
      hexiaoApi({code: this.currentTicket.code, hexiao_id: this.userInfo.wework_corpid}).then(res=>{
        let msg = res.data.data.msg
        this.$message.success(msg)
        this.checkTicket()
      }).catch(err=>{
        this.$message.success('核销失败')
      })
    }
  },
}
</script>


<style lang="scss" scoped>
.main-Hexiao {
  width: 100%;
  padding: 10px 10px;

  .hexiao-box {
    padding: 10px 10px;
    border: 1px solid rgba(100, 10, 120, 0.8);
    max-width: 900px;
  }

  .code-input {
    width: 260px;
    margin: 10px auto;
  }


  .ticket-info {
    margin: 10px auto;
    width: 98%;
    max-width: 700px;
    //border: 1px solid rgba(100, 10, 120, 0.8);


    .ticket-info-box {
      text-align: left;

      .info-label {
        display: inline-block;
        width: 130px;
        padding-right: 10px;
        text-align: right;
        font-size: 12px;
        color: #00bcd4;

      }

      .info-value {
        display: inline-block;
        text-align: left;
        font-size: 14px;
      }
    }

    .ticket-desc {
      text-align: left;

      .desc-title{
        font-size: 12px;
        color: #00bcd4;
      }
    }

  }


}
</style>
