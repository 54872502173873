<template>
  <div class="main-customerChart" v-loading="loading" element-loading-background="rgba(0, 80, 80, 0.5)">
    <div class="title">客户流动趋势图</div>
    <GroupBar
        :base-data="customerChartData"
        lineName=""
        :padding="[50, 'auto', 70]"
    ></GroupBar>

  </div>
</template>


<script>

import GroupBar from "@/components/charts/GroupBar";

export default {
  name: "customerChart",
  components: {
    GroupBar
  },
  data() {
    return {}
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    customerChartData: {
      type: Array,
      required: true
    }
  },
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {},
}
</script>


<style lang="scss" scoped>

</style>
