<template>
  <div class="main-NewActive">

    <!--    新增窗口-->
    <div class="new-box ">

      <el-form class="new-form table-box" :rules="rules" ref="form" size="mini" :model="form" label-width="80px">
        <div class="title" v-if="Object.keys(activeInfo).indexOf('id')===-1">创建新活动</div>
        <div class="title" v-else>更新活动</div>
        <el-form-item label="活动名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="活动类型" prop="type">
          <el-select v-model="form.type"
                     style="width: 300px"
                     @change="typeChange"
                     placeholder="请选择活动类型">
            <el-option v-for="item in activeTypes" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item v-if="form.type==='koulin'" label="口令" prop="koulin">
          <el-input v-model="form.koulin"></el-input>
        </el-form-item>

        <el-form-item label="关联卡券" required>
          <div class="tip" v-if="form.type==='get'">所选卡券会自动显示用户可在卡券限量内自由领取。</div>
          <div class="tip" v-if="form.type==='miaosha'">只允许一种卡券秒杀，付费卡券购买后到用户名下，免费卡券自动领取，关联卡券请在卡券页面设置限量。</div>
          <div class="tip" v-if="form.type==='choujiang'">请设置多种卡券，仅限免费卡券，抽奖概率由卡券余量决定，不限量卡券权重值1000，相当于由1000张卡券参与概率随机。
          </div>
          <div class="tip" v-if="form.type==='ping'">请设置一种或多种卡券，仅限免费卡券，手气获奖概率由卡券余量决定，不限量卡券权重值1000，相当于由1000张卡券参与概率随机。
          </div>
          <div class="tip" v-if="form.type==='koulin'">只允许一种卡券，付费卡券购买后到用户名下，免费卡券自动领取，关联卡券请在卡券页面设置限量。</div>

          <el-select v-model="tickets"
                     @change="ticketsChange"
                     style="width: 300px"
                     collapse-tags
                     :multiple="['get', 'choujiang', 'ping'].indexOf(form.type)>-1"
                     placeholder="请选择">
            <el-option
                v-for="item in ticketsOption"
                :key="item.id"
                :disabled="['choujiang', 'ping'].indexOf(form.type)>-1 && item.pay"
                :class="['choujiang', 'ping'].indexOf(form.type)>-1 && item.pay ? 'no-show' : ''"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>

          <div v-if="form.type==='choujiang'"  class="t-name">
            抽奖数量限制：
            <el-row v-for="(item, index) in ticketsLimits" :key="index" class="t-cell">
              <el-col :span="8">{{ item.ticket_name}} : </el-col>
              <el-col :span="16">
                <el-input-number v-model="item.limit_count" size="mini" :precision="0" :step="1" :max="1000"></el-input-number>
              </el-col>
            </el-row>

          </div>
        </el-form-item>

        <el-form-item label="开始时间" prop="active_time">
          <div class="tip">不填将默认当前时间，定点秒杀等请选择之后的时间，配置错误可能导致活动失效</div>
          <el-date-picker
              v-model="form.active_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择活动开始时间">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间" prop="dead_time">
          <div class="tip">抽奖活动结束时间必须选择整点，配置错误可能导致活动开奖延迟或无法开奖。</div>
          <el-date-picker
              v-model="form.dead_time"
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择活动结束时间">
          </el-date-picker>
        </el-form-item>

        <el-form-item label="活动封面">
          <div class="tip">活动图片不传将会使用系统默认图片封面，请自行查看效果。可多选，图片将自动轮播，最多可上传10张图。</div>
          <el-upload

              class="upload-demo"
              multiple
              :action="file_url"
              :data="{prefix: 'activity_img'}"
              :limit="10"
              :on-success="special_license_picSuccess"
              :on-progress="onProgress"
              :on-error="errFunc"
              :accept="accept_type.join(',')"
              :on-remove="special_license_picRemove"
              :file-list="special_license_pics"
              :show-file-list="false"
              list-type="picture">
            <el-button size="mini" :loading="uploading" type="primary">点击上传</el-button>
          </el-upload>

          <div class="img-box"
               v-for="(img, index) in activity_imgs" :key="index"
          >
            <el-image
                class="img-cell"
                :src="'https://img.xingtuangroup.com/'+img"
                fit="contain">
            </el-image>

            <el-button
                type="text"
                size="mini"
                class="img-btn"
                icon="el-icon-delete"
                @click="removeImage(img)"
            ></el-button>
          </div>


        </el-form-item>


        <el-form-item label="活动描述" prop="desc">
          <FroalaEditor v-model="form.desc" @change="changetext"></FroalaEditor>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="onSubmit()">
            <span v-if="Object.keys(activeInfo).indexOf('id')===-1"> 立即创建 </span>
            <span v-else> 立即更新 </span>
          </el-button>

          <el-button
              v-if="activeInfo!=={}"
              size="mini"
              type="warning"
              @click="backPage">返回
          </el-button>
          <div class="tip">提交创建后请至各活动列表页查看。</div>
        </el-form-item>
      </el-form>
    </div>


  </div>
</template>


<script>

import FroalaEditor from "@/views/xingtuan/components/FroalaEditor";
import {mapGetters, mapState} from "vuex";
import {getTicketApi} from "@/services/xingtuan";
import common from "@/views/xingtuan/pages/coupon/components/common";
import {postActivityApi} from "@/services/client";

export default {
  name: "NewActive",
  components: {
    FroalaEditor
  },
  props: {
    activeInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {

      ticketsLimits: [],
      uploading: false,
      hasActiveTime: ['get', 'miaosha'],
      accept_type: ['.jpg', '.png', '.bmp', '.gif', '.jpeg'],
      special_license_pics: [],
      activity_imgs: [],
      ticketsOption: [],
      tickets: [],

      common: common,
      editor: {
        p:
            '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
      },
      form: {
        corpid: '',
        name: '',
        type: 'get',
        koulin: '',
        active_time: '',
        dead_time: '',
        desc: '',

      },
      activeTypes: common.activeTypes,


      rules: {
        name: [
          {required: true, message: '请输入活动名称', trigger: 'blur'},
          {min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '请选择活动类型', trigger: 'blur'}
        ],
        dead_time: [
          {required: true, message: '请选择活动结束时间', trigger: 'blur'}
        ],

        desc: [
          {required: true, message: '请填写活动描述', trigger: 'blur'}
        ],

      }

    }
  },
  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
    ...mapState("companys", ["companyInfo"]),
    file_url() {
      return process.env.VUE_APP_API_HOST + '/api/v1/oss_upload'
    },
    ticketNames(){
      let _ = {}
      this.ticketsOption.forEach(item=>{
        _[item.id] = item.name
      })
      return _
    },



  },
  watch:{

  },
  created() {

  },
  mounted() {

    this.getTicketOptions()

    if (Object.keys(this.activeInfo).indexOf('id')>-1) {
      console.log(this.activeInfo)
      this.form = this.activeInfo


      if (this.form.type === 'miaosha' || this.form.type === 'koulin') {
        if (this.activeInfo.tickets.length > 0) {
          this.tickets = this.activeInfo.tickets[0]['id']
        }
      } else {
        this.tickets = this.activeInfo.tickets.map(ticket => {
          return ticket.id
        })

      }

      this.ticketsLimits = this.activeInfo.limit
      this.activity_imgs = this.activeInfo.imgs

    }


  },
  methods: {
    ticketsChange(){
      this.ticketsLimits = []
      this.tickets.forEach(id=>{
        this.ticketsLimits.push({
          ticket_name: this.ticketNames[id],
          ticket_id: id,
          limit_count: 10
        })
      })
    },
    onProgress() {
      this.uploading = true
    },
    toPage(val) {
      this.$emit('toPage', val)
    },
    backPage() {
      this.$emit('backPage')
    },
    typeChange() {
      this.tickets = []
    },
    getTicketOptions() {
      // ticketsOption
      getTicketApi({status: '1'}).then(res => {
        this.ticketsOption = res.data.data
      })


    },
    special_license_picSuccess(res, file) {
      this.special_license_pic_url = URL.createObjectURL(file.raw);
      this.activity_imgs.push(res.data.key)
      this.uploading = false

    },
    removeImage(img){
      let _ = []
      this.activity_imgs.forEach(item=>{
        if (item !== img){
          _.push(item)
        }
      })
      this.activity_imgs = _
    },
    special_license_picRemove(file, fileList) {
      let val = ''
      this.activity_imgs.forEach(item => {
        if (item.indexOf(file.name) > -1) {
          val = this.activity_imgs.indexOf(item)
        }
      })
      this.activity_imgs.splice(val, 1)
    },
    errFunc() {
      this.$message.error('上传错误');
      this.uploading = false
    },
    newActive() {
      this.$refs['form'].validate((valid) => {
        if (valid) {

          postActivityApi({base: this.form, tickets: this.tickets, imgs: this.activity_imgs, limit:this.ticketsLimits}).then(res => {
            this.$message.success('创建成功')
            this.form = {
              corpid: '',
              name: '',
              type: 'get',
              active_time: '',
              dead_time: '',
              desc: '',
            }
            this.tickets = []
            this.imgs = []
            let _map = {
              'get': 'active_get',
              'miaosha': 'active_miaosha',
              'choujiang': 'active_choujiang',
              'ping': 'active_pingshouqi',
              'koulin': 'active_koulin',
            }
            this.toPage(_map[this.form.type])

          }).catch(err => {
            this.$message.success('创建失败')
          }).finally(() =>{
            if (this.activeInfo!=={}){
              this.backPage()
            }
          })

        } else {
          if (this.activeInfo!=={}){
              this.backPage()
            }
          return false;
        }
      });
    },
    onSubmit() {
      this.form.corpid = this.userInfo.wework_corpid

      // 验证
      if (!this.tickets) {
        this.$message.error('未关联卡券')
      } else if (this.form.type === 'koulin' && this.form.koulin === '') {
        this.$message.error('请输入口令')
      } else {
        this.newActive()
      }


    },

    changetext(val) {

      // if (val.seriesDetail.indexOf(this.editor.p) > -1) {
      //   val.seriesDetail = val.seriesDetail.replace(
      //       this.editor.p,
      //       ""
      //   );
      // }

      this.form.desc = val.content

    },

  },
}
</script>


<style lang="scss" scoped>

.new-box {
  width: 100%;
  padding: 10px 10px;


  .new-form {
    text-align: left;

    padding: 10px 10px;
    //border: 1px solid rgba(100, 10, 120, 0.8);
    max-width: 900px;

    .title {
      padding: 10px 10px;
      font-weight: bold;
      font-size: 20px;
    }
  }


}

.upload-demo {
  max-width: 380px;
}

.tip {
  font-size: 10px;
  color: #3cb371;
}

.img-box {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 120px;
  padding: 2px 2px;
  text-align: center;

  .img-cell {
    width: 100%;
    height: 100%;


  }

  .img-btn {
    position: absolute;
    left: calc(100% - 18px);
    top: calc(100% - 28px);

  }
}
.no-show{
  display: none;
}
.t-name{
  text-align: left;
  font-size: 12px;
  color: #aaaaaa;

}
.t-cell{
  margin: 4px 0 0;
}
</style>
