<template>
  <div class="main-zhekou">
    <div class="btn">
      <el-button class="new-btn" size="mini" type="primary" @click="toPage('coupon_new')">新增</el-button>
    </div>

    <ticketBase :ticket-type="ticketType"></ticketBase>

  </div>
</template>


<script>


import {mapGetters, mapState} from "vuex";
import ticketBase from "@/views/xingtuan/pages/coupon/components/ticketBase";

export default {
  name: "DaiJing",
  components: {
    ticketBase
  },
  data() {
    return {
      ticketType: 'daijing'

    }
  },
  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
    ...mapState("companys", ["companyInfo"]),
  },
  created() {

  },
  mounted() {


  },
  methods: {
    toPage() {
      this.$emit('toPage', 'coupon_new')
    },


  },
}
</script>


<style lang="scss" scoped>
.btn {
  padding: 10px 10px;
  width: 100%;
  text-align: left;


}


.list-box {
  width: 100%;
  padding: 10px 10px;

  .info-table {
    //max-width: 800px;
  }
}


</style>
