<template>
  <div class="main-GroupMsg">

    <div class="group-msg table-box">
      <div>客户消息群发</div>

      <el-form :model="ruleForm"
               :rules="rules"
               size="mini"
               ref="ruleForm" label-width="100px"
               class="demo-ruleForm">

        <el-form-item label="消息类型" prop="chat_type">
          <el-radio-group v-model="ruleForm.chat_type">
            <el-radio label="group">群消息</el-radio>
            <el-radio label="single">客户消息</el-radio>
          </el-radio-group>


        </el-form-item>
        <el-form-item label="发送成员" prop="sender">
          <el-radio v-model="ruleForm.sender" label="" @input="senderChange">不选择成员</el-radio>
          <MemberTree v-model="ruleForm.sender" :single="true" @change="senderChange"></MemberTree>
        </el-form-item>

        <el-form-item label="接收客户" prop="external_userid" v-if="ruleForm.chat_type==='single'">

          <el-row v-loading="externalLoading" element-loading-background="rgba(0, 80, 80, 0.5)">


            <el-checkbox
                v-if="externalUserids.length>0"
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
            >全选
            </el-checkbox>


            <el-checkbox-group v-model="ruleForm.external_userid" @change="handleCheckedCitiesChange">
              <el-checkbox :label="item" v-for="(item, index) in externalUserids" :key="index">
                <WeworkUserShow
                    type="userName"
                    :openid="item"
                ></WeworkUserShow>
              </el-checkbox>
            </el-checkbox-group>
          </el-row>
        </el-form-item>


        <el-form-item label="消息内容" prop="text.content">
          <el-input v-model="ruleForm.text.content"></el-input>
        </el-form-item>
        <el-form-item label="添加附件" prop="attachments">
          <el-checkbox-group v-model="attachments">
            <el-checkbox label="image">图片</el-checkbox>
            <el-checkbox label="link">链接</el-checkbox>
            <el-checkbox label="miniprogram">小程序</el-checkbox>
            <el-checkbox label="video">视频</el-checkbox>
            <el-checkbox label="file">文件</el-checkbox>
          </el-checkbox-group>
        </el-form-item>


        <el-form-item label="图片" prop="name" v-if="attachments.indexOf('image')>-1">
          <el-upload
              class="upload-demo"
              multiple
              :action="file_url"
              :data="{prefix: 'msg_img', corpid: userInfo.wework_corpid}"
              :limit="1"
              :on-success="special_license_picSuccess"
              :on-progress="onProgress"
              :on-error="errFunc"
              :before-upload="beforeLicenseUpload"
              :accept="accept_type.join(',')"
              :file-list="special_license_pics"
              :show-file-list="false"
              list-type="picture">
            <el-button size="mini" :loading="uploading" type="primary">点击上传</el-button>
          </el-upload>

          <div class="img-box"
               v-for="(img, index) in msg_imgs" :key="index"
          >
            <el-image
                class="img-cell"
                :src="img.url"
                fit="contain">
            </el-image>

            <el-button
                type="text"
                size="mini"
                class="img-btn"
                icon="el-icon-delete"
                @click="removeImage(img.url)"
            ></el-button>
          </div>

        </el-form-item>

        <el-form-item label="链接" prop="link" v-if="attachments.indexOf('link')>-1">

          图文消息标题
          <el-input v-model="linkMsg.title"></el-input>
          图文消息封面的url
          <el-input v-model="linkMsg.picurl"></el-input>
          图文消息的描述
          <el-input v-model="linkMsg.desc"></el-input>
          图文消息的链接
          <el-input v-model="linkMsg.url"></el-input>

        </el-form-item>

        <el-form-item label="小程序" prop="link" v-if="attachments.indexOf('miniprogram')>-1">

          小程序消息标题
          <el-input v-model="miniMsg.title"></el-input>
          小程序消息封面
          <el-upload
              v-if="!miniMsg.pic_media_id"
              class="upload-demo"
              multiple
              :action="media_url"
              :data="{prefix: 'msg_img', corpid: userInfo.wework_corpid, type: 'image'}"
              :limit="1"
              :on-success="media_Success"
              :on-progress="onProgress"
              :on-error="errFunc"
              :before-upload="beforeLicenseUpload"
              :accept="accept_type.join(',')"
              :file-list="special_license_pics"
              :show-file-list="false"
              list-type="picture">
            <el-button size="mini" :loading="uploading" type="primary">点击上传</el-button>
          </el-upload>
          小程序appid
          <el-input v-model="miniMsg.appid"></el-input>
          小程序page路径
          <el-input v-model="miniMsg.page"></el-input>

        </el-form-item>

        <el-form-item label="视频" prop="link" v-if="attachments.indexOf('video')>-1">


          <el-upload
              v-if="!videoMsg.media_id"
              class="upload-demo"
              multiple
              :action="media_url"
              :data="{prefix: 'msg_img', corpid: userInfo.wework_corpid, type: 'video'}"
              :limit="1"
              :on-success="video_Success"
              :on-progress="onProgress"
              :on-error="errFunc"

              list-type="picture">
            <el-button size="mini" :loading="uploading" type="primary">点击上传</el-button>
          </el-upload>


        </el-form-item>
        <el-form-item label="文件" prop="link" v-if="attachments.indexOf('file')>-1">


          <el-upload
              v-if="!fileMsg.media_id"
              class="upload-demo"
              multiple
              :action="media_url"
              :data="{prefix: 'msg_img', corpid: userInfo.wework_corpid, type: 'file'}"
              :limit="1"
              :on-success="file_Success"
              :on-progress="onProgress"
              :on-error="errFunc"

              list-type="picture">
            <el-button size="mini" :loading="uploading" type="primary">点击上传</el-button>
          </el-upload>


        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>


    </div>


  </div>
</template>


<script>

import MemberTree from "@/views/xingtuan/components/MemberTree";
import {mapGetters} from "vuex";
import {getExternalContactApi, sentGroupMsgApi} from "@/services/weworkApi";

export default {
  name: "GroupMsg",
  components: {
    MemberTree
  },
  data() {
    return {
      uploading: false,
      externalLoading: false,
      checkAll: false,
      isIndeterminate: true,
      special_license_pics: [],

      accept_type: ['.jpg', '.png', '.bmp', '.gif', '.jpeg'],
      attachments: [],

      msg_imgs: [],
      linkMsg: {
        title: '',
        picurl: '',
        desc: '',
        url: '',
      },
      miniMsg: {
        title: '',
        pic_media_id: '',
        appid: '',
        page: '',
      },
      videoMsg: {media_id: ''},
      fileMsg: {media_id: ''},

      externalUserids: [],
      ruleForm: {
        chat_type: "group",
        external_userid: [],
        sender: '',
        allow_select: true,
        text: {content: ''},
        attachments: [],


      },
      rules: {}


    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    file_url() {
      return process.env.VUE_APP_API_HOST + '/api/v1/wework_upload'
    },
    media_url() {
      return process.env.VUE_APP_API_HOST + '/api/v1/wework_media_upload'
    },
    attachmentsMsg() {
      let _ = []
      //img
      if (this.msg_imgs.length > 0 && this.attachments.indexOf('image') > -1) {
        this.msg_imgs.forEach(item => {
          _.push({
            "msgtype": "image",
            "image": {
              "pic_url": item.url,
            }
          })
        })
      }
      // link
      if (this.linkMsg.title && this.attachments.indexOf('link') > -1) {
        _.push({
          "msgtype": "link",
          "link": {
            "title": this.linkMsg.title,
            "picurl": this.linkMsg.picurl,
            "desc": this.linkMsg.desc,
            "url": this.linkMsg.url
          }
        })
      }
      //mini
      if (this.miniMsg.title && this.attachments.indexOf('miniprogram') > -1) {
        _.push({
          "msgtype": "miniprogram",
          "miniprogram": {
            "title": this.miniMsg.title,
            "pic_media_id": this.miniMsg.pic_media_id,
            "appid": this.miniMsg.appid,
            "page": this.miniMsg.page
          }
        })
      }
      // video
      if (this.videoMsg && this.attachments.indexOf('video') > -1) {
        _.push({
          "msgtype": "video",
          "video": {
            "media_id": this.videoMsg.media_id
          }
        })
      }
      // video
      if (this.fileMsg && this.attachments.indexOf('file') > -1) {
        _.push({
          "msgtype": "file",
          "file": {
            "media_id": this.fileMsg.media_id
          }
        })
      }
      return _

    }
  },
  watch: {},
  created() {

  },
  mounted() {

  },
  methods: {
    handleCheckAllChange(val) {
      this.ruleForm.external_userid = val ? this.externalUserids : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.externalUserids.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.externalUserids.length;
    },
    senderChange(val) {

      console.log(this.ruleForm.sender)
      this.getExternalContactList()

    },
    getExternalContactList() {
      // externalUserids
      this.externalLoading = true
      getExternalContactApi({userid: this.ruleForm.sender, corpid: this.userInfo.wework_corpid}).then(res => {
        this.externalUserids = res.data.data
      }).finally(e => {
        this.externalLoading = false
      })

    },
    beforeLicenseUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 10MB!');
      }
      return isLt2M;
    },
    errFunc() {
      this.$message.error('上传错误');
      this.uploading = false
    },
    onProgress() {
      this.uploading = true
    },
    removeImage(img) {
      let _ = []
      this.msg_imgs.forEach(item => {
        if (item.url !== img) {
          _.push(item)
        }
      })
      this.msg_imgs = _

      let _m = []
      this.ruleForm.attachments.forEach(item => {
        if (item.image.url !== img.url) {
          _m.push(item)
        }
      })
      this.ruleForm.attachments = _m
    },
    special_license_picSuccess(res, file) {
      this.uploading = false
      console.log(res.data)

      this.msg_imgs.push({
        url: res.data.url
      })

    },
    media_Success(res, file) {
      this.uploading = false
      this.miniMsg.pic_media_id = res.data.media_id
    },
    video_Success(res, file) {
      this.uploading = false
      this.videoMsg.media_id = res.data.media_id
    },
    file_Success(res, file) {
      this.uploading = false
      this.fileMsg.media_id = res.data.media_id
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {

          this.ruleForm['corpid'] = this.userInfo.wework_corpid
          this.ruleForm['attachments'] = this.attachmentsMsg
          console.log(this.ruleForm)
          sentGroupMsgApi(this.ruleForm).then(res => {
            if (res.data.data.fail_list) {
              this.$message.warning('有成员无法发送')
            }
            this.$message.success('创建成功')

          }).catch(err => {
            this.$message.error('创建失败，请检查输入')
          })


        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
}
</script>


<style lang="scss" scoped>

.main-GroupMsg {
  padding: 10px 10px;

  .group-msg {
    text-align: left;
    padding: 10px 10px;
    //border: 1px solid rgba(100, 10, 120, 0.8);
    max-width: 900px;
  }


  .img-box {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    padding: 2px 2px;
    text-align: center;

    .img-cell {
      width: 100%;
      height: 100%;


    }

    .img-btn {
      position: absolute;
      left: calc(100% - 18px);
      top: calc(100% - 28px);

    }
  }

}


</style>
