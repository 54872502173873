import {getOpenDataConfigApi} from "@/services/weworkApi";

export default {
    namespaced: true,
    state: {

        weworkConfig: JSON.parse(localStorage.getItem("weworkConfig")),
        weworkAgentConfig: JSON.parse(localStorage.getItem("weworkAgentConfig")),

    },
    getters: {},
    mutations: {
        setWeworkConfig(state, payload) {
            state.weworkConfig = payload
            localStorage.setItem("weworkConfig", JSON.stringify(payload));
        },
        setWeworkAgentConfig(state, payload) {
            state.weworkAgentConfig = payload
            localStorage.setItem("weworkAgentConfig", JSON.stringify(payload));
        },


    },
    actions: {
        getWeworkConfig({commit, state}, query = {}) {

            getOpenDataConfigApi(query).then(res => {
                commit("setWeworkConfig", res.data)
            })
        },
        getWeworkAgentConfig({commit, state}, query = {}) {

            getOpenDataConfigApi(query).then(res => {
                commit("setWeworkAgentConfig", res.data)
            })
        },


    }
}

