
import request from "@/libs/apiRequest";


export function getUserStatusApi(params) {
    return request({
        url: "/xingtuan/table_user_status",
        method: "get",
        params: params
    });
}



export function getWifiUserStatusApi(params) {
    return request({
        url: "/xingtuan/wifi_user_status",
        method: "get",
        params: params
    });
}


