<template>
  <div class="clientActiveMiaoSha">

    <!--    banner-->
    <headBanner :imgList="activityInfo.imgs"></headBanner>

    <!--    ticket-->

    <div v-for="(item,index) in activityInfo.tickets" :key="index">
      <div @click="ticketClick(item)">
        <ticketCell :ticketInfo="item"></ticketCell>
      </div>
      <ticketTip :ticketInfo="item"></ticketTip>
      <ticketPay v-if="item.pay" :ticketInfo="item" :wxUserInfo="wxUserInfo"></ticketPay>
      <el-divider></el-divider>
    </div>

    <!--    deadline-->
    <el-row class="ticket-box">
      <div style="width: 100%; display: inline-block; ">
        <el-statistic
            :value="formatDate(activityInfo.active_time)"
            time-indices :title="timeTitle"
            format="HH:mm:ss"
            @finish="timeFinish"
        >
        </el-statistic>
      </div>
      <el-divider></el-divider>
    </el-row>


    <!--    报名人-->
    <!--    <getUserList title="已报名用户"></getUserList>-->
    <!--    <el-divider></el-divider>-->
    <!--    活动详情-->
    <activeDes :content="activityInfo.desc"></activeDes>


    <el-dialog
        :title="currentTicket.name"
        :visible.sync="ticketDetailShow"
        width="98%"
        custom-class="ticket-dialog"
    >
      <ticketPreview :ticket-info="currentTicket" :disabled="disabled" :wxUserInfo="wxUserInfo"></ticketPreview>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="ticketDetailShow = false">关 闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>


<script>

import headBanner from "@/views/xingtuan/clientPages/actives/components/headBanner";
import activeDes from "@/views/xingtuan/clientPages/actives/components/activeDes";
import ticketCell from "@/views/xingtuan/clientPages/clientComponents/ticketCell";
import ticketTip from "@/views/xingtuan/clientPages/actives/components/ticketTip";
import ticketPay from "@/views/xingtuan/clientPages/actives/components/ticketPay";
import ticketPreview from "@/views/xingtuan/clientPages/actives/components/ticketPreview";
import {mapState} from "vuex";

export default {
  name: "clientActiveMiaoSha",
  components: {
    headBanner,
    // getUserList,
    activeDes,
    ticketCell,
    ticketTip,
    ticketPay,
    ticketPreview,
  },
  data() {
    return {

      disabled: false,
      timeTitle: '活动已开始',
      ticketShow: false,
      ticketDetailShow: false,
      currentTicket: {},


    }
  },
  props: {
    activityInfo: {
      type: Object,
      required: true
    },
    wxUserInfo: {
      type: Object,
      required: true
    },
  },
  computed: {

  },
  created() {

  },
  mounted() {
    if (new Date(this.activityInfo.active_time) < new Date()) {
      this.disabled = false
      this.timeTitle = '活动已开始'
    } else {
      this.disabled = true
      this.timeTitle = '即将开始'
    }

  },
  methods: {
    wxProcess() {
      console.log('wxProcess')
      this.$parent.wxProcess();
    },
    timeFinish() {
      this.disabled = false
      this.timeTitle = '活动已开始'
    },
    ticketClick(val) {
      console.log(val)
      this.ticketDetailShow = true
      this.currentTicket = val
    },
    formatDate(val) {
      return new Date(val);
    },
  },
}
</script>


<style lang="scss" scoped>
.clientActiveMiaoSha {

  min-height: 100vh;
  background-image: linear-gradient(to bottom right, rgba(18, 1, 89, 0.7), rgba(82, 10, 53, 0.3));
  color: #eeeeee;


  .ticket-box {
    text-align: center;
    padding: 10px 10px;

  }


}

/deep/ .el-statistic {
  color: #eeeeee;
}

/deep/ .el-statistic .head {
  color: #eeeeee;
}

/deep/ .ticket-dialog {

  border-radius: 10px;
  background-color: rgba(40, 30, 45, 0.9);

}
</style>
