<template>
  <div :id="id"></div>
</template>


<script>
import Theme from "@/plugins/g2-theme";
import G2 from "@antv/g2";

const colorMap = Theme.colors;

export default {
  name: "GroupBar",
  components: {},
  props: {
    // 数据
    baseData: {
      type: Array,
      default: () => {
        return [
          {name: 'London', time: 'Jan.', value: 18.9, gr: 0.22},
          {name: 'London', time: 'Feb.', value: 28.8, gr: 0.32},
          {name: 'London', time: 'Mar.', value: 39.3, gr: 0.42},
          {name: 'London', time: 'Apr.', value: 81.4, gr: 0.52},
          {name: 'London', time: 'May', value: 47, gr: 0.32},
          {name: 'London', time: 'Jun.', value: 20.3, gr: 0.22},
          {name: 'London', time: 'Jul.', value: 24, gr: 0.52},
          {name: 'London', time: 'Aug.', value: 35.6, gr: 0.32},
          {name: 'Berlin', time: 'Jan.', value: 12.4, gr: 0.22},
          {name: 'Berlin', time: 'Feb.', value: 23.2, gr: 0.42},
          {name: 'Berlin', time: 'Mar.', value: 34.5, gr: 0.52},
          {name: 'Berlin', time: 'Apr.', value: 99.7, gr: 0.12},
          {name: 'Berlin', time: 'May', value: 52.6, gr: 0.32},
          {name: 'Berlin', time: 'Jun.', value: 35.5, gr: 0.22},
          {name: 'Berlin', time: 'Jul.', value: 37.4, gr: 0.22},
          {name: 'Berlin', time: 'Aug.', value: 42.4, gr: 0.42}
        ]


      }
    },
    // DOM 高度
    height: {
      type: Number,
      default: 320,
    },
    // DOM 高度
    padding: {
      type: Array,
      default: () => {
        return ['auto', 'auto']
      },
    },

    xName: {
      type: String,
      default: 'time',
    },
    yName: {
      type: String,
      default: 'value',
    },
    typeName: {
      type: String,
      default: 'name',
    },
    lineName: {
      type: String,
      default: 'gr',
    },
    percent: {
      type: Boolean,
      default: false,
    },
    barTooltipShow: {
      type: Boolean,
      default: true,
    },
    colors: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      id: this.genID(),
      chart: null,
    }
  },
  watch: {
    baseData() {
      this.drawChart()
    },
  },
  computed: {

    G2() {
      if (typeof window !== "undefined" && window.G2) {
        return window.G2;
      } else {
        return G2;
      }
    },
  },
  created() {

  },
  mounted() {
    this.drawChart()


  },
  methods: {
    genID(length) {
      return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36);
    },
    drawChart() {
      // 销毁实例
      if (this.chart) {
        this.chart.destroy();
      }
      // 新建实例
      this.chart = new G2.Chart({
        container: this.id,
        forceFit: true,
        height: this.height,
        padding: this.padding
      });
      this.chart.source(this.baseData);
      this.chart.axis(this.yName, {
        grid: null,
        label: null
      })
      this.chart.axis(this.lineName, {
        grid: null
      })
      let colorsMap = colorMap
      if (this.colors.length > 0) {
        colorsMap = this.colors
      }


      if (!this.barTooltipShow) {
        this.chart.interval().position(this.xName + '*' + this.yName).color(this.typeName, colorsMap)
            .tooltip(false).label(this.yName)
            .adjust([{
              type: 'dodge',
              marginRatio: 1 / 32
            }]);
      } else {
        if (this.percent) {
          this.chart.interval().position(this.xName + '*' + this.yName).color(this.typeName, colorsMap).label(this.yName)
              .adjust([{
                type: 'dodge',
                marginRatio: 1 / 32
              }]).tooltip(this.typeName + '*' + this.yName, (a, b) => {
            return {
              name: a,
              value: (b * 100).toFixed(0) + '%',
            }
          })
        } else {
          this.chart.interval().position(this.xName + '*' + this.yName).color(this.typeName, colorsMap)
              .adjust([{
                type: 'dodge',
                marginRatio: 1 / 32
              }]).label(this.yName)
        }

      }

      if (this.lineName) {

        this.chart.line()
            .position(this.xName + '*' + this.lineName)
            .color(this.typeName, colorsMap)
            .size(3)
            .label(this.lineName, {
              offset: 10,
              textStyle: {
                textAlign: 'center', // 文本对齐方向，可取值为： start middle end
                fill: '#bbb', // 文本的颜色
                fontSize: '12', // 文本大小
                fontWeight: 'bold', // 文本粗细
                textBaseline: 'top' // 文本基准线，可取 top middle bottom，默认为middle
              },
              formatter: (text, item, index) => {
                return (text * 100).toFixed(0) + '%';
              }
            })

      }
      this.chart.render();

    }

  },
}
</script>


<style lang="scss" scoped>

</style>
