const ticketTypes = [
    {label: '折扣券', value: 'zhekou'},
    {label: '单品券', value: 'danpin'},
    {label: '满减券', value: 'manjian'},
    {label: '团购券', value: 'tuangou'},
]

const activeTypes = [
    {label: '直接领取', value: 'get'},
    {label: '定点秒杀', value: 'miaosha'},
    {label: '抽奖活动', value: 'choujiang'},
    {label: '拼手气', value: 'ping'},
    {label: '口令活动', value: 'koulin'},
    // {label: '九宫格', value: 'jiu'},
]

export default {
    ticketTypes,
    activeTypes


}
