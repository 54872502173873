import request from "@/libs/apiRequest";


export function getCompanyAuth() {
    return request({
        url: "/xingtuan/company",
        method: "get"
    });
}

export function postPermanentCode(data) {
    return request({
        url: "/xingtuan/company",
        method: "post",
        data: data,
    });
}


export function getWeworkUserInfo(params) {
    return request({
        url: "/xingtuan/wework_login",
        method: "get",
        params: params,
    });
}

export function getWeworkCompanyInfo(params) {
    return request({
        url: "/xingtuan/company_info",
        method: "get",
        params: params,
    });
}

export function bindWeworkUser(data) {
    return request({
        url: "/xingtuan/wework_login",
        method: "post",
        data: data,
    });
}

export function deleteWeworkShop(data) {
    return request({
        url: "/xingtuan/wework_shop",
        method: "delete",
        data: data,
    });
}

export function getWeworkShop(params) {
    return request({
        url: "/xingtuan/wework_shop",
        method: "get",
        params: params,
    });
}

export function postWeworkShop(data) {
    return request({
        url: "/xingtuan/wework_shop",
        method: "post",
        data: data,
    });
}
export function postWeworkPermission(data) {
    return request({
        url: "/xingtuan/wework_permission",
        method: "post",
        data: data,
    });
}
export function getWeworkPermission(params) {
    return request({
        url: "/xingtuan/wework_permission",
        method: "get",
        params: params,
    });
}


export function getCompanyDepartmentApi(params) {
    return request({
        url: "/xingtuan/company_department",
        method: "get",
        params: params,
    });
}

export function getCompanyUserApi(params) {
    return request({
        url: "/xingtuan/company_user",
        method: "get",
        params: params,
    });
}
export function getOpenDataConfigApi(params) {
    return request({
        url: "/xingtuan/open_data_config",
        method: "get",
        params: params,
    });
}
export function getWxPayConfigApi(params) {
    return request({
        url: "/xingtuan/wx_pay_config",
        method: "get",
        params: params,
    });
}

export function checkAccountActiveInfoApi(params) {
    return request({
        url: "/xingtuan/check_account_active",
        method: "get",
        params: params,
    });
}
export function activeOrderApi(params) {
    return request({
        url: "/xingtuan/active_order",
        method: "get",
        params: params,
    });
}
export function getBankInfoDataApi(params) {
    return request({
        url: "/xingtuan/bank",
        method: "get",
        params: params,
    });
}
export function postBankInfoDataApi(data) {
    return request({
        url: "/xingtuan/bank",
        method: "post",
        data: data,
    });
}
export function sentGroupMsgApi(data) {
    return request({
        url: "/xingtuan/sent_group_msg",
        method: "post",
        data: data,
    });
}
export function getGroupMsgApi(params) {
    return request({
        url: "/xingtuan/sent_group_msg",
        method: "get",
        params: params,
    });
}
export function tixingGroupMsgApi(params) {
    return request({
        url: "/xingtuan/tixing_group_msg",
        method: "get",
        params: params,
    });
}
export function getExternalContactApi(params) {
    return request({
        url: "/xingtuan/externalcontact_list",
        method: "get",
        params: params,
    });
}
export function getFriendsCircleApi(params) {
    return request({
        url: "/xingtuan/friends_circle",
        method: "get",
        params: params,
    });
}
export function postFriendsCircleApi(data) {
    return request({
        url: "/xingtuan/friends_circle",
        method: "post",
        data: data,
    });
}
export function deleteFriendsCircleApi(data) {
    return request({
        url: "/xingtuan/friends_circle",
        method: "delete",
        data: data,
    });
}


export function getVipsApi(params) {
    return request({
        url: "/xingtuan/vip",
        method: "get",
        params: params,
    });
}
export function getCustomerWelcomeApi(params) {
    return request({
        url: "/xingtuan/customer_welcome",
        method: "get",
        params: params,
    });
}
export function postCustomerWelcomeApi(data) {
    return request({
        url: "/xingtuan/customer_welcome",
        method: "post",
        data: data,
    });
}
export function deleteCustomerWelcomeApi(data) {
    return request({
        url: "/xingtuan/customer_welcome",
        method: "delete",
        data: data,
    });
}

