<template>
  <div class="main-LinkMeCode">
  LinkMeCode

  </div>
</template>


<script>

export default {
  name: "LinkMeCode",
  components: {

  },
  data() {
    return {

    }
  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
  methods: {

  },
}
</script>


<style lang="scss" scoped>

.main-LinkMeCode {
  padding: 10px 10px;

  .group-msg {
    text-align: left;
    padding: 10px 10px;

    max-width: 900px;
  }


  .img-box {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    padding: 2px 2px;
    text-align: center;

    .img-cell {
      width: 100%;
      height: 100%;


    }

    .img-btn {
      position: absolute;
      left: calc(100% - 18px);
      top: calc(100% - 28px);

    }
  }

}

</style>
