<template>
  <div class="main-ticketPreview">
    <div class="title">{{ ticketInfo.name }}</div>
    <ticketTip :ticketInfo="ticketInfo"></ticketTip>
    <ticketPay v-if="ticketInfo.pay" :ticketInfo="ticketInfo" :wxUserInfo="wxUserInfo"></ticketPay>
    <el-button v-if="!ticketInfo.pay "
               type="primary" size="mini"
               :disabled="disabled"
               @click="postTicketCode">立即领取
    </el-button>

    <div class="desc">
      卡券说明：
      <div v-html="ticketInfo.desc"></div>
    </div>


    <el-dialog
        title="前往主页"
        :visible.sync="toIndexShow"
        width="80%"
        center>
      <span>现在前往主页吗？</span>
      <span slot="footer" class="dialog-footer">
    <el-button size="mini" @click="toIndexShow = false">取 消</el-button>
    <el-button size="mini" type="primary" @click="toIndex">确 定</el-button>
  </span>
    </el-dialog>

  </div>
</template>


<script>

import ticketTip from "@/views/xingtuan/clientPages/actives/components/ticketTip";
import ticketPay from "@/views/xingtuan/clientPages/actives/components/ticketPay";
import {postTicketCodeApi} from "@/services/xingtuan";
import {getOpenId} from "@/services/wxApi";

export default {
  name: "ticketPreview",
  components: {
    ticketTip,
    ticketPay
  },
  data() {
    return {
      toIndexShow: false
    }
  },
  props: {
    ticketInfo: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    wxUserInfo: {
      type: Object,
      required: true
    },

  },
  computed: {},
  created() {

  },
  mounted() {


  },
  methods: {
    wxProcess() {
      console.log('wx')

      if (!this.wxUserInfo) {

        if (this.wx_code) {
          getOpenId({code: this.wx_code}).then(res => {
            let access_info = res.data.data
            this.getWxUserInfo({token: access_info.access_token, open_id: access_info.openid})
          })
        } else {
          const local_url = window.location.href;
          window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3e56662e1635e163&redirect_uri=' + local_url + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
        }
      }


    },

    postTicketCode() {
      if (!this.wxUserInfo) {
        this.$message.warning('请先微信授权')
        this.wxProcess();
      } else {
        postTicketCodeApi({
          wx_id: this.wxUserInfo.openid,
          wx_name: this.wxUserInfo.nickname,
          wx_header_img: this.wxUserInfo.headimgurl,
          ticket_id: this.ticketInfo.id,
        }).then(res => {
          let _info = res.data.data
          if (_info.msg === 'success') {
            this.$message.success('领取成功，请前往个人中心查看')
            this.toIndexShow = true
          } else if (_info.msg === '超出可领取数量') {
            this.$message.warning('超出可领取数量')
          } else if (_info.msg === 'expire') {
            this.$message.warning('活动已过期')
          } else {
            this.$message.warning('领取失败')
          }
        }).catch(err => {
          this.$message.warning('领取失败!')
        })

      }


    },
    toIndex() {
      this.toIndexShow = false
      this.$router.push({name: 'client_home'})
    }
  },
}
</script>


<style lang="scss" scoped>
.desc {
  text-align: left;

}
</style>
