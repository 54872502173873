<template>
  <div class="main-FriendsCircleTable">
    <div class="list-box" v-if="pageShow==='index'">

      <el-table
          v-loading="loading"
          element-loading-text="拼命加载中"
          element-loading-spinner="el-icon-loading"
          class="info-table"
          :data="filterFriendsCircles"
          element-loading-background="rgba(10, 0, 30, 0.7)"
          header-row-class-name="table_title"
          header-cell-class-name="table_title_cell"
          row-class-name="table_cell"
          style="width: 100%">


        <el-table-column
            label="创建时间"

            :formatter="formatterDate"
            prop="created_at">
        </el-table-column>
        <el-table-column
            label="朋友圈文本内容"
            prop="text">
        </el-table-column>

        <el-table-column
            label="朋友圈状态"
            prop="status">
          <template slot-scope="scope">

            <el-tag v-if="scope.row.status===1" type="warning">开始创建</el-tag>
            <el-tag v-if="scope.row.status===2" type="info">正在创建</el-tag>
            <el-tag v-if="scope.row.status===3" type="success">已完成</el-tag>

          </template>
        </el-table-column>

        <el-table-column
            label="操作">
          <template slot-scope="scope">

            <el-popconfirm
                title="确定删除吗？"
                style="padding-left: 10px"
                @confirm="delFriends(scope.row)"
            >
              <el-button slot="reference" type="primary" size="mini">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>


      </el-table>

      <el-pagination
          style="padding-top: 10px; text-align: center"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next, jumper"
          :total="friendsCircleList.length">
      </el-pagination>

    </div>


  </div>
</template>


<script>

import {deleteFriendsCircleApi, getFriendsCircleApi} from "@/services/weworkApi";
import moment from "moment";
import {mapGetters} from "vuex";

export default {
  name: "FriendsCircleTable",
  components: {},
  data() {
    return {
      loading: false,
      pageShow: 'index',
      friendsCircleList: [],
      currentPage: 1,
      pageSize: 10,
      currentFriendsCircle: {}
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    filterFriendsCircles() {
      return this.friendsCircleList.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)

    }

  },
  created() {

  },
  mounted() {
    this.getDatas()

  },
  methods: {
    formatterDate(row, col) {
      return this.formatDate(row['created_at'])
    },
    formatterStatus() {

    },
    formatDate(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
    clickFriends(row) {
      this.pageShow = 'detail'
      this.currentFriendsCircle = row
      console.log(this.currentFriendsCircle)

    },
    delFriends(row) {
      deleteFriendsCircleApi({id: row.id}).then(res => {
        this.getDatas()
      })
    },
    getDatas() {
      getFriendsCircleApi({corpid: this.userInfo.wework_corpid}).then(res => {
        this.msgList = res.data.data
      })
    }
  },
}
</script>


<style lang="scss" scoped>
.main-FriendsCircleTable {
  padding: 10px 10px;
  text-align: left;
}

</style>
