<template>
  <div class="login-border">


    <div class="login-box">
      <div class="logo-box">
        <img src="https://img.xingtuangroup.com/img/%E6%98%9F%E5%9B%A2-10.png" alt="logo">
      </div>

      <div class="type-check">
        <span @click="loginType='phone'">用户名登录</span>
        <el-divider direction="vertical"></el-divider>
        <span @click="loginType='qr'">企微登录</span>
      </div>

      <div v-if="loginType==='phone'">
        <el-row class="login-item">
          <svg-icon iconname="icon-yonghuming" class="icon"></svg-icon>
          <el-input
              class="input"
              placeholder="用户名"
              v-model="form.username"
              clearable>
          </el-input>
        </el-row>
        <el-row class="login-item">
          <svg-icon iconname="icon-mima" class="icon"></svg-icon>
          <el-input
              class="input"
              show-password
              placeholder="密码"
              v-model="form.password"
              clearable>
          </el-input>

        </el-row>
        <div style="text-align: right">
          <el-button type="text" size="mini" @click="forgetPwdBox=true">忘记密码</el-button>
        </div>


        <el-row class="login-item">
          <el-button type="primary" size="mini" class="btn" @click="loginClick">登录</el-button>
        </el-row>


      </div>

      <div v-if="loginType==='qr'">

        <a @click="toLogin" :disabled="!checked">
          <img src="https://open.work.weixin.qq.com/service/img?id=wwa70c79eee161a233&t=login&c=blue&s=medium"
               srcset="https://open.work.weixin.qq.com/service/img?id=wwa70c79eee161a233&t=login&c=blue&s=medium@2x 2x"
               referrerpolicy="unsafe-url" alt="企业微信"></a>

      </div>

      <el-row class="login-item allow">
        <el-checkbox v-model="checked"></el-checkbox>
        阅读并接受
        <el-button type="text" size="mini">用户协议</el-button>
      </el-row>




      <el-dialog title="忘记密码" :visible.sync="forgetPwdBox" width="380px">
        <changePwd></changePwd>
      </el-dialog>

    </div>

  </div>
</template>

<script>

import changePwd from "@/views/public/components/changePwd";

export default {
  name: "Login",
  components: {
    changePwd
  },
  data() {
    return {
      qrBackUrl: 'https://www.xingtuangroup.com/qr_login_back',
      corpId: 'wwa70c79eee161a233',
      SuiteID: 'wwbe0e55677439fc52',
      loginType: 'qr',
      forgetPwdBox: false,
      form: {
        username: sessionStorage.username || "",
        password: sessionStorage.password || ""
      },

      checked: true,
    }
  },
  created() {

    if (window.location.hostname==='localhost'){

    }else if (window.location.hostname.indexOf('www')===-1){
      window.location.href = 'https://www.xingtuangroup.com/login'
    }
  },
  mounted() {

  },
  computed: {
    redirect_uri() {
      return 'https://open.work.weixin.qq.com/wwopen/sso/3rd_qrConnect?appid=' + this.corpId + '&redirect_uri='
          + encodeURIComponent(this.qrBackUrl) + '&state=web_login'
    },
    js_redirect_uri(){
      return 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' + this.SuiteID + '&redirect_uri='
          + encodeURIComponent(this.qrBackUrl) +
          '&response_type=code&scope=snsapi_privateinfo&state=STATE#wechat_redirect'
    }

  },
  methods: {
    toAuthPage() {
      this.$router.push('/company_auth')
    },
    toLogin() {
      let ua = window.navigator.userAgent.toLowerCase()
      if (this.checked) {
        // 判断登录环境
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          window.location.href = this.js_redirect_uri
        }else{
          window.location.href = this.redirect_uri
        }



      } else {
        this.$message.error('请先阅读并同意用户协议!');
      }
    },

    loginClick() {
      console.log("Login")
      if (!this.checked) {
        this.$message.error('请先阅读并同意用户协议!');
      } else {
        this.errors = [];
        this.loading = true
        if (!this.username) {
          this.$store.dispatch('user/login', this.form)
              .then(success => {
                if (success) {
                  this.$router.push({
                    path: '/guide'
                  });
                } else {
                  this.errors = ["请输入用户名密码"]
                }
              })
          this.loading = false
        }
        this.loading = false

      }
    },

  }
}
</script>

<style scoped lang="scss">
.login-border {
  padding: 80px 0 0 0;
  min-height: calc(100vh - 30px);
  //background-image: url('https://img.xingtuangroup.com/web/dash-bg.png');
  background-image: url('https://img.xingtuangroup.com/img/%E6%98%9F%E5%9B%A2-02.png');
  //background-size: cover;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
}
.danger{
  color: red;
  font-size: 8px;

}

.login-box {
  position: absolute;
  top: calc(50% - 250px);
  left: calc(50% + 190px);
  border-radius: 10px;
  width: 380px;
  min-height: 360px;
  background-color: rgba(250,250,250,1);
  //box-shadow: rgba(11, 120, 120, 0.87) 0 0 10px inset;

  padding: 40px 60px;
  //margin: 0 auto;


  .logo-box {

    text-align: center;
    height: 80px;

    img {
      display: inline-block;
      width: 200px;
      height: 70px;
    }
  }

  .type-check {
    color: #111;
    height: 60px;
    line-height: 60px;
    font-size: 14px;

    span {
      cursor: pointer;
    }

    span:hover {
      color: rgb(100, 50, 120);
      transform: scale(1.1);
    }


  }

  .login-item {
    margin-top: 10px;

    .icon {
      width: 30px;
      height: 30px;
      margin: 5px 5px;
      float: left

    }

    .input {
      height: 40px;
      width: 220px;

    }

    .btn {
      background-color: rgb(0, 39, 225);
      width: 100%;
    }

  }

  .allow {
    font-size: 12px;
  }


}

</style>
