import request from "@/libs/apiRequest";


export function getCustomerQrCodeApi(params) {
    return request({
        url: "/xingtuan/customer_qr_code",
        method: "get",
        params: params
    });
}

export function getCustomerWifiApi(params) {
    return request({
        url: "/xingtuan/customer_wifi",
        method: "get",
        params: params
    });
}


export function postCustomerWifiApi(data) {
    return request({
        url: "/xingtuan/customer_wifi",
        method: "post",
        data: data
    });
}

export function postCustomerQrCodeApi(data) {
    return request({
        url: "/xingtuan/customer_qr_code",
        method: "post",
        data: data
    });
}


export function downloadQrCodeApi(params) {
    return request({
        url: "/xingtuan/download_qr_code",
        method: "get",
        params: params
    });
}


export function delCustomerQrCodeApi(data) {
    return request({
        url: "/xingtuan/customer_qr_code",
        method: "delete",
        data: data
    });
}


export function delCustomerWifiApi(data) {
    return request({
        url: "/xingtuan/customer_wifi",
        method: "delete",
        data: data
    });
}

