<template>
  <div class="main-clientActiveGet">

    <!--    banner-->
    <headBanner :imgList="activityInfo.imgs"></headBanner>

    <!--    ticket-->
    <div v-for="(item,index) in activityInfo.tickets" :key="index">
      <div  @click="ticketClick(item)">
        <ticketCell :ticketInfo="item"></ticketCell>
      </div>
      <ticketTip :ticketInfo="item"></ticketTip>
      <ticketPay v-if="item.pay" :ticketInfo="item" :wxUserInfo="wxUserInfo"></ticketPay>
      <el-divider></el-divider>
    </div>

    <!--    活动详情-->
    <activeDes :content="activityInfo.desc"></activeDes>



     <el-dialog
        :title="currentTicket.ticket_name"
        :visible.sync="ticketDetailShow"
        width="98%"
        custom-class="ticket-dialog"
    >
      <ticketPreview :ticket-info="currentTicket"  :wxUserInfo="wxUserInfo"></ticketPreview>


<!--      <span slot="footer" class="dialog-footer">-->
<!--        <el-button type="primary" size="mini" @click="ticketDetailShow = false">关 闭</el-button>-->
<!--      </span>-->
    </el-dialog>
  </div>
</template>


<script>

import headBanner from "@/views/xingtuan/clientPages/actives/components/headBanner";
import activeDes from "@/views/xingtuan/clientPages/actives/components/activeDes";
import ticketPay from "@/views/xingtuan/clientPages/actives/components/ticketPay";
import ticketCell from "@/views/xingtuan/clientPages/clientComponents/ticketCell";
import ticketTip from "@/views/xingtuan/clientPages/actives/components/ticketTip";
import ticketPreview from "@/views/xingtuan/clientPages/actives/components/ticketPreview";

export default {
  name: "clientActiveGet",
  components: {
    headBanner,
    ticketCell,
    ticketTip,
    ticketPay,
    ticketPreview,
    activeDes
  },
  data() {
    return {
      ticketDetailShow: false,
      currentTicket: {},
    }
  },
  props: {
    activityInfo: {
      type: Object,
      required: true
    },
    wxUserInfo: {
      type: Object,
      required: true
    },
  },
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {
    wxProcess(){
      this.$parent.wxProcess();
    },

    ticketClick(val){
      console.log(val)
      this.ticketDetailShow=true
      this.currentTicket=val
    },
    createShortUrl(urlStr) {
      var convertStr = encodeURIComponent(urlStr); //转码
      var appkey = 'xxx';
      getShortUrl(convertStr);

      function getShortUrl(urlStr) {
        $.ajax({
          type: 'GET',
          url:
              'https://dwz.cn/api/v3/short-urls' + appkey + '&url_long=' +
              urlStr, //source为新浪appkey
          dataType: 'JSONP',
          json: 'callback',
          data: {},
          success: function (r) {
            console.log(r);
            return r[0].url_short; //生成的短链接
          }
        });
      }
    }
  },
}
</script>


<style lang="scss" scoped>
.main-clientActiveGet {


  .indexBanner {
    overflow: hidden;
  }

  .indexBannerImg {
    width: 100%;
    height: auto;
  }

  .ticket-box {
    text-align: center;
    padding: 10px 10px;

  }


  .ac {
    color: #eeeeee;
    min-height: 500px;

    text-align: left;
    padding: 0 10px 10px;


    .ac-title {
      font-weight: bold;
      font-size: 18px;
    }

    .ac-detail {
      font-size: 14px;
    }
  }


  .ac-title {

    text-align: left;

    z-index: 11;
  }

}
/deep/ .ticket-dialog{

  border-radius: 10px;
  background-color: rgba(40,30,45, 0.9);

}
</style>
