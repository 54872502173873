<template>
  <div class="main-activeChart" v-loading="loading" element-loading-background="rgba(0, 80, 80, 0.5)">

    <div class="title">活动人流趋势图</div>
    <BaseChart
        :base-data="activeChartData"
        :axis-name="{'name': '时间','value': '人流'}"

        :padding="[50, 'auto', 70]"
    ></BaseChart>


  </div>
</template>


<script>

import BaseChart from "@/components/charts/BaseChart";

export default {
  name: "activeChart",
  components: {
    BaseChart
  },
  data() {
    return {}
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    activeChartData: {
      type: Array,
      required: true
    }
  },
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {},
}
</script>


<style lang="scss" scoped>

</style>
