import axios from "axios";
import {router} from "@/router";
import Vue from 'vue';
import store from '@/store';
import {Message} from 'element-ui';
/* eslint-disable */
// create an axios instance
const service = axios.create({
    baseURL: process.env.VUE_APP_API_HOST + "/api/v1", // api的base_url,
    timeout: 600000 // request timeout
})

// request interceptor
service.interceptors.request.use(config => {
    let token = store.getters.token;
    if (token) {
        // 让每个请求携带token-- ['X-Token']为自定义key 请根据实际情况自行修改
        config.headers['Authorization'] = 'Bearer ' + token;
        config.headers['content-type'] = 'application/json';
    }
    return config
}, error => {
    // Do something with request error
    return Promise.reject(error)
})

// respone interceptor
service.interceptors.response.use(
    response => {
        if (response.status !== 200) {
            if (response.status === 504) {
                return response;
            } else {
                const res = response.data;
                Vue.notify({
                    text: res.message
                })
            }

            return Promise.reject('error');
        } else {
            return response;
        }
    },
    error => {


        if (error.response && error.response.status === 401) {
            sessionStorage.removeItem('access_token')
            localStorage.removeItem('access_token')
            store.dispatch('user/logout').then(() => {
                router.push({
                    name: "login"
                });
                router.go(0);
            });

            if (error.config.url.indexOf("logout") === -1) {
                Message.error('登录信息过期，请重新登陆')

                sessionStorage.removeItem('access_token')
                localStorage.removeItem('access_token')
                store.dispatch('user/logout').then(() => {
                    router.push({
                        name: "login"
                    });
                    router.go(0);
                });
            }
            setTimeout(() => {
                store.dispatch('user/logout').then(() => {
                    router.push({
                        name: "login"
                    });
                    router.go(0);
                });
            }, 1000)

        } else if (error.response && error.response.status === 400) {
            Message.error(error.response.data.data.msg)
        } else if (error.message.indexOf("timeout") > -1) {
            // Message.error('网络超时')
        } else if (error.message.indexOf("Network") > -1) {
            // Message.error('网络超时')
        } else {
           Message.error(error.response.data.data.msg)
        }
        return Promise.reject(error)
    })

export default service
