<template>
  <div class="clientActiveChouJiang">

    <!--    banner-->
    <headBanner :imgList="activityInfo.imgs"></headBanner>

    <!--    ticket-->
    <div v-for="(item,index) in showTickets" :key="index">
      <div @click="ticketClick(item)">
        <ticketCell :ticketInfo="item"></ticketCell>
      </div>
      <ticketTip :ticketInfo="item"></ticketTip>
      <ticketPay v-if="item.pay"
                 :ticketInfo="item"
                 :disabled="prizeTickets.length===0"
                 :wxUserInfo="wxUserInfo"
      ></ticketPay>
      <el-divider></el-divider>
    </div>
    <div v-if="showTickets.length===0">很遗憾，您未中奖</div>


    <!--    deadline-->
    <el-row class="ticket-box">
      <div style="width: 100%; display: inline-block; ">
        <el-statistic
            :value="formatDate(activityInfo.dead_time)"
            time-indices
            format="HH:mm:ss"
            :title="timeTitle"
            @finish="timeFinish"
        >
        </el-statistic>
      </div>


      <div
          style="padding-top: 4px;"

      >
        <el-button v-if="!joined && !openPrize" type="primary" size="mini" @click="joinActive">立即报名</el-button>
        <el-button v-if="joined" type="primary" size="mini" disabled>您已参与</el-button>
      </div>
    </el-row>
    <el-divider></el-divider>


    <!--    报名人-->
    <getUserList :title="userTitle" :userList="activeUsers"></getUserList>
    <el-divider></el-divider>
    <!--    活动详情-->
    <activeDes :content="activityInfo.desc"></activeDes>


    <el-dialog
        :title="currentTicket.name"
        :visible.sync="ticketDetailShow"
        width="98%"
        custom-class="ticket-dialog"
    >
      <ticketPreview :ticket-info="currentTicket" :disabled="prizeTickets.length===0"
                     :wxUserInfo="wxUserInfo"></ticketPreview>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="ticketDetailShow = false">关 闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>


<script>

import headBanner from "@/views/xingtuan/clientPages/actives/components/headBanner";
import activeDes from "@/views/xingtuan/clientPages/actives/components/activeDes";
import getUserList from "@/views/xingtuan/clientPages/actives/components/getUserList";
import ticketCell from "@/views/xingtuan/clientPages/clientComponents/ticketCell";
import ticketTip from "@/views/xingtuan/clientPages/actives/components/ticketTip";
import ticketPay from "@/views/xingtuan/clientPages/actives/components/ticketPay";
import ticketPreview from "@/views/xingtuan/clientPages/actives/components/ticketPreview";
import {getActiveWxUserApi, getWxActiveStatusApi, postActiveWxUserApi} from "@/services/client";


export default {
  name: "clientActiveChouJiang",
  components: {
    headBanner,
    getUserList,
    activeDes,
    ticketCell,
    ticketTip,
    ticketPay,
    ticketPreview,
  },
  data() {
    return {

      joined: false,
      openPrize: false,

      prizeTickets: [],
      ticketShow: false,
      ticketDetailShow: false,
      currentTicket: {},
      activeUsers: [],


    }
  },
  props: {
    activityInfo: {
      type: Object,
      required: true
    },

    wxUserInfo: {
      type: Object,
      required: true
    },

  },
  computed: {

    timeTitle() {
      if (this.openPrize) {
        return '已开奖'
      } else {
        return '即将开奖'
      }

    },
    userTitle() {
      if (this.activityInfo.open_status === 1) {
        return '中奖用户'
      } else {
        return '已报名用户'
      }
    },
    showTickets() {
      if (this.activityInfo.open_status === 1) {
        return this.prizeTickets
      } else {
        return this.activityInfo.tickets
      }
    }
  },
  watch: {
    openPrize(n, o) {
      this.getWxActiveStatus()
      this.getActiveWxUser()
    }
  },
  created() {

  },
  mounted() {

    this.openPrize = new Date(this.activityInfo.dead_time) < new Date();

    // 获取该用户参与信息
    this.getWxActiveStatus()
    this.getActiveWxUser()


  },
  methods: {
    wxProcess() {
      console.log('wxProcess')
      this.$parent.wxProcess();
    },
    timeFinish() {

      this.openPrize = true


    },
    finishGet() {
      this.getWxActiveStatus()
      this.getActiveWxUser()
    },
    joinActive() {
      if (!this.wxUserInfo) {
        this.$message.warning('请先授权')
        const local_url = window.location.href;
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3e56662e1635e163&redirect_uri=' + local_url + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'


      } else {
        postActiveWxUserApi({wx_info: this.wxUserInfo, activity_id: this.activityInfo.id}).then(res => {
          if (res.data.data.msg === 'joined') {
            this.$message.warning('您已参与该活动')
          } else if (res.data.data.msg === 'success') {
            this.$message.success('报名成功')
          } else {
            this.$message.warning('参与失败')
          }

        }).catch(err => {
          this.$message.warning('参与失败')
        }).finally(e => {
          this.getActiveWxUser()
        })
      }


    },
    getWxActiveStatus() {
      getWxActiveStatusApi({wx_id: this.wxUserInfo.openid, activity_id: this.activityInfo.id}).then(res => {

        console.log('joined')
        console.log(this.joined)
        if (res.data.data.prize_ticket_id) {
          this.prizeTickets = []
          this.joined = true
          if (res.data.data.prize_ticket_info !== {}) {

            this.prizeTickets.push(res.data.data.prize_ticket_info)
          }

        }
        console.log(this.joined)
        console.log(this.prizeTickets)
      })


    },
    getActiveWxUser() {
      getActiveWxUserApi({activity_id: this.activityInfo.id}).then(res => {
        this.activeUsers = res.data.data
      })
    },
    ticketClick(val) {
      if (!this.wxUserInfo) {
        this.$message.warning('请先授权')
        const local_url = window.location.href;
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx3e56662e1635e163&redirect_uri=' + local_url + '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'


      } else {
        this.ticketDetailShow = true
        this.currentTicket = val
      }

    },
    formatDate(val) {
      return new Date(val);
    },
  },
}
</script>


<style lang="scss" scoped>
.clientActiveChouJiang {

  min-height: 100vh;
  background-image: linear-gradient(to bottom right, rgba(18, 1, 89, 0.7), rgba(82, 10, 53, 0.3));
  color: #eeeeee;


  .ticket-box {
    text-align: center;
    padding: 10px 10px;

  }


}

/deep/ .el-statistic {
  color: #eeeeee;
}

/deep/ .el-statistic .head {
  color: #eeeeee;
}

/deep/ .ticket-dialog {

  border-radius: 10px;
  background-color: rgba(40, 30, 45, 0.9);

}
</style>
