<template>
  <!--  <=2 权限为admin-->
  <div class="main-ShopIndex" v-if="userInfo.wework_role<=2">
    <el-table
        class="info-table"
        :data="shopData"
        element-loading-background="rgba(0, 0, 30, 0.2)"
        header-row-class-name="table_title"
        header-cell-class-name="table_title_cell"
        row-class-name="table_cell"
        border
        style="width: 100%">

      <el-table-column

          prop="shop_name"
          label="门店名称"
      >
      </el-table-column>
      <el-table-column

          label="门店管理员"
      >
        <template slot-scope="scope">

          <WeworkUserShow type="userName" :openid="scope.row.shop_manager_id"></WeworkUserShow>


        </template>
      </el-table-column>

      <el-table-column

          label="操作"
          width="100">
        <template slot-scope="scope">
          <el-button @click="handleClick(scope.row)" type="text" size="small">查看</el-button>

          <el-popconfirm
              v-if="userInfo.wework_role<=2"
              title="删除后不可恢复，确定删除吗？"
              @confirm="deleteShop(scope.row)"
          >
            <el-button style="padding-left: 10px;"
                       slot="reference" type="text"
                       size="small"

            >删除
            </el-button>
          </el-popconfirm>

        </template>
      </el-table-column>
    </el-table>

    <div class="add-btn" v-if="userInfo.wework_role<=2">
      <el-button @click="addShopShow=true" type="primary" size="small">新增</el-button>
    </div>

    <el-dialog title="添加门店" :visible.sync="addShopShow" class="dialog-box-add">
      <el-form ref="form" :model="newShopInfo" label-width="80px" class="add-box">
        <el-form-item label="门店名称">
          <el-input v-model="newShopInfo.shop_name" size="small"></el-input>
        </el-form-item>
        <el-form-item label="店长">
          <el-tag size="mini" v-if="newShopInfo.shop_manager_id" class="mtag">
            <WeworkUserShow type="userName" :openid="newShopInfo.shop_manager_id"></WeworkUserShow>
          </el-tag>
          <el-button size="mini" type="primary" @click="newShowManagerShow=true">选择店长</el-button>

        </el-form-item>
        <el-form-item label="成员">
          <el-tag size="mini" v-for="item in newShopInfo.members" :key="item" class="mtag">
            <WeworkUserShow type="userName"
                            :openid="item"></WeworkUserShow>
          </el-tag>

          <el-button size="mini" type="primary" @click="newShowMemberShow=true">选择成员</el-button>


        </el-form-item>


        <el-form-item>
          <el-button size="mini" type="success" @click="submitNewShop">立即创建</el-button>
          <el-button size="mini" @click="addShopShow=false">取消</el-button>
        </el-form-item>
      </el-form>

      <!--选择店长-->
      <el-dialog
          title="选择店长"
          :visible.sync="newShowManagerShow"
          append-to-body>
        <MemberTree :single="true" v-model="newShopInfo.shop_manager_id"></MemberTree>

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="newShowManagerShow = false">完 成</el-button>
        </span>
      </el-dialog>

      <!--添加成员-->
      <el-dialog

          title="选择成员"
          :visible.sync="newShowMemberShow"
          append-to-body>
        <MemberTree v-model="newShopInfo.members"></MemberTree>

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="newShowMemberShow = false">完 成</el-button>
        </span>
      </el-dialog>

    </el-dialog>


    <el-dialog title="门店信息" :visible.sync="shopInfoShow" class="dialog-box">
      <el-descriptions class="margin-top" :title="currentShop.shop_name" :column="1" size="small" border>
        <template slot="extra">
          <!--          <el-button type="primary" size="small">操作</el-button>-->
        </template>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i>
            门店名称
          </template>
          {{ currentShop.shop_name }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            门店管理员
          </template>

          <WeworkUserShow class="mtag" type="userName" :openid="currentShop.shop_manager_id"></WeworkUserShow>

          <el-button type="primary" size="small" @click="changeManagerShow = true">改换店长</el-button>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            门店成员
          </template>

          <el-tag
              size="mini"
              class="mtag"
              :key="member"
              v-for="member in currentShop.members"
          >

            <WeworkUserShow type="userName" :openid="member"></WeworkUserShow>

          </el-tag>

          <el-button class="button-new-tag" type="primary" size="mini" @click="addMemberShow = true">成员管理</el-button>


        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-date"></i>
            创建时间
          </template>
          {{ timeFilter(currentShop.created_at) }}
        </el-descriptions-item>

      </el-descriptions>

      <span slot="footer" class="dialog-footer">
        <el-button @click="shopInfoShow = false">取 消</el-button>
        <el-button type="primary" @click="changeShopInfo">提 交</el-button>
      </span>


    </el-dialog>


    <!--改换店长dialog-->

    <el-dialog

        title="改换店长"
        :visible.sync="changeManagerShow"
        append-to-body>
      <MemberTree :single="true" v-model="currentShop.shop_manager_id"></MemberTree>

      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="changeManagerShow = false">完 成</el-button>
        </span>
    </el-dialog>

    <!--成员管理dialog-->
    <el-dialog

        title="成员管理"
        :visible.sync="addMemberShow"
        append-to-body>
      <MemberTree v-model="currentShop.members"></MemberTree>

      <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="addMemberShow = false">完 成</el-button>
        </span>
    </el-dialog>


  </div>

  <div class="ban" v-else>权限不足</div>
</template>


<script>

import {deleteWeworkShop, getCompanyUserApi, getWeworkShop, postWeworkShop} from "@/services/weworkApi";
import MemberTree from "@/views/xingtuan/components/MemberTree";
import {mapGetters} from "vuex";
import WeworkUserShow from "@/views/xingtuan/components/WeworkUserShow";

export default {
  name: "ShopIndex",
  components: {
    MemberTree,
    WeworkUserShow
  },
  data() {
    return {
      changeManagerShow: false,
      shopInfoShow: false,
      addShopShow: false,
      addMemberShow: false,
      newShowManagerShow: false,
      newShowMemberShow: false,
      currentShop: false,
      allMemberList: [],
      newShopInfo: {

        shop_name: '',
        shop_manager_id: '',
        members: [],
      },
      shopData: []

    }
  },
  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
  },
  created() {


  },
  mounted() {
    console.log(this.userInfo)
    // 获取公司成员
    this.getCompanyUser()
    // 获取公司门店
    this.getCompanyShop()


  },
  methods: {
    timeFilter: function (value, args) {
      const dt = new Date(value);

      const y = dt.getFullYear();
      //这里month得加1
      let m = dt.getMonth() + 1;
      if (m < 10) {
        m = '0' + m.toString()
      }
      let d = dt.getDate();
      if (d < 10) {
        d = '0' + d.toString()
      }
      //如果要求的时间格式只有年月日

      const hh = dt.getHours();
      let mm = dt.getMinutes();
      if (mm < 10) {
        mm = '0' + mm.toString()
      }
      let ss = dt.getSeconds();
      if (ss < 10) {
        ss = '0' + ss.toString()
      }
      return `${y}-${m}-${d} ${hh}:${mm}:${ss}`

    },
    getCompanyUser() {
      getCompanyUserApi({
        corpid: this.userInfo.wework_corpid,
      }).then(res => {
        this.allMemberList = res.data.data
      })
    },
    handleClick(row) {
      console.log(row);
      this.shopInfoShow = true
      this.currentShop = row

    },

    getCompanyShop() {
      getWeworkShop({corpid: this.userInfo.wework_corpid}).then(res => {
        this.shopData = res.data.data
      })
    },
    changeShopInfo() {
      // 权限检查
      if (this.userInfo.wework_role <= 2 || this.currentShop.shop_manager_id === this.userInfo.wework_userid) {
        this.doChangeShopInfo()
      } else {
        this.$message.error('权限不足')
      }


    },
    doChangeShopInfo() {
      postWeworkShop(this.currentShop).then(res => {
        this.$message.success('更新成功')
        this.getCompanyShop()
        this.shopInfoShow = false
      }).catch(err => {
        this.$message.success('更新失败')
      })
    },


    submitNewShop() {
      console.log(this.newShopInfo);
      if (this.newShopInfo.name != '' && this.newShopInfo.shop_manager_id != '') {
        console.log('submit!');
        this.newShopInfo['corpid'] = this.userInfo.wework_corpid
        postWeworkShop(this.newShopInfo).then(res => {
          this.$message.success('创建成功')
          this.getCompanyShop()
          this.addShopShow = false
        }).catch(err => {
          this.$message.success('创建失败')
        })
      } else {
        this.$message.error('请填写完整信息!');
      }

    },

    deleteShop(row) {
      console.log(row);
      if (this.userInfo.wework_role <= 1) {
        deleteWeworkShop(row).then(res => {
          this.$message({
            message: '删除成功',
            type: 'success'
          });
        }).catch(err => {
          this.$message.error('删除失败');
        }).finally(info => {
          this.getCompanyShop()
        })
      } else {
        this.$message.error('仅超级管理员可删除门店！')
      }


    },


  },
}
</script>


<style lang="scss" scoped>
.ban {
  text-align: center;
  padding-top: 20px;
}

.info-table {
  display: inline-block;
  max-width: 1200px;
}

.add-btn {
  text-align: right;
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px 10px;
}

.mtag {
  margin: 0 5px;
}

.dialog-box-add {


  .add-box {
    margin: 0 auto;
    width: 600px;
    text-align: left;
  }
}

/deep/ .dialog-box .el-dialog {
  background-color: rgba(20, 50, 80, 0.9);
}

/deep/ .el-dialog {
  background-color: rgba(20, 50, 80, 0.9) !important;
}


@media only screen and (max-width: 767px) {
  /deep/ .el-dialog {
    width: 98%;
  }

  .dialog-box-add {


    .add-box {
      margin: 0 auto;
      width: 98%;
      text-align: left;
    }
  }
}
</style>
