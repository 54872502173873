<template>
  <div class="main-GroupIndex">

    <el-button v-if="!newShow" type="primary" size="mini" @click="newShow=true">新建</el-button>
    <el-button v-if="newShow" type="primary" size="mini" @click="newShow=false">返回</el-button>
    <GroupMsg v-if="newShow"></GroupMsg>
    <msgTable v-if="!newShow"></msgTable>


  </div>
</template>


<script>

import GroupMsg from "@/views/xingtuan/pages/we/components/GroupMsg";
import msgTable from "@/views/xingtuan/pages/we/components/msgTable";

export default {
  name: "GroupIndex",
  components: {
    GroupMsg,
    msgTable

  },
  data() {
    return {
      newShow: false
    }
  },
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {},
}
</script>


<style lang="scss" scoped>
.main-GroupIndex{
  text-align: left;
  padding: 10px 10px;
}

</style>
