<template>
  <div class="main-DataCustomer">
    <div class="h2 title">
      卡券数据
    </div>
    <div class="total">

      <ticketData></ticketData>


    </div>


    <div class="total">

      <el-row class="total-cell total-main table-box">
        <div class="user-list">

          <div class="btns">
            <el-button-group>
              <el-button type="primary" size="mini" @click="daysChange(7)" :disabled="days===7">最近7日</el-button>
              <el-button type="primary" size="mini" @click="daysChange(15)" :disabled="days===15">最近15日</el-button>
              <el-button type="primary" size="mini" @click="daysChange(30)" :disabled="days===30">最近30日</el-button>
            </el-button-group>

            <el-select
                style="padding-left: 10px;"
                v-model="currentShop"
                size="small"
                clearable placeholder="请选择门店"
                @change="getTicketDetailData">
              <el-option
                  v-for="item in shopOptions"
                  :key="item.id"
                  :label="item.shop_name"
                  :value="item.id">
              </el-option>
            </el-select>

          </div>


        </div>

        <div class="customer-list">

          <el-row class="total-cell table-box chart-box"
                  v-loading="detailDataLoading"
                  element-loading-background="rgba(0, 80, 80, 0.5)">
            <div class="title">
              卡券使用详情：
              <el-radio-group v-model="ticketType">
                <el-radio label="get">领取</el-radio>
                <el-radio label="put">核销</el-radio>
              </el-radio-group>
            </div>

            <el-table
                size="mini"
                :data="detailData[ticketType]"
                max-height="350"
                style="height: 320px;overflow: auto;"
            >
              <el-table-column

                  label="时间">
                <template slot-scope="scope">
                  {{ scope.row.time }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="ticket_name"
                  label="名称">
              </el-table-column>
              <el-table-column
                  prop="wx_name"
                  label="微信名">
              </el-table-column>
              <el-table-column
                  prop="hexiao_id"
                  label="核销人">
                <template slot-scope="scope">
                  <WeworkUserShow class="user username" type="userName"
                                  :openid="scope.row.hexiao_id"></WeworkUserShow>
                </template>
              </el-table-column>
              <el-table-column
                  prop="hexiao_shop"
                  label="核销门店">

              </el-table-column>
            </el-table>

          </el-row>

          <el-row class="total-cell table-box  chart-box">
            <ticketChart
                :loading="ticketChartLoading"
                :ticketChartData="ticketChartData">

            </ticketChart>

          </el-row>

        </div>
      </el-row>
    </div>

  </div>
</template>


<script>

import {mapGetters, mapState} from "vuex";
import ticketData from "@/views/xingtuan/pages/index/components/ticketData";
import {getTicketChartDataApi, getTicketDetailApi} from "@/services/xingtuan";
import ticketChart from "@/views/xingtuan/pages/index/components/ticketChart";
import WeworkUserShow from "@/views/xingtuan/components/WeworkUserShow";
import {getWeworkShop} from "@/services/weworkApi";

export default {
  name: "DataTicket",
  components: {
    ticketChart,
    ticketData,
    WeworkUserShow

  },
  data() {
    return {
      days: 7,
      ticketChartLoading: false,
      ticketChartData: [],
      detailDataLoading: false,
      detailData: [],
      ticketType: 'get',

      currentShop: null,
      shopOptions: [],

      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
    }
  },
  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
    ...mapState("companys", ["companyInfo"]),
  },
  created() {

  },
  mounted() {
    this.getShopOptions()
    this.getTicketChartData()
    this.getTicketDetailData()


  },
  methods: {
    getShopOptions() {
      getWeworkShop({corpid: this.userInfo.wework_corpid}).then(res => {
        this.shopOptions = res.data.data
      })

    },
    daysChange(d) {
      this.days = d
      this.getTicketChartData()
      this.getTicketDetailData()
    },
    getTicketChartData() {
      this.ticketChartLoading = true
      getTicketChartDataApi({days: this.days,}).then(res => {
        let _ = res.data.data

        this.ticketChartData = []
        _.forEach(item => {
          this.ticketChartData.push({
            'time': " " + item.time.toString(),
            'name': item.name,
            'value': item.value,
          })
        })

      }).finally(e => {
        this.ticketChartLoading = false
      })
    },
    getTicketDetailData() {
      this.detailDataLoading = true
      getTicketDetailApi({days: this.days, shop_id: this.currentShop}).then(res => {
        this.detailData = res.data.data
      }).finally(e => {
        this.detailDataLoading = false
      })
    }
  },
}
</script>


<style lang="scss" scoped>

.total {
  text-align: center;
  padding-top: 20px;

  .total-cell {
    margin: 10px 10px 0;

    display: inline-block;
    width: calc(50% - 20px);

    .title {
      font-weight: bold;
      color: #00bc8c;
    }


  }

  .total-main {
    width: calc(100% - 20px);
  }


}

.btns {
  padding: 0 10px 10px;
  text-align: left;
}

.icon {
  width: 40px;
  height: 40px;
}

.cups {
  display: inline-block;
  width: 160px;
  padding: 10px 10px;

  .title {
    //height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
    color: #00EAFF;
  }

  .content {
    font-size: 24px;
  }

  .total {
    color: #67C23A;
  }

  .new {
    color: #00bc8c;
  }

  .bad {
    color: #9a6e3a;
  }


}

.user-list {

  font-size: 14px;
  padding: 10px 10px;
  text-align: left;
}

.customer-list {

  font-size: 14px;
  padding: 10px 10px;
  text-align: left;

  .total-cell {
    height: 370px;
  }
}


@media only screen and (max-width: 767px) {

  .chart-box {
    width: calc(100% - 20px) !important;
  }


}

</style>
