<template>
  <div class="editor-wrap">
    <froala
        id="froala-editor"
        :tag="'textarea'"
        :config="config"
        v-model="body.content"
    />
  </div>
</template>
<script>
import {uploadFileApi} from "@/services/baseApi";

const tool = [
  "undo",
  "redo",
  "clearFormatting",
  "bold",
  "italic",
  "underline",
  "strikeThrough",
  "fontFamily",
  "fontSize",
  "textColor",
  "color",
  "backgroundColor",
  "inlineStyle",
  "paragraphFormat",
  "align",
  "formatOL",
  "formatUL",
  "outdent",
  "indent",
  "quote",
  "insertLink",
  "insertImage",
  "insertVideo",
  "embedly",
  "insertFile",
  "insertTable",
  "emoticons",
  "specialCharacters",
  "insertHR",
  "selectAll",
  "print",
  "spellChecker",
  "html",
  "help",
  "fullscreen"
];

export default {
  props: {
    // 显示的工具列表
    placeholder: {
      type: String
      //   required: true
    },
    height: {
      type: Number
    },
    value: {
      type: String,
      default: null
    },
    index: {
      type: Number,
      default: 1
    }
  },
  name: "froala-editor",
  data() {
    const that = this;
    return {
      editor: null,
      uploadImage: {
        loading: false,
        previewVisible: false,
        previewImage: "",
        imgFile: {},
        isSize: false,
        isType: false
      },
      fileList: [],
      body: {
        content: null,
        textLen: 0,
        leftoverLen: 10000,
        sumLen: 10000,
        error_tip: "",
        error_show: false
      },
      config: {
        toolbarButtons: tool,
        // theme: "dark",//主题
        placeholderText: this.placeholder || "编辑卡券介绍",
        language: "zh_cn", //国际化
        imageUploadURL: "", //上传url
        imageUploadParams: {token: "", i: "", ak: "", f: 9},
        fileUploadURL: "",
        fileUploadParams: {token: "", i: "", ak: "", f: 9},
        videoUploadURL: "",
        videoUploadParams: {token: "", i: "", ak: "", f: 9},
        quickInsertButtons: ["image", "table", "ul", "ol", "hr"], //快速插入项
        // toolbarVisibleWithoutSelection: true,//是否开启 不选中模式
        // disableRightClick: true,//是否屏蔽右击
        colorsHEXInput: true, //关闭16进制色值
        toolbarSticky: false, //操作栏是否自动吸顶,
        // Colors list.
        colorsBackground: [
          "#15E67F",
          "#E3DE8C",
          "#D8A076",
          "#D83762",
          "#76B6D8",
          "REMOVE",
          "#1C7A90",
          "#249CB8",
          "#4ABED9",
          "#FBD75B",
          "#FBE571",
          "#FFFFFF"
        ],
        colorsStep: 6,
        colorsText: [
          "#15E67F",
          "#E3DE8C",
          "#D8A076",
          "#D83762",
          "#76B6D8",
          "REMOVE",
          "#1C7A90",
          "#249CB8",
          "#4ABED9",
          "#FBD75B",
          "#FBE571",
          "#FFFFFF"
        ],
        zIndex: 2501,
        height: this.height || "250",
        // autofocus: true,
        events: {
          initialized: function () {
            that.editor = this;
            that.body.content = that.value;
            that.EditorChange();
          },
          blur: () => {
            console.log("blur....");
            that.$emit("blur");
          },
          contentChanged: () => {
            that.EditorChange();
          },
          "image.beforeUpload": function (images) {
            //自定义上传图片
            that.beforeUpload(images[0]);
            return false;
          },
          "file.beforeUpload": function () {
            // Image was uploaded to the server.
            return true;
          },
          "video.beforeUpload": function () {
            // Image was uploaded to the server.
            return true;
          }
        }
      }
    };
  },
  watch: {
    value: {
      handler: function (news, old) {
        if (news) {
          this.body.content = this.value;
        }
      },
      deep: true //对象内部的属性监听，也叫深度监听
    },
    "body.content": function (newVal, old) {
      if (old !== newVal) {
        let val = this.getSimpleText(this.editor.html.get(true));
      }
    },
    label: function (newVal, old) {
      if (old !== newVal) {
        this.editor.html.set(newVal);
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.setIndex(this.index);
      this.editor.html.set(this.value);
    }, 200);


  },
  methods: {
    //更改富文本层级
    setIndex(val) {
      this.$nextTick(() => {
        let dv = document.getElementsByClassName("fr-box");
        for (let i in dv) {
          if (!dv[i].style) {
            return;
          }
          dv[i].style.cssText = "z-index:" + val;
        }
      });
    },
    //富文本中提取纯文本
    getSimpleText: html => {
      var re1 = new RegExp('<p data-f-id="pbf".+?</p>', "g"); //匹配html标签的正则表达式，"g"是搜索匹配多个符合的内容
      var msg = html.replace(re1, ""); //执行替换成空字符
      return msg;
    },
    EditorChange() {
      if (this.editor == null) return;
      console.log(this.editor, "this.editor");
      const editorCount = this.editor.charCounter.count();
      this.body.textLen = editorCount;
      this.body.leftoverLen = this.body.sumLen - editorCount;
      this.$emit("change", this.body);
    },
    beforeUpload(file) {
      const that = this;
      this.uploadImage.loading = true;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("prefix", 'image_for_ticket');
      uploadFileApi(formData).then(res=>{
        const key = res.data.data.key;
        console.log(key)
        const url = 'https://img.xingtuangroup.com/' +res.data.data.key;

        that.editor.html.insert(
                  "<img src=" + url + ">", //HTML
                  false //在插入之前是否应清除HTML
              ); //插入图片

      })

    }


  }

};
</script>
<style>

.editor-wrap > div {
  width: 100%;
}

.fr-wrapper > div[style*="z-index:9999;"],
.fr-wrapper > div[style*="z-index: 9999;"] {
  height: 0;
  overflow: hidden;
  position: absolute;
  top: -1000000px;
  opacity: 0;
}

.fr-view {
  position: absolute;
  top: 0;
}

.fr-placeholder {
  margin-top: 0;
}

.fr-box .second-toolbar {
  display: none;
}

.fr-box .second-toolbar #logo {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
}

.fr-box .fr-toolbar {
  border-radius: 4px 4px 0 0;
  border-color: #dcdfe6;
}

.fr-box .second-toolbar {
  border-radius: 0 0 4px 4px;
  border-color: #dcdfe6;
}

.fr-box .fr-wrapper {
  border-color: #dcdfe6 !important;
}
</style>
