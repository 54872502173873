<template>
  <div class="main-PayForAuth">
  PayForAuth

  </div>
</template>


<script>

export default {
  name: "PayForAuth",
  components: {

  },
  data() {
    return {

    }
  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
  methods: {

  },
}
</script>


<style lang="scss" scoped>

</style>
