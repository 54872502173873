import Vue from 'vue';
import Vuex from 'vuex';
import user from './modules/user'
import permission from './modules/permission'

Vue.use(Vuex)

const modulesFiles = require.context('./modules', true, /\.js$/);

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
    // set './app.js' => 'app'
    const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
    const value = modulesFiles(modulePath);
    modules[moduleName] = value.default;
    return modules
}, {});
modules.user = user;
modules.permission = permission;


const store = new Vuex.Store({
    modules,
    // 全局变量
    state: {

    },
    mutations: {

    },
    getters: {
        token: state => state.user.token,
        userInfo: state => state.user.userInfo,
        isSupervisor: state => state.user.userInfo.is_supervisor,
        addRoutes: state => state.permission.addRoutes,
        permissions: state => state.permission.permissions,

    },

})

export default store;
