<template>
  <div class="main-XingIndex">
    <div class="welcome"><span class="welcome-tip">欢迎您：
    </span>{{ companyInfo.corp_name }} -
      <WeworkUserShow class="user username" type="userName"
                      :openid="userInfo.wework_userid"></WeworkUserShow>
    </div>

    <div class="h2 title">
      企业主页
    </div>

    <div class="quick-menu" v-if="activeStatus>=3">
      <div>待处理：</div>

      <!--      activeStatus-->
      <div>您有新的企业成员待激活或有成员企微激活即将到期,建议您先处理成员资料继承关系。处理完成后，请点击"激活新成员"激活，星团后台会为您处理成员相关权限,一般最晚两个工作日内处理完毕！</div>
      <div class="menu-list">
<!--        <el-button type="primary" size="mini" @click="toPage('member_extend')">成员继承</el-button>-->

        <el-button v-if="activeStatus>=3" type="primary" size="mini" :loading="activeBtn" @click="activeMember">激活新成员
        </el-button>

      </div>

    </div>

    <div class="quick-menu">
      快捷菜单
      <div class="menu-list">
<!--        <el-button class="menu-cell" type="primary" size="mini" @click="toPage('index_guide')">平台指引</el-button>-->
        <el-button class="menu-cell" type="primary" size="mini" @click="toPage('index_data')">数据总览</el-button>
        <el-button class="menu-cell" type="primary" size="mini" @click="toPage('data_customer')">数据分析-客户</el-button>
        <el-button class="menu-cell" type="primary" size="mini" @click="toPage('coupon_new')">新建卡券</el-button>
        <el-button class="menu-cell" type="primary" size="mini" @click="toPage('coupon_hexiao')">卡券核销</el-button>
      </div>

    </div>

    <div class="main">

      <el-card class="main-cell  table-box">


        <div class="corp-info">

          <el-row class="corp-info-item">
            <el-col class="label">企业logo</el-col>
            <el-col class="cont"><img class="logo" :src="companyInfo.corp_square_logo_url" alt="logo"></el-col>
          </el-row>

          <el-row class="corp-info-item">
            <el-col class="label">企业全称</el-col>
            <el-col class="cont">{{ companyInfo.corp_full_name }}</el-col>
          </el-row>
          <el-row class="corp-info-item">
            <el-col class="label">企业简称</el-col>
            <el-col class="cont">{{ companyInfo.corp_name }}</el-col>
          </el-row>
          <el-row class="corp-info-item">
            <el-col class="label">企业版本</el-col>
            <el-col class="cont">{{
                companyInfo.version === 1 ? '通用版' : companyInfo.version === 2 ? '旗舰版' : '未知'
              }}
            </el-col>
          </el-row>
          <el-row class="corp-info-item">
            <el-col class="label">企业类型</el-col>
            <el-col class="cont">{{ companyTypeMap[companyInfo.subject_type] }}</el-col>
          </el-row>
          <el-row class="corp-info-item">
            <el-col class="label">企业用户规模</el-col>
            <el-col class="cont">{{ companyInfo.corp_user_max }}</el-col>
          </el-row>
          <el-row class="corp-info-item">
            <el-col class="label">企业规模</el-col>
            <el-col class="cont">{{ companyInfo.corp_scale }}</el-col>
          </el-row>
          <el-row class="corp-info-item">
            <el-col class="label">企业所属行业</el-col>
            <el-col class="cont">{{ companyInfo.corp_industry }}</el-col>
          </el-row>
          <el-row class="corp-info-item">
            <el-col class="label">企业所属子行业</el-col>
            <el-col class="cont">{{ companyInfo.corp_sub_industry }}</el-col>
          </el-row>
          <el-row class="corp-info-item">
            <el-col class="label">所在地</el-col>
            <el-col class="cont">{{ companyInfo.location }}</el-col>
          </el-row>
          <el-row class="corp-info-item">
            <el-col class="label">企业应用授权到期日</el-col>
            <el-col class="cont">{{ companyInfo.end_date }}</el-col>
          </el-row>


        </div>


      </el-card>
      <el-card class="main-cell table-box">
        公告信息


        <el-collapse accordion>
          <el-collapse-item>
            <template slot="title">
              空
            </template>
<!--            <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>-->
<!--            <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>-->
          </el-collapse-item>

        </el-collapse>
      </el-card>

    </div>

  </div>
</template>


<script>

import {mapActions, mapGetters, mapState} from "vuex";
import {activeOrderApi, checkAccountActiveInfoApi} from "@/services/weworkApi";
import WeworkUserShow from "@/views/xingtuan/components/WeworkUserShow";

export default {
  name: "WeworkIndex",
  components: {
    WeworkUserShow
  },
  props: {},
  data() {
    return {
      showName: true,
      activeStatus: 0,
      activeBtn: false,
      companyTypeMap: {
        1: '企业',
        2: '政府以及事业单位',
        3: '其他组织',
        4: '团队号',
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo']),
    ...mapState("companys", ["companyInfo"]),

  },
  created() {

  },
  mounted() {
    this.getCompanyInfo({corpid: this.userInfo.wework_corpid})
    this.checkAccountActiveInfo()

    // setTimeout(this.showUser, 500)


  },
  methods: {
    ...mapActions("companys", ["getCompanyInfo"]),
    // showUser() {
    //   this.showName = true
    // },

    checkAccountActiveInfo() {
      checkAccountActiveInfoApi().then(res => {
        this.activeStatus = res.data.data.status
        if (this.activeStatus === 4) {
          this.activeBtn = true
        }
      }).catch(err => {

        // console.log(err.response.data.data)
        // this.$message.warning(err.response.data.data.msg)

      })

    },
    toPage(val) {
      this.$emit('toPage', val)
    },
    activeMember() {
      activeOrderApi().then(res=>{
        this.$message.warning(res.data.data.msg)
      }).catch(err => {
        // this.$message.warning(err.response.data.data.msg)
      })
      this.activeBtn = true

    }
  },
}
</script>


<style lang="scss" scoped>
.welcome {
  font-size: 22px;
  font-weight: bold;
  margin: 0 auto;
  text-align: left;
  padding: 0 10px;

  .welcome-tip {
    font-size: 14px;
    font-weight: normal;
  }
}


.quick-menu {
  padding: 10px 10px;
  text-align: left;
  font-size: 14px;
  color: #0d8092;


  .menu-list {
    padding: 10px 10px;

    .menu-cell {
      margin: 6px 6px;
    }

  }
}

.main {
  text-align: center;


  .main-cell {
    height: 500px;
    overflow: auto;
    margin: 10px 10px 0;
    border: none;
    //background-color: rgba(130, 0, 150, 0.2);

    display: inline-block;
    width: calc(50% - 20px);
  }
}

.logo {

  height: 40px;

}

.tip {
  color: #00bc8c;
}

.corp-info {
  padding: 10px 10px;
  text-align: left;
  color: #111;
  font-size: 14px;

  .corp-info-item {
    padding: 5px 10px;
  }


  .label {
    display: inline-block;
    width: 130px;
     color: #111;
  }

  .cont {
    display: inline-block;
    width: calc(100% - 130px);
  }
}


@media only screen and (max-width: 767px) {
  .main {


    .main-cell {

      width: calc(100% - 20px);
    }
  }

}
</style>
