<template>
  <div class="main-clientTable"
  >
    <div class="back-img"
         :style="'background-image: url(\' ' +backImg+ '\')'"
    >

    </div>

    <div class="shop-img">
      <svg-icon class="icon" iconname="icon-diancan"></svg-icon>
    </div>

    <div class="pt60 pb40 f20 main-t">欢迎光临</div>
    <div class="pb40 f20 main-t">{{ tableInfo.shop_info.shop_name }}</div>
    <div class="pb40 f20 main-t table-name">{{ tableInfo.table_name }}</div>
    <div style="text-align: center;">
      <el-button v-if="is_exists && tableInfo.type=='H5'" type="primary" size="mini" @click="toTable">立 即 点 餐
      </el-button>
      <div v-if="is_exists && tableInfo.type=='小程序'">
        <wx-open-launch-weapp
            id="launch-btn"
            :appid="tableInfo.mini_appid"
            :path="tableInfo.mini_page"
        >
          <template>
            <button class="btn" type="primary" size="mini">立 即 点 餐</button>
          </template>
        </wx-open-launch-weapp>

      </div>


      <div v-if="!is_exists" class="main-ac">识别下方二维码点餐</div>

      <div v-if="!is_exists" class="img-box">
        <el-image
            class="img-cell"
            :src="tableInfo.qr_code"
            style="width: 100%; height: 100%"
            fit="contain">
        </el-image>
      </div>
    </div>

  </div>


</template>


<script>
import {getUserStatusApi} from "@/services/table";
import {getWxPayConfigApi} from "@/services/weworkApi";

export default {
  name: "clientTable",
  components: {},
  data() {
    return {
      is_exists: true,
      tableInfo: {shop_info: {}},
      backImg: '/img/table_back.jpg',
    }
  },
  props: {

    wxUserInfo: {
      type: Object,
      required: true
    },
  },
  computed: {
    table_id() {
      return this.$route.query.table_id
    },
  },
  created() {


  },
  mounted() {
    this.getUserStatus()
    this.weworkInit()

  },
  watch: {
    wxUserInfo() {
      this.getUserStatus()
    }
  },
  methods: {
    toTable() {
      if (this.tableInfo.type == 'H5') {
        window.location.href = this.tableInfo.url
      } else if (this.tableInfo.type == '小程序') {
        this.weworkInit()
      }


    },
    async weworkInit() {
      getWxPayConfigApi({url: window.location.href}).then(res => {
        this.config(res.data)
      })

    },
    async config(config) {
      wx.config(config);
      wx.ready(function () {
        //config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
      });
      wx.error(function (res) {
        console.log('res', res);
        // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
      })


    },

    getUserStatus() {
      if (!this.table_id) {
        this.$message.error('二维码已失效')
      } else {
        if (this.wxUserInfo.openid && this.wxUserInfo.unionid) {
          getUserStatusApi({
            user_id: this.wxUserInfo.openid,
            unionid: this.wxUserInfo.unionid,
            table_id: this.table_id
          }).then(res => {
            this.is_exists = res.data.data.is_exists
            this.tableInfo = res.data.data
            if (res.data.data.back_img) {
              this.backImg = res.data.data.back_img
            }
          })
        }

      }

    }

  },
}
</script>


<style lang="scss" scoped>

.main-clientTable {

  min-height: calc(100vh - 30px);


  width: 100%;
  text-align: center;
  position: relative;


  .back-img {
    min-height: calc(100vh - 30px);
    width: 100%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    z-index: -10;
    position: fixed;
    top: 0;
  }


  .shop-img {
    display: inline-block;
    margin-top: 140px;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    overflow: hidden;


  }

  .main-t {
    font-weight: bold;
    color: #c69500;
  }


  .main-ac {
    color: #ccc;
  }

  .table-name {
    color: #00ff80;
  }

  .img-box {
    display: inline-block;
    width: 120px;
    height: 120px;
  }
}
</style>
