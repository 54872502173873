<template>
  <div class="main-MemberTree">
    <!--    <el-row separator-class="el-icon-arrow-right">-->
    <!--      &lt;!&ndash;      <span class="d-item" @click="dClick(0)">{{ companyInfo.corp_name }}</span>&ndash;&gt;-->
    <!--      <span v-for="item in checkDepartmentList" :key="item.id">-->

    <!--      <span class="d-item" @click="dClick(item.id)">-->
    <!--        <WeworkUserShow type="departmentName" :openid="item.id"></WeworkUserShow>-->
    <!--      </span>-->
    <!--        <span> > </span>-->
    <!--      </span>-->
    <!--    </el-row>-->

    <!--    <div>-->
    <!--      部门：-->
    <!--    </div>-->
    <!--    <div class="department-item" v-for="part in departmentList" :key="part.id" @click="dClick(part.id)">-->

    <!--      <WeworkUserShow type="departmentName" :openid="part.id"></WeworkUserShow>-->

    <!--    </div>-->

    <div>
      成员：
    </div>

    <el-checkbox-group v-if="!single" v-model="value"
                       @change="memberChange"
    >
      <el-checkbox class="user-item"
                   v-for="member in departmentUserList" :label="member" :key="member">
        <WeworkUserShow type="userName" :openid="member"></WeworkUserShow>
      </el-checkbox>
    </el-checkbox-group>

    <el-radio-group v-model="value" v-if="single" @change="memberChange">
      <el-radio class="user-item" v-for="member in departmentUserList" :label="member" :key="member">
        <WeworkUserShow class="user-name" type="userName" :openid="member"></WeworkUserShow>
      </el-radio>
    </el-radio-group>


  </div>
</template>

<script>

import {mapGetters, mapState} from "vuex";
import {getCompanyDepartmentApi, getCompanyUserApi} from "@/services/weworkApi";
import WeworkUserShow from "@/views/xingtuan/components/WeworkUserShow";

export default {
  name: "MemberTree",
  components: {
    WeworkUserShow
  },
  // model: {
  //           prop: 'value',
  //           event: 'input'
  //       },
  props: {
    value:{

    },

    single: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentDepartmentId: 0,
      departmentList: [],
      departmentUserList: [],
      checkDepartmentList: [],
      memberList: [],
      memberId: '',

      baseConfig: {},
      ageentConfig: {},
    }
  },

  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
    ...mapState("companys", ["companyInfo"]),
    departmentDict() {
      let _ = {}
      this.departmentList.forEach(p => {
        _[p.id] = p
      })
      return _
    }

  },

  created() {
    // this.getCompanyDepartment()
    this.getCompanyUser()


  },
  mounted() {

  },
  methods: {
    doParent(val, l) {
      if (this.departmentDict[val]['parentid'] != 0) {
        l.push(this.departmentDict[this.departmentDict[val]['parentid']])

        this.doParent(this.departmentDict[val]['parentid'], l)

      }
      return l

    },

    dClick(val) {

      if (val === 0) {
        this.currentDepartmentId = 0
        this.checkDepartmentList = [this.departmentDict[val]]
      } else {
        this.currentDepartmentId = val
        let _ = [this.departmentDict[val]]
        _ = this.doParent(val, _)
        _.reverse()
        this.checkDepartmentList = _

      }

    },
    getCompanyDepartment() {
      getCompanyDepartmentApi({corpid: this.userInfo.wework_corpid}).then(res => {
        this.departmentList = res.data.data
        this.checkDepartmentList = [this.departmentDict[0]]
      })
    },
    getCompanyUser() {
      getCompanyUserApi({
        corpid: this.userInfo.wework_corpid,
        department_id: this.currentDepartmentId
      }).then(res => {
        this.departmentUserList = res.data.data
      })
    },
    memberChange(val) {
      this.$emit('input', val)
      this.$emit('change', val)
    }


  },
}
</script>


<style lang="scss" scoped>
.d-item {
  cursor: pointer;
  display: inline-block;
  padding: 0 5px;
  color: whitesmoke;

  &:hover {
    color: #00EAFF;

  }
}

.department-item {
  color: #cccccc;
  padding: 5px 10px;
  cursor: pointer;

  &:hover {
    color: #00EAFF;
    border: 1px solid rgba(100, 222, 222, 0.2);
    box-shadow: rgba(11, 127, 235, 0.87) 0 0 8px inset;

  }

}

.user-item {
  display: inline-block;
  color: #cccccc;
  padding: 5px 10px;

  .user-name {
    display: inline-block;
  }

}

/deep/ .el-checkbox__label {
  color: #00bc8c !important;
}

/deep/ .is-checked .el-checkbox__label {
  color: #00EAFF !important;
}
</style>
