<template>
  <div class="total">
    <!--      卡券-->
    <el-row class="total-cell table-box" v-loading="ticketDataLoading"
            element-loading-background="rgba(0, 80, 80, 0.5)">
      <div class="cups">
        <div>
          <svg-icon class="icon" iconname="icon-youhuiquan5"></svg-icon>
        </div>
        <div class="title">历史发行总卡券数</div>
        <div class="content">
          <ICountUp class="content total" :endVal="ticketData.all_total" :options="options"/>
        </div>

      </div>
      <div class="cups">
        <div>
          <svg-icon class="icon" iconname="icon-youhuiquan5"></svg-icon>
        </div>
        <div class="title">发行中卡券数</div>
        <div class="content">
          <ICountUp class="content total" :endVal="ticketData.on_total" :options="options"/>
        </div>

      </div>
      <div class="cups">
        <div>
          <svg-icon class="icon" iconname="icon-youhuiquan6"></svg-icon>
        </div>
        <div class="title">已领取卡券数</div>
        <div class="content">
          <ICountUp class="content new" :endVal="ticketData.all_get" :options="options"/>
        </div>

      </div>
      <div class="cups">
        <div>
          <svg-icon class="icon" iconname="icon-tuiquan"></svg-icon>
        </div>
        <div class="title">已核销卡券数</div>
        <div class="content">
          <ICountUp class="content bad" :endVal="ticketData.all_put" :options="options"/>
        </div>

      </div>

    </el-row>

    <!--      卡券领取-->
    <el-row class="total-cell table-box" v-loading="ticketDataLoading"
            element-loading-background="rgba(0, 80, 80, 0.5)">
      <div class="cups">
        <div>
          <svg-icon class="icon" iconname="icon-youhuiquan7"></svg-icon>
        </div>
        <div class="title">昨日领取数</div>
        <div class="content">
          <ICountUp class="content total" :endVal="ticketData.get1" :options="options"/>
        </div>

      </div>
      <div class="cups">
        <div>
          <svg-icon class="icon" iconname="icon-tuiquan"></svg-icon>
        </div>
        <div class="title">昨日核销数</div>
        <div class="content">
          <ICountUp class="content new" :endVal="ticketData.put1" :options="options"/>
        </div>

      </div>
      <div class="cups">
        <div>
          <svg-icon class="icon" iconname="icon-coupon-fill"></svg-icon>
        </div>
        <div class="title">30日领取数</div>
        <div class="content">
          <ICountUp class="content bad" :endVal="ticketData.get30" :options="options"/>
        </div>

      </div>
      <div class="cups">
        <div>
          <svg-icon class="icon" iconname="icon-tuiquan"></svg-icon>
        </div>
        <div class="title">30日核销数</div>
        <div class="content">
          <ICountUp class="content bad" :endVal="ticketData.put30" :options="options"/>
        </div>

      </div>
    </el-row>
  </div>
</template>


<script>

import {getTicketDataApi} from "@/services/xingtuan";
import ICountUp from "vue-countup-v2";

export default {
  name: "ticketData",
  components: {
    ICountUp
  },
  data() {
    return {
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },

      ticketDataLoading: false,
      ticketData: {
        all_total: 1000,
        on_total: 1000,
        all_get: 1000,
        all_put: 1000,
        get1: 1000,
        put1: 1000,
        get30: 1000,
        put30: 1000,
      },

    }
  },
  computed: {},
  created() {

  },
  mounted() {
    this.getTicketData()

  },
  methods: {
    getTicketData() {
      this.ticketDataLoading = true
      getTicketDataApi().then(res => {
        this.ticketData = res.data.data
      }).finally(e => {
        this.ticketDataLoading = false
      })
    },

  },
}
</script>


<style lang="scss" scoped>

.total {
  text-align: center;
  padding-top: 20px;

  .total-cell {
    margin: 10px 10px 0;


    display: inline-block;
    width: calc(50% - 20px);

    .title {
      font-weight: bold;
      color: #00bc8c;
    }


  }


}

.icon {
  width: 40px;
  height: 40px;
}

.cups {
  display: inline-block;
  width: 160px;
  padding: 10px 10px;

  .title {
    //height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
    color: #00EAFF;
  }

  .content {
    font-size: 24px;
  }

  .total {
    color: #67C23A;
  }

  .new {
    color: #00bc8c;
  }

  .bad {
    color: #9a6e3a;
  }


}


.detail {
  text-align: center;
  margin: 10px 10px;
  background-color: rgba(130, 0, 150, 0.2);
}


@media only screen and (max-width: 767px) {
  /deep/ .total {
    .total-cell {
      width: calc(100% - 20px);

    }
  }

}
</style>
