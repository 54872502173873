<template>
  <div class="main-DataAll">
    <div class="h2 title">
      数据总览
    </div>

    <div class="total">
      <div class="total-box ">
        <customerData
            :customerDataLoading="customerDataLoading"
            :customerData="customerData"
        ></customerData>
      </div>
      <div class="total-box">
        <groupData></groupData>
      </div>

      <ticketData></ticketData>

      <div class="btns">
        <el-button-group>
          <el-button type="primary" size="mini" @click="daysChange(7)" :disabled="days===7">最近7日</el-button>
          <el-button type="primary" size="mini" @click="daysChange(15)" :disabled="days===15">最近15日</el-button>
          <el-button type="primary" size="mini" @click="daysChange(30)" :disabled="days===30">最近30日</el-button>
        </el-button-group>
      </div>
      <el-row class="total-cell table-box">
        <customerChart
            :loading="customerChartLoading"
            :customerChartData="customerChartData">

        </customerChart>

      </el-row>

      <el-row class="total-cell table-box">
        <ticketChart
            :loading="ticketChartLoading"
            :ticketChartData="ticketChartData">

        </ticketChart>

      </el-row>


    </div>


  </div>
</template>


<script>
import ticketData from "@/views/xingtuan/pages/index/components/ticketData";
import customerData from "@/views/xingtuan/pages/index/components/customerData";
import groupData from "@/views/xingtuan/pages/index/components/groupData";
import {getCustomerDataApi, getTicketChartDataApi} from "@/services/xingtuan";
import customerChart from "@/views/xingtuan/pages/index/components/customerChart";
import ticketChart from "@/views/xingtuan/pages/index/components/ticketChart";

export default {
  name: "DataAll",
  components: {

    groupData,
    customerData,
    ticketData,
    customerChart,
    ticketChart
  },
  data() {
    return {
      customerChartLoading: false,
      ticketChartLoading: false,
      customerDataLoading: false,
      customerData: {
        customer_count: 1000,
        new: 1000,
        release: 1000,
        customer_active_data: [],
      },
      days: 7,

      ticketChartData: [
        {name: 'London', time: '01-01', value: 18, gr: 22},
        {name: 'London', time: '01-02', value: 28, gr: 32},
        {name: 'London', time: '01-03', value: 39, gr: 42},
        {name: 'London', time: '01-04', value: 81, gr: 52},
        {name: 'London', time: '01-05', value: 47, gr: 32},
        {name: 'London', time: '01-06', value: 20, gr: 22},
        {name: 'London', time: '01-07', value: 24, gr: 52},
        {name: 'London', time: '01-08', value: 35, gr: 32},
        {name: 'Berlin', time: '01-01', value: 12, gr: 22},
        {name: 'Berlin', time: '01-02', value: 23, gr: 42},
        {name: 'Berlin', time: '01-03', value: 34, gr: 52},
        {name: 'Berlin', time: '01-04', value: 99, gr: 12},
        {name: 'Berlin', time: '01-05', value: 52, gr: 32},
        {name: 'Berlin', time: '01-06', value: 35, gr: 22},
        {name: 'Berlin', time: '01-07', value: 37, gr: 22},
        {name: 'Berlin', time: '01-08', value: 42, gr: 42}
      ],


    }
  },
  computed: {
    customerChartData() {
      let _ = []
      if (this.customerData.customer_active_data) {
        this.customerData.customer_active_data.forEach(item => {
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.new_contact_cnt,
            'name': '新增',
          })
          _.push({
            'time': this.processTimeStamp(item.stat_time),
            'value': item.negative_feedback_cnt,
            'name': '流失',
          })
        })
      }

      return _

    }
  },
  created() {

  },
  mounted() {


    this.getCustomerData()
    this.getTicketChartData()

  },
  methods: {
    processTimeStamp(t) {
      const date = new Date(t * 1000);  // 参数需要毫秒数，所以这里将秒数乘于 1000
      const Y = date.getFullYear() + '-';
      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      const D = date.getDate() + ' ';
      const h = date.getHours() + ':';
      const m = date.getMinutes() + ':';
      const s = date.getSeconds();
      return Y + M + D

    },
    daysChange(d) {
      this.days = d
      this.getCustomerData(false)
      this.getTicketChartData()

    },
    getCustomerData(one = true) {
      if (one) {
        this.customerDataLoading = true
      }
      this.customerChartLoading = true
      getCustomerDataApi({days: this.days,}).then(res => {
        this.customerData = res.data.data
      }).finally(e => {
        if (one) {
          this.customerDataLoading = false
        }
        this.customerChartLoading = false
      })
    },
    getTicketChartData(){
      this.ticketChartLoading = true
      getTicketChartDataApi({days: this.days,}).then(res => {
         let _= res.data.data

        this.ticketChartData = []
        _.forEach(item=>{
          this.ticketChartData.push({
            'time': " "+item.time.toString(),
            'name': item.name,
            'value': item.value,
          })
        })
        console.log(this.ticketChartData)
      }).finally(e => {
        this.ticketChartLoading = false
      })
    }
  },

}
</script>


<style lang="scss" scoped>

.total {
  text-align: center;
  padding-top: 20px;

  .total-box {
    margin: 10px 10px 0;
    display: inline-block;
    width: calc(50% - 20px);
  }

  .total-cell {
    margin: 10px 10px 0;


    display: inline-block;
    width: calc(50% - 20px);

    .title {
      font-weight: bold;
      color: #00bc8c;
    }


  }


}

.icon {
  width: 40px;
  height: 40px;
}

.cups {
  display: inline-block;
  width: 160px;
  padding: 10px 10px;

  .title {
    //height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
    color: #00EAFF;
  }

  .content {
    font-size: 24px;
  }

  .total {
    color: #67C23A;
  }

  .new {
    color: #00bc8c;
  }

  .bad {
    color: #9a6e3a;
  }


}


.detail {
  text-align: center;
  margin: 10px 10px;
  background-color: rgba(130, 0, 150, 0.2);
}

.btns {
  text-align: left;
  //padding-top: 20px;
  padding: 20px 10px 0;

}

@media only screen and (max-width: 767px) {
  .total {
    .total-box {
      width: 100%;
    }

    .total-cell {
      width: calc(100% - 20px);

    }
  }

}
</style>
