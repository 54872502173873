<template>
  <div class="main-clientHome">


    <div class="user-info">
      <div class="head-img">
        <img :src="wxUserInfo.headimgurl" alt="img">
      </div>
      <div class="head-info user-name">
        {{ wxUserInfo.nickname }}
      </div>
      <div class="head-info level">
        <svg-icon :iconname="levelIcon" class="icon"></svg-icon>
      </div>


      <div class="active-box">

        <div class="active-cell" @click="showPage='ticket'">
          <svg-icon iconname="icon-youhuiquan6" class="icon"></svg-icon>
          我的卡券
        </div>


        <div class="active-cell" @click="showPage='order'">
          <svg-icon iconname="icon-youhuiquan5" class="icon"></svg-icon>
          我的订单
        </div>

      </div>

    </div>


    <!--    我的卡券-->
    <div class="ticket-box" v-if="showPage==='ticket'">
      <el-row class="tabs">
        <el-col :span="8" class="tab-cell"
                :class="filterType==='已领取' ?  'active' : ''"
        >
          <div @click="handleClick('已领取')">已领取</div>
        </el-col>
        <el-col :span="8" class="tab-cell"
                :class="filterType==='已使用' ?  'active' : ''"
        >
          <div @click="handleClick('已使用')">已使用</div>
        </el-col>
        <el-col :span="8" class="tab-cell" :class="filterType==='已失效' ?  'active' : ''"
        >
          <div @click="handleClick('已失效')">已失效</div>
        </el-col>
      </el-row>


      <div class="ticket-pool" v-loading="loading"  element-loading-background="rgba(0, 80, 80, 0.5)">

        <div
            class="ticket-cell"
            v-for="(item, index) in showTickets"
            :key="index"
            @click="ticketClick(item)">
          <ticketCell

              :ticketInfo="item.ticket_info"
              :ticketStatus="item.status"
              :activeTime="formatDate(item.active_time)"
              :deadTime="formatDate(item.dead_time)"

          ></ticketCell>
        </div>

        <el-pagination
            style="padding-top: 10px;"

            @current-change="handleCurrentTicketChange"
            :current-page.sync="currentTicketPage"
            :page-size="pageSize"
            layout="prev, pager, next"
            :total="filterTickets.length">
        </el-pagination>
      </div>


    </div>

    <!--    我的订单-->
    <div class="order-box" v-if="showPage==='order'">
      <div class="order-cell" v-for="(item,index) in showOrders" :key="index">
        <div class="order-header">
          {{ item.corp_info.corp_name }}
        </div>
        <div class="order-name">{{ item.ticket_info.name }}</div>
        <div class="order-time">时间： {{ formatDateTime(item.created_at) }}</div>

        <div class="order-price">
          ￥{{ item.payment }}
        </div>

        <div class="order-tip">
          {{ formatStatus(item.status) }}
        </div>

      </div>

      <el-pagination
          style="padding-top: 10px;"

          @current-change="handleCurrentOrderChange"
          :current-page.sync="currentOrderPage"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="orderList.length">
      </el-pagination>
    </div>


    <el-dialog

        :title="currentTicket.ticket_info.corpname"
        :visible.sync="ticketDetailShow"
        width="98%"
        custom-class="ticket-dialog"
    >
      <ticketDetail :ticket-info="currentTicket"></ticketDetail>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="ticketDetailShow = false">关 闭</el-button>
      </span>
    </el-dialog>


  </div>
</template>


<script>

import ticketCell from "@/views/xingtuan/clientPages/clientComponents/ticketCell";
import ticketDetail from "@/views/xingtuan/clientPages/clientComponents/ticketDetail";
import {getUserOrderApi, getUserTicketApi} from "@/services/client";
import moment from "moment";

export default {
  name: "clientHome",
  components: {
    ticketCell,
    ticketDetail
  },
  props: {

    wxUserInfo: {
      type: Object,
      required: true
    },
  },

  data() {
    return {
      timer: null,
      loading: false,
      ticketDetailShow: false,

      showPage: 'ticket',
      filterType: '已领取',
      currentTicket: {ticket_info: {corpname: ''}},
      ticketList: [],
      pageSize: 5,
      currentTicketPage: 1,
      currentOrderPage: 1,

      orderList: []


    }
  },
  watch:{
    ticketDetailShow(n, o){
      if(n){
        this.timer = setInterval(this.getUserTickets, 3000)
      }else{
        clearInterval(this.timer)
        this.getUserTickets()
      }
    }
  },
  computed: {
    userLevel(){
      let _ = 1
      this.ticketList.forEach(item=>{
        if (item.status === '已使用'){
          _  = _ + 1
        }
      })
      console.log(Math.ceil(_/5))
      return  Math.ceil(_/5)

    },
    showOrders() {
      return this.orderList.slice((this.currentOrderPage - 1) * this.pageSize, this.currentOrderPage * this.pageSize)

    },
    showTickets() {
      return this.filterTickets.slice((this.currentTicketPage - 1) * this.pageSize, this.currentTicketPage * this.pageSize)

    },
    filterTickets() {
      let _ = []
      this.ticketList.forEach(item => {
        if (item.status === this.filterType) {
          _.push(item)
        }
      })
      return _
    },
    levelIcon() {
      if (this.userLevel) {
        const _map = {
          1: 'icon-chuangxiangqingtongvip',
          2: 'icon-chuangxiangbaiyinvip',
          3: 'icon-chuangxianghuangjinvip',
          4: 'icon-chuangxiangzijinvip',
          5: 'icon-chuangxiangzuanshivip',
          6: 'icon-icon-test',
        }

        return _map[this.userLevel]
      } else {
        return 'icon-icon-test'
      }
    }
  },
  created() {

  },
  mounted() {
    this.getUserTickets()
    this.getUserOrders()

  },
  methods: {
    formatDateTime(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
    formatStatus(val) {
      if (val === 'SUCCESS') {
        return '支付成功'
      } else if (val === 'USERPAYING') {
        return '支付中'
      } else if (val === 'NOPAY') {
        return '已取消'
      }
    },
    handleCurrentOrderChange(val) {
      this.currentOrderPage = val

    },
    handleCurrentTicketChange(val) {
      this.currentTicketPage = val

    },

    formatDate(val) {
      return moment(val).format('YYYY-MM-DD')
    },
    getUserTickets() {
      this.loading = true
      getUserTicketApi({wx_id: this.wxUserInfo.openid}).then(res => {
        this.ticketList = res.data.data
      }).finally(e=>{
        this.loading = false
      })
    },
    getUserOrders() {
      getUserOrderApi({wx_id: this.wxUserInfo.openid}).then(res => {
        this.orderList = res.data.data
      })
    },

    handleClick(tab) {
      this.filterType = tab
      console.log(tab);
    },
    ticketClick(item) {
      console.log(item);
      this.currentTicket = item
      this.ticketDetailShow = true
    }
  },
}
</script>


<style lang="scss" scoped>
.main-clientHome {
  min-height: 100vh;
  background-image: linear-gradient(to bottom right, rgba(18, 1, 89, 0.7), rgba(82, 10, 53, 0.3));


  .user-info {
    padding-top: 30px;
    //height: 260px;
    width: 100%;
    background-image: linear-gradient(to bottom right, rgba(100, 80, 60, 0.9), rgba(120, 110, 180, 0.8));

    .head-img {
      display: inline-block;
      width: 80px;
      margin: 0 20px;
      height: 80px;
      float: left;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%
      }
    }

    .head-info {
      display: inline-block;
      width: calc(100% - 120px);
      height: 40px;
      line-height: 40px;
      float: right;
      text-align: left;
    }

    .user-name {
      font-size: 18px;
      font-weight: bold;
    }

    .level {
      text-align: left;

      .icon {
        width: 60px;
        height: 20px;
      }
    }


    .active-box {
      text-align: center;

      .active-cell {
        cursor: pointer;
        display: inline-block;
        width: 80px;
        height: 80px;
        line-height: 24px;

        margin: 20px 16px;
        font-size: 10px;

        .icon {
          height: 50%;
        }


        &:hover {
          background-color: rgba(100, 80, 70, 0.3);
          transform: scale(1.05);
        }

      }
    }

  }


  .tabs {
    text-align: center;
    font-size: 12px;
    height: 30px;
    line-height: 30px;
    color: #00bc8c;


    .tab-cell {
      cursor: pointer;
      border-bottom: 1px solid rgba(100, 80, 70, 0.6);

      &:hover {
        color: #cccccc;
        border-bottom: 1px solid rgba(100, 80, 70, 1);
      }
    }

    .active {
      color: #cccccc;
      border-bottom: 1px solid rgba(100, 80, 70, 1);
    }


  }


  .order-box {
    text-align: center;


    .order-cell {
      position: relative;
      text-align: left;
      margin: 6px 0;
      padding: 6px 10px;
      border-radius: 5px;
      display: inline-block;
      //height: 100px;
      width: 94%;
      background-color: rgba(120, 110, 80, 0.3);


      .order-header {


        font-size: 16px;
        font-weight: bold;
      }

      .order-name {

        overflow: hidden;
        height: 40px;
        line-height: 40px;
      }


      .order-time {


        overflow: hidden;
        font-size: 8px;
        color: #666666;
      }

      .order-price {
        width: 80px;
        font-weight: bolder;
        position: absolute;
        top: 36px;
        left: calc(100% - 80px);
      }

      .order-tip {
        position: absolute;
        top: 6px;
        padding: 2px 4px;
        border-radius: 4px;
        left: calc(100% - 60px);
        font-size: 8px;
        background-color: rgba(120, 110, 80, 0.3);
      }
    }
  }

  .ticket-box {


    .ticket-cell {
      margin: 8px 4px 2px;
    }

  }

}


/deep/ .ticket-dialog {

  border-radius: 10px;
  background-color: rgba(40, 30, 45, 0.9);

}


/deep/ .el-table__expanded-cell {
  padding: 10px 10px !important;
}

/deep/ .el-pagination button:disabled {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pager li {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pagination .btn-next {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pagination .btn-prev {
  background-color: rgba(100, 10, 100, 0.1);
}

</style>
