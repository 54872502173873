import request from "@/libs/apiRequest";

export function getCityOptionsApi(params) {
    return request({
        url: "/citys",
        method: "get",
        params: params,
    });
}


export function createUser(data) {
    return request({
        url: "/users",
        method: "post",
        data: data,
    });
}


export function getShopListApi(params) {
    return request({
        url: "/shop_list",
        method: "get",
        params: params,
    });
}


export function getCurrentShopInfoApi(params) {
    return request({
        url: "/shop_info",
        method: "get",
        params: params,
    });
}


export function getCurrentShopSimpleInfoApi(params) {
    return request({
        url: "/shop_simple_info",
        method: "get",
        params: params,
    });
}


export function getOrderInfoApi(params) {
    return request({
        url: "/order_info",
        method: "get",
        params: params,
    });
}


export function queryTradeStatusApi(params) {
    return request({
        url: "/trade_query",
        method: "get",
        params: params,
    });
}


export function sendVCodeApi(data) {
    return request({
        url: "/v_code",
        method: "post",
        data: data,
    });
}


export function changePwd(data) {
    return request({
        url: "/change_pwd",
        method: "post",
        data: data,
    });
}


export function uploadFileApi(data) {
    return request({
        url: "/oss_upload",
        method: "post",
        data: data,
    });
}



