<template>
  <div :id="id"></div>
</template>

<script>
import G2 from "@antv/g2";
import Theme from "@/plugins/g2-theme";
import '@antv/g2-plugin-slider';
import DataSet from "@antv/data-set";

const colorMap = Theme.colors_24;
export default {
  name: "BaseChart",
  components: {},
  props: {
    // 数据
    baseData: {
      type: Array,
      default: () => {
        return [
          {name: "2020-01", value: 20},
          {name: "2020-02", value: 166},
          {name: "2020-03", value: 124},
          {name: "2020-04", value: 174},
          {name: "2020-05", value: 714},
          {name: "2020-06", value: 258},
          {name: "2020-07", value: 455}
        ]
      }
    },
    // 图表类型
    chartType: {
      type: Array,
      default: () => ['line']
    },
    // 平滑显示
    smoothShow: {
      type: Boolean,
      default: false
    },

    // 图例显示
    legendShow: {
      type: Boolean,
      default: false
    },

    // DOM 高度
    height: {
      type: Number,
      default: 300,
    },
    // DOM 高度
    padding: {
      type: Array,
      default: () => {
        return ['auto', 'auto']
      },
    },
    // 坐标轴
    axisMap: {
      type: Object,
      default: () => {
        return {
          name: "name",
          value: "value",
        };
      },
    },
    axisName: {
      type: Object,
      default: () => {
        return {
          name: "name",
          value: "value",
        };
      },
    },
    minData: {
      type: Object,
      default: () => {
        return {};
      },
    },
    axisX: {
      type: Object,
      default: () => {
        return {
          title: true,
          label: {
            offset: 10,
            textStyle: {
              textAlign: 'center', // 文本对齐方向，可取值为： start middle end
              fill: '#a0a0a0', // 文本的颜色
              fontSize: '12', // 文本大小
              textBaseline: 'top' // 文本基准线，可取 top middle bottom，默认为middle
            }
          }
        };
      },
    },
    axisY: {
      type: Object,
      default: () => {
        return {
          title: true,
          label: {
            offset: 10,
            textStyle: {
              // textAlign: 'center', // 文本对齐方向，可取值为： start middle end
              fill: '#a0a0a0', // 文本的颜色
              fontSize: '12', // 文本大小
              textBaseline: 'top' // 文本基准线，可取 top middle bottom，默认为middle
            }
          },
          grid: null
        };
      },
    },
    axisXLine: {
      type: Object,
      default: () => {
        return {
          lineWidth: 1, // 设置线的宽度
          stroke: 'gray', // 设置线的颜色
        };
      },
    },
    axisYLine: {
      type: Object,
      default: () => {
        return {
          lineWidth: 1, // 设置线的宽度
          stroke: 'gray', // 设置线的颜色
        };
      },
    },
    axisXScale: {
      type: Object,
      default: () => {
        return {
          // tickCount: 1,
          type: 'timeCat', // 度量的类型:linear、log、pow、time，非连续：cat、timeCat
          // nice: {boolean}, // 默认为 true，用于优化数值范围，使绘制的坐标轴刻度线均匀分布。例如原始数据的范围为 [3, 97]，如果 nice 为 true，那么就会将数值范围调整为 [0, 100]
          // min: 0, // 定义数值范围的最小值
          // max: {number}, // 定义数值范围的最大值
          // minLimit: {number}, // 对数据的最小值的限制，无论数据中是否存在比这个值小的数据，生成的坐标点不会小于这个值
          // maxLimit: {number}, // 对数据的最大值的限制，无论数据中是否存在比这个值大的数据，生成的坐标点不会大于这个值

          // range: [], // 数值范围区间，即度量转换的范围，默认为 [0, 1]
          // alias: this.axisName['name'], // 为数据属性定义别名，用于图例、坐标轴、tooltip 的个性化显示
          // ticks: [], // 存储坐标轴上的刻度点文本信息
          // tickCount: 5, // 坐标轴上刻度点的个数，不同的度量类型对应不同的默认值
          // formatter: {function}, // 回调函数，用于格式化坐标轴刻度点的文本显示，会影响数据在坐标轴、图例、tooltip 上的显示
        };
      },
    },
    axisYScale: {
      type: Object,
      default: () => {
        return {
          type: 'linear', // 度量的类型:linear、log、pow、time，非连续：cat、timeCat
          // range: [], // 数值范围区间，即度量转换的范围，默认为 [0, 1]
          // alias: this.axisName['value'], // 为数据属性定义别名，用于图例、坐标轴、tooltip 的个性化显示
          // ticks: [], // 存储坐标轴上的刻度点文本信息
          // tickCount: 5, // 坐标轴上刻度点的个数，不同的度量类型对应不同的默认值
          // formatter: {function}, // 回调函数，用于格式化坐标轴刻度点的文本显示，会影响数据在坐标轴、图例、tooltip 上的显示
        };
      },
    },


    // 图表颜色
    color: {
      type: String,
      default: "",
    },
    // value 数据是否是百分数（整数和百分数）
    isPercent: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: true,
    },
    labelX: {
      type: Object,
      default: () => {
        return {
          textStyle: {
            textAlign: 'center', // 文本对齐方向，可取值为： start middle end
            fill: '#404040', // 文本的颜色
            fontSize: '12', // 文本大小
            fontWeight: 'bold', // 文本粗细
            textBaseline: 'top' // 文本基准线，可取 top middle bottom，默认为middle
          }, // 支持回调
          formatter: (text, item, index) => {

            return text;
          }, // 回调函数，用于格式化坐标轴上显示的文本信息
        }
      },
    },
    // 使用辅助元素,需传入辅助guideCfg方法
    useGuide: {
      type: Boolean,
      default: false,
    },
    useTooltip: {
      type: Boolean,
      default: true,
    },
    tooltipCfg: {
      type: Object,
      default: () => {
      },
    },
    customShape: {
      type: String,
      default: 'e',
    },
    customRegisterShape: {
      type: Object,
      default: () => {
      },
    },
    // {
    //   name: 'interval:click',
    //   event: 'Click'
    // }
    // 存在clickEvent，则必须绑定对应的事件
    clickEvent: {
      type: Object,
      default: () => {
      },
    },
    useSlider: {
      type: Boolean,
      default: false,
    }

  },
  data() {
    return {
      id: this.genID(),
      chart: null,
    };
  },
  computed: {
    G2: function () {
      if (typeof window !== "undefined" && window.G2) {
        return window.G2;
      } else {
        return G2;
      }
    },
  },
  watch: {
    // 监控data，当发生变化时，重新绘制图表
    baseData(val, oldVal) {
      this.drawChart();
    },
  },
  created() {
    const { v4: uuidv4 } = require('uuid');
    this.id = uuidv4();
  },
  mounted() {
    this.drawChart();
  },
  methods: {
    // guide辅助元素示例，详见文档
    // guideCfg(chart) {
    //   const colors = G2.Global.colors;
    //   chart.guide().text({
    //     position: ['2021-08-01', 67],
    //     content: '越完美的钻石切割工艺越集中',
    //     style: {
    //       fill: colors[1],
    //       textAlign: 'center',
    //       fontSize: 14
    //     }
    //   });
    //   chart.guide().text({
    //     position: ['2021-09-01', 63],
    //     content: '越差的钻石切割工艺越分散',
    //     style: {
    //       fill: colors[4],
    //       textAlign: 'center',
    //       fontSize: 14
    //     }
    //   });
    // },
    randomColor() {
      return colorMap[Math.floor(Math.random() * 20 + 1)]
    },
    registerShape() {
      this.G2.Shape.registerShape('interval', 'triangle', {
        getPoints(cfg) {
          const x = cfg.x;
          const y = cfg.y;
          const y0 = cfg.y0;
          const width = cfg.size;
          return [
            {x: x - width / 6, y: y0},
            {x: x, y: y},
            {x: x + width / 6, y: y0}
          ]
        },
        draw(cfg, group) { // 自定义最终绘制
          const points = this.parsePoints(cfg.points); // 将0-1空间的坐标转换为画布坐标
          return group.addShape('polygon', {
            attrs: {
              points: [
                [points[0].x, points[0].y],
                [points[1].x, points[1].y],
                [points[2].x, points[2].y]
              ],
              fill: cfg.color
            }
          }); // !必须返回 shape
        }
      });
      if (this.customRegisterShape) {
        this.G2.Shape.registerShape(
            this.customRegisterShape['chartType'],
            this.customRegisterShape['shapeName'],
            this.customRegisterShape['config'])
      }
    },
    genID(length) {
      return Number(Math.random().toString().substr(3, length) + Date.now()).toString(36);
    },
    parseDate(_date){
      return new Date(_date.split("-")[0], parseInt(_date.split("-")[1])-1);
    },
    drawChart() {

      // 销毁实例
      if (this.chart) {
        this.chart.destroy();
      }

      this.registerShape()

      // 文档：https://g2-v3.antv.vision/zh/docs/manual/tutorial/chart-composition
      // 新建实例
      this.chart = new this.G2.Chart({
        container: this.id,
        forceFit: true,
        height: this.height,
        padding: this.padding,
      });

      // 图例
      this.chart.legend(this.legendShow);

      // // 坐标轴
      let x_axis = this.axisMap['name']
      let y_axis = this.axisMap['value']

      this.axisX['line'] = this.axisXLine
      this.axisY['line'] = this.axisYLine


      this.chart.axis(x_axis, this.axisX);

      this.chart.axis(y_axis, this.axisY);

      // 坐标轴别名等配置
      this.axisXScale['alias'] = this.axisName['name']
      this.axisYScale['alias'] = this.axisName['value']

      // tooltip 提示信息
      // let cfg = {
      //   triggerOn: 'mousemove', // tooltip 的触发方式，默认为 mousemove
      //   showTitle: true, // 是否展示 title，默认为 true
      //   crosshairs: {}, // tooltip 辅助线配置
      //   offset: 10, // tooltip 距离鼠标的偏移量
      //   containerTpl: '', // tooltip 每项记录的默认模板
      //   inPlot: true, // 将 tooltip 展示在指定区域内
      //   follow: true, // tooltip 是否跟随鼠标移动
      //   shared: false, // 默认为 true, false 表示只展示单条 tooltip
      //   position: 'right'  // 固定位置展示 tooltip
      // }
      this.chart.tooltip(this.useTooltip, this.tooltipCfg); // 开启 tooltip，并设置 tooltip 配置信息

      // 为 chart 装载数据
      let data_start = null
      let data_end = null
      if (this.baseData.length > 0) {
        const data_0 = this.baseData[0][x_axis]
        data_start = this.parseDate(data_0);
        data_end = this.parseDate(data_0);
        this.baseData.forEach(item => {
          let cur_date = this.parseDate(item[x_axis])
          if (cur_date < data_start) {
            data_start = cur_date
          }
          if (cur_date > data_end) {
            data_end = cur_date
          }
        })
      }
      const ds = new DataSet({
        state: {
          start: data_start,
          end: data_end
        }
      });
      const dv = ds.createView('origin').source(this.baseData);
      let _this = this
      dv.transform({
        type: 'filter',
        callback(obj) {
          const time = _this.parseDate(obj[x_axis]); // !注意：时间格式，建议转换为时间戳进行比较
          return time >= ds.state.start && time <= ds.state.end;
        }
      });

      const scale = {
        x_axis: this.axisXScale,
        y_axis: this.axisYScale,
      };
      if (this.useSlider) {
        this.chart.source(dv, scale);
      } else {
        // 坐标轴配置

        this.chart.scale(x_axis, this.axisXScale);
        this.chart.scale(y_axis, this.axisYScale);
        this.chart.source(this.baseData);
      }

      // 使用辅助元素
      if (this.useGuide) {
        this.$emit('guideCfg', this.chart);
      }

      // 图表类型
      let _color = y_axis
      if (this.color) {
        _color = this.color
      }

      this.chartType.forEach(item => {
        let _chart = this.chart
        if (item === 'line') {
          _chart = _chart.line().position(x_axis + '*' + y_axis).color(_color)
          if (this.smoothShow) {
            _chart.shape('smooth');
          }
        }

        if (item === 'interval') {
          _chart = _chart.interval().position(x_axis + '*' + y_axis).color(_color);
        }
        if (item === 'area') {
          _chart = _chart.area().position(x_axis + '*' + y_axis).color(_color);
        }
        if (item === 'bar') {
          _chart.coord().transpose();
          _chart = _chart.interval().position(x_axis + '*' + y_axis).color(_color);

        }
        if (this.showLabel) {
          _chart.label(this.axisMap['value'], this.labelX);
        }
        if (this.customShape) {
          _chart.shape(this.customShape);
        }

      })

      // 生成
      this.chart.render();

      if (this.useSlider && dv.rows.length > 0) {
        const sliderDiv = document.createElement('div');
        let sliderId = this.genID();
        sliderDiv.id = sliderId;
        const container = document.getElementById(this.id);
        container.parentNode.appendChild(sliderDiv);

        this.chart.interact('slider', {
          container: sliderId,
          padding: [10, 100, 20, 100],
          data: this.baseData,
          start: ds.state.start, // 和状态量对应
          end: ds.state.end,
          scales: scale,
          xAxis: x_axis,
          yAxis: y_axis,
          backgroundChart: {
            type: 'line',
            color: 'grey'
          },
          textStyle: {
            fill: '#ccc'
          }, // 文本样式
          onChange(ev) {
            const {startValue, endValue} = ev;
            ds.setState('start', _this.parseDate(startValue));
            ds.setState('end', _this.parseDate(endValue));
          }
        });
      }

      // 事件
      if (this.clickEvent) {
        this.chart.on(this.clickEvent['name'], (ev) => {
          // interval:click
          // const selecttype = ev.data._origin.type
          // const selectName = ev.data._origin.name
          // const select_id = ev.data._origin.id
          // const select_value = ev.data._origin.value

          this.$emit(this.clickEvent['event'], ev.data);
        });
      }
    }
  },
}
</script>


<style lang="scss" scoped>

</style>
