<template>
  <div class="main-ActiveGet">
<!--    <div class="btn">-->
<!--      <el-button class="new-btn" size="mini" type="primary" @click="toPage('active_new')">新增</el-button>-->
<!--    </div>-->

    <activeTableBase :activeType="activeType"></activeTableBase>

  </div>
</template>


<script>


import {mapGetters, mapState} from "vuex";
import activeTableBase from "@/views/xingtuan/pages/active/activeTableBase";

export default {
  name: "ActiveKoulin",
  components: {
    activeTableBase
  },
  data() {
    return {
      activeType: 'koulin'

    }
  },
  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
    ...mapState("companys", ["companyInfo"]),
  },
  created() {

  },
  mounted() {


  },
  methods: {
    toPage(val) {
      this.$emit('toPage', val)
    },


  },
}
</script>


<style lang="scss" scoped>
.btn {
  padding: 10px 10px;
  width: 100%;
  text-align: left;


}


.list-box {
  width: 100%;
  padding: 10px 10px;

  .info-table {
    //max-width: 800px;
  }
}


</style>
