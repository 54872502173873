<template>
  <div class="main-NewCustomerWelcome">

    <div class="group-msg table-box">
      <div>客户欢迎语创建</div>

      <el-form :model="ruleForm"
               :rules="rules"
               size="mini"
               ref="ruleForm" label-width="100px"
               class="demo-ruleForm">


        <el-form-item label="欢迎语名称" prop="name">
          <el-input style="width:200px;" v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="默认欢迎语" prop="active">

          <el-switch
              v-model="ruleForm.active"
              active-color="#13ce66"
              inactive-color="gray">
          </el-switch>

        </el-form-item>
        <el-form-item label="欢迎语内容" prop="welcome_text">
          <el-input v-model="ruleForm.welcome_text"></el-input>
        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>


    </div>

  </div>
</template>


<script>

import {mapGetters} from "vuex";
import {postCustomerWelcomeApi} from "@/services/weworkApi";

export default {
  name: "NewCustomerWelcome",
  components: {},
  data() {
    return {
      uploading: false,
      rules: {},
      ruleForm: {
        name: '',
        active: true,
        welcome_text: '',
      },


    }
  },
  computed: {
    ...mapGetters(['userInfo']),

  },
  created() {

  },
  mounted() {

  },
  methods: {

    submitForm(formName) {

      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.ruleForm['corpid'] = this.userInfo.wework_corpid

          console.log(this.ruleForm)
          postCustomerWelcomeApi(this.ruleForm).then(res => {

            this.$message.success('创建成功')
            this.backIndex()

          }).catch(err => {
            this.$message.error('创建失败，请检查输入')
          })


        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    backIndex() {
      this.$emit('backIndex')
    },
  },
}
</script>


<style lang="scss" scoped>

.main-NewCustomerWelcome {
  padding: 10px 10px;

  .group-msg {
    text-align: left;
    padding: 10px 10px;

    max-width: 900px;
  }


  .img-box {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    padding: 2px 2px;
    text-align: center;

    .img-cell {
      width: 100%;
      height: 100%;


    }

    .img-btn {
      position: absolute;
      left: calc(100% - 18px);
      top: calc(100% - 28px);

    }
  }

}

</style>
