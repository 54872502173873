<template>
  <div class="main-CustomerWifi">
    <div class="active-box text-left">
      <el-button type="primary" size="mini" @click="showPage='add'" v-if="showPage==='table'">新增</el-button>
      <el-button type="warning" size="mini" @click="showPage='table'" v-if="showPage==='add'">返回</el-button>
      <el-button type="warning" size="mini" @click="downloadShow=true">导出WiFi码</el-button>
    </div>


        <div class=" add-box text-left box-shadow1" v-if="showPage==='add'">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
               size="mini" label-width="100px" class="demo-ruleForm">
        <el-form-item label="WiFi别名" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>

        <el-form-item label="封面图" prop="back_img">
          <el-upload
              v-if="ruleForm.back_img===''"
              class="upload-demo"
              :action="file_url"
              :data="{prefix: 'customer_img'}"
              :limit="1"
              :on-success="backSuccess"
              :on-progress="onProgress"
              :on-error="errFunc"

              :accept="accept_type.join(',')"

              list-type="picture">
            <el-button size="mini" :loading="uploading" type="primary">点击上传</el-button>
          </el-upload>
          <div class="img-box"
               v-if="ruleForm.back_img"
          >
            <el-image
                class="img-cell"
                :src="'https://img.xingtuangroup.com/'+ruleForm.back_img"
                fit="contain">
            </el-image>

            <el-button
                type="text"
                size="mini"
                class="img-btn"
                icon="el-icon-delete"
                @click="removeBackImage"
            ></el-button>
          </div>
        </el-form-item>
        <el-form-item label="门店" prop="shop_id">
          <el-select v-model="ruleForm.shop_id" placeholder="请选择门店">
            <el-option v-for="(item,index) in shopOptions"
                       :key="index"
                       :label="item.shop_name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="引流成员" prop="corp_user_id">
          <el-button size="mini" type="primary" @click="newShowManagerShow=true">选择引流成员</el-button>
        </el-form-item>

        <el-form-item label="WiFi名称 （SSID）" prop="wifi_name"  >
          <el-input v-model="ruleForm.wifi_name"></el-input>
        </el-form-item>
        <el-form-item label="WiFi密码" prop="wifi_pwd"  >
          <el-input v-model="ruleForm.wifi_pwd"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
          <el-button @click="resetForm('ruleForm')">重置</el-button>
        </el-form-item>
      </el-form>

    </div>


    <div v-if="showPage==='table'">
      <el-table

          :data="showTableData"
          style="width: 100%">
        <el-table-column
            prop="shop_info.shop_name"
            label="门店"
            width="180">
        </el-table-column>
        <el-table-column
            prop="name"
            label="wifi别名"
            width="180">
        </el-table-column>
        <el-table-column
            prop="corp_user_id"
            label="引流成员"
        >
          <template slot-scope="scope">
            <WeworkUserShow type="userName" :openid="scope.row.corp_user_id"></WeworkUserShow>

          </template>

        </el-table-column>

        <el-table-column
            prop="user_num"
            label=引流好友数
        >
        </el-table-column>
        <el-table-column
            prop="del_num"
            label=流失数
        >
        </el-table-column>

        <el-table-column
            prop=""
            label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="readClick(scope.row)">查看</el-button>
            <el-button type="primary" size="mini" @click="editClick(scope.row)">编辑</el-button>

            <el-popconfirm
                title="确定删除吗？"
                style="padding-left: 10px"
                @confirm="delClick(scope.row)"
            >

              <el-button slot="reference" type="primary" size="mini">删除</el-button>

            </el-popconfirm>
          </template>

        </el-table-column>
      </el-table>

      <el-pagination
          style="padding-top: 10px;"

          @current-change="handleCurrentChange"
          :current-page.sync="currentPage"
          :page-size="pageSize"
          layout="prev, pager, next"
          :total="tableData.length">
      </el-pagination>
    </div>

    <div v-if="showPage==='detail'">
      <div style="text-align: left;">

        <el-button type="primary" @click="showPage='table'" size="mini">返回</el-button>

      </div>


      <el-form label-position="left" inline class="demo-table-expand table-box">
        <el-form-item label="WiFi别名">
          <span>{{ currentTable.name }}</span>
        </el-form-item>
        <el-form-item label="封面图">
          <div class="img-box"
               v-if="currentTable.back_img"
          >
            <el-image
                class="img-cell"
                :src="'https://img.xingtuangroup.com/'+currentTable.back_img"
                fit="contain">
            </el-image>
          </div>
          <div v-else>无封面图</div>
        </el-form-item>
        <el-form-item label="门店">
          <el-select v-model="currentTable.shop_id" placeholder="">

            <el-option v-for="(item,index) in shopOptions"
                       :key="index"
                       :label="item.shop_name" :value="item.id"></el-option>

          </el-select>
        </el-form-item>
        <el-form-item label="引流成员">
          <WeworkUserShow type="userName" :openid="currentTable.corp_user_id"></WeworkUserShow>

        </el-form-item>

        <el-form-item label="引流好友数">
          <span>{{ currentTable.user_num }}</span>
        </el-form-item>
        <el-form-item label="流失数">
          <span>{{ currentTable.del_num }}</span>
        </el-form-item>
        <el-form-item label="WiFi名称（SSID）">
          <span>{{ currentTable.wifi_name }}</span>
        </el-form-item>
        <el-form-item label="WiFi密码">
          <span>{{ currentTable.wifi_pwd }}</span>
        </el-form-item>

        <el-form-item label="系统WiFi码">
          <qrCode :value="createTableCode(currentTable.id)"></qrCode>
        </el-form-item>


      </el-form>
    </div>


    <el-dialog
        title="选择引流成员"
        :visible.sync="newShowManagerShow"
        append-to-body>
      <MemberTree :single="true" v-model="ruleForm.corp_user_id"></MemberTree>

      <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="mini" @click="newShowManagerShow = false">完 成</el-button>
        </span>
    </el-dialog>

    <el-dialog
        title="导出WiFi码"
        :visible.sync="downloadShow"
        append-to-body>

      <div class="cover-list">

        <div>请选择模板：</div>
        <el-radio-group v-model="wifiModelId">


          <div class="cover-box">
            <el-image
                class="img-cell"
                src="/qr/wifi1.png"
                fit="contain">
            </el-image>

            <el-radio :label="1"></el-radio>
          </div>


          <div class="cover-box">
            <el-image
                class="img-cell"
                src="/qr/wifi2.png"
                fit="contain">
            </el-image>

            <el-radio :label="2"></el-radio>
          </div>
          <div class="cover-box">
            <el-image
                class="img-cell"
                src="/qr/wifi3.png"
                fit="contain">
            </el-image>

            <el-radio :label="3"></el-radio>
          </div>
          <div class="cover-box">
            <el-radio :label="0">不使用模板，只下载二维码</el-radio>
          </div>

          <div style="text-align: center">
            <el-button type="warning" size="mini" @click="downloadQrCode">立 即 下 载</el-button>

          </div>
        </el-radio-group>


      </div>


      <span slot="footer" class="dialog-footer">
          <el-button type="primary" size="mini" @click="downloadShow = false">关 闭</el-button>
        </span>
    </el-dialog>


  </div>
</template>


<script>

import WeworkUserShow from "@/views/xingtuan/components/WeworkUserShow";
import {
  delCustomerQrCodeApi, delCustomerWifiApi,
  getCustomerWifiApi,
  postCustomerWifiApi
} from "@/services/customer";
import {getWeworkShop} from "@/services/weworkApi";
import {mapGetters} from "vuex";
import MemberTree from "@/views/xingtuan/components/MemberTree";
import qrCode from "@/views/xingtuan/components/qrCode";

export default {
  name: "CustomerWifi",
  components: {
    MemberTree,
    WeworkUserShow,
    qrCode
  },
  data() {
    return {
      uploading: false,
      newShowManagerShow: false,
      downloadShow: false,
      wifiModelId: 0,
      tableData: [],
      showPage: 'table',
      currentTable: {},
      shopOptions: [],
      accept_type: ['.jpg', '.png', '.bmp', '.gif', '.jpeg'],
      currentPage: 1,
      pageSize: 10,
      ruleForm: {
        name: '',
        back_img: '',
        shop_id: '',
        corp_user_id: '',
        wifi_name: '',
        wifi_pwd: '',

      },
      rules: {
        name: [
          {required: true, message: '请输入wifi别名', trigger: 'blur'},
          {min: 1, max: 50, message: '长度在 1 到 50 个字符', trigger: 'blur'}
        ],
        shop_id: [
          {required: true, message: '请选择门店', trigger: 'change'}
        ],
        corp_user_id: [
          {required: true, message: '请选择引流成员', trigger: 'change'}
        ],

        wifi_name: [
          {required: true, message: '请输入wifi名称（ssid）', trigger: 'change'}
        ],

        wifi_pwd: [
          {required: true, message: '请输入wifi密码', trigger: 'change'}
        ],


      },
    }
  },
  computed: {
     ...mapGetters(['userInfo']),
    file_url() {
      return process.env.VUE_APP_API_HOST + '/api/v1/oss_upload'
    },
    showTableData() {
      return this.tableData.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize)


    }
  },
  created() {

  },
  mounted() {
    this.getShopOptionsData()
    this.getData()

  },
  methods: {
    downloadQrCode() {
      window.location.href = process.env.VUE_APP_API_HOST + '/api/v1/xingtuan/download_qr_code?type=wifi&id='
          + this.wifiModelId + '&corpid=' + this.userInfo.wework_corpid
    },
    createTableCode(val) {
      return 'https://www.xingtuangroup.com/client/wifi?wifi_id=' + val
    },
    removeBackImage() {
      this.ruleForm.back_img = ''
    },
    errFunc() {
      this.$message.error('上传错误');
      this.uploading = false
    },
    onProgress() {
      this.uploading = true
    },
    backSuccess(res, file) {
      this.ruleForm.back_img = res.data.key
      this.uploading = false
    },
    handleCurrentChange(val) {
      this.currentPage = val
    },
    readClick(row) {
      this.showPage = 'detail'
      this.currentTable = row
    },
    editClick(row) {
      this.showPage = 'add'
      this.ruleForm = row
    },
    delClick(row) {
      delCustomerWifiApi(this.ruleForm).then(res => {
        this.$message.success('删除成功')
        this.showPage = 'table'
      }).catch(err => {
        this.$message.success('删除失败,请检查相关信息状态或重试')
      })

    },
    getShopOptionsData() {
      getWeworkShop().then(res => {
        this.shopOptions = res.data.data
      })

    },
    getData() {
      getCustomerWifiApi().then(res => {
        this.tableData = res.data.data
      })
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          postCustomerWifiApi(this.ruleForm).then(res => {
            this.$message.success('创建成功')
            this.showPage = 'table'
          }).catch(err => {
            this.$message.success('创建失败,请检查相关信息状态或重试')
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
}
</script>


<style lang="scss" scoped>
.main-CustomerWifi {
  padding: 10px 10px;

  .active-box {

    padding: 10px 10px;
  }

  .add-box {
    width: 96%;
    max-width: 600px;
    margin: 2% 2%;
    padding: 10px 10px;
    border: 1px solid rgba(100, 10, 120, 0.8);
  }


  .img-box {
    position: relative;
    display: inline-block;
    width: 120px;
    height: 120px;
    padding: 2px 2px;
    text-align: center;

    .img-cell {
      width: 100%;
      height: 100%;


    }

    .img-btn {
      position: absolute;
      left: calc(100% - 18px);
      top: calc(100% - 28px);

    }
  }

}

.cover-box {
  position: relative;
  display: inline-block;
  width: 220px;
  height: 220px;
  padding: 2px 2px;
  text-align: center;

  .img-cell {
    width: 100%;
    height: 100%;
  }


}
/deep/ .dialog-box .el-dialog {
  background-color: rgba(20, 50, 80, 0.9);
}

/deep/ .el-dialog {
  background-color: rgba(20, 50, 80, 0.9) !important;
}


/deep/ .el-pagination button:disabled {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pager li {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pagination .btn-next {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .el-pagination .btn-prev {
  background-color: rgba(100, 10, 100, 0.1);
}

/deep/ .demo-table-expand {
  font-size: 0;
  text-align: left;
}

/deep/ .demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}

/deep/ .demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
