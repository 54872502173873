// import {getCompanyUserApi, getWeworkCompanyInfo,} from "@/services/weworkApi";


import {getOpenInfo} from "@/services/wxApi";
import {getOpenDataConfigApi, getWxPayConfigApi} from "@/services/weworkApi";
import permission from "@/store/modules/permission";

const wxClient =  {
    namespaced: true,
    state: {
        baseConfig: null,
        agentConfig: null,
        wxUserInfo: null,
        userLoading: false,


    },
    getters: {
        baseConfig: state => state.baseConfig,
        agentConfig: state => state.agentConfig,


    },
    mutations: {

        updateUserLoading(state, data) {
            state.userLoading = data
        },
        setWxUserInfo(state, payload) {
            state.wxUserInfo = payload
        },
        setBaseConfig(state, payload) {
            state.baseConfig = payload
        },
        setAgentConfig(state, payload) {
            state.agentConfig = payload
        },


    },
    actions: {
        getWxUserInfo({commit, state}, query = {}) {
            commit("updateUserLoading", true)

            getOpenInfo(query).then(res => {
                commit("setWxUserInfo", res.data.data)
            }).finally(res => {
                commit("updateUserLoading", false)
            })
        },

         getWxBaseConfig({commit, state}, query = {}) {
            getOpenDataConfigApi(query).then(res => {
                commit("setBaseConfig", res.data)
            })
        },
        getWxAgentConfig({commit, state}, query = {}) {
            getOpenDataConfigApi(query).then(res => {
                commit("setAgentConfig", res.data)
            })
        },


    }
}

export default wxClient
