<template>
  <div>
    <div>U享付</div>

<!--    <router-link to="/Login">Login</router-link>-->
<!--    <router-link to="/Register">Register</router-link>-->
<!--    <button type="button" class="btn btn-primary">Primary</button>-->
<!--    <button type="button" class="btn btn-secondary">Secondary</button>-->
<!--    <button type="button" class="btn btn-success">Success</button>-->
<!--    <button type="button" class="btn btn-info">Info</button>-->
<!--    <button type="button" class="btn btn-warning">Warning</button>-->
<!--    <button type="button" class="btn btn-danger">Danger</button>-->
<!--    <button type="button" class="btn btn-link">Link</button>-->


<!--    <div>-->
<!--      <el-button>默认按钮</el-button>-->
<!--      <el-button type="primary">主要按钮</el-button>-->
<!--      <el-button type="success">成功按钮</el-button>-->
<!--      <el-button type="info">信息按钮</el-button>-->
<!--      <el-button type="warning">警告按钮</el-button>-->
<!--      <el-button type="danger">危险按钮</el-button>-->
<!--    </div>-->

    功能开发中
  </div>
</template>

<script>
export default {
  name: "Main"
}
</script>

<style scoped>

</style>
