<template>
  <!--      群-->
  <el-row class="main-cell table-box" v-loading="groupDataLoading"
          element-loading-background="rgba(0, 80, 80, 0.5)">
    <div class="cups">
      <div>
        <svg-icon class="icon" iconname="icon-qunliao"></svg-icon>
      </div>
      <div class="title">群总数</div>
      <div class="content">
        <ICountUp class="content total" :endVal="groupData.total" :options="options"/>
      </div>

    </div>
    <div class="cups">
      <div>
        <svg-icon class="icon" iconname="icon-qun"></svg-icon>
      </div>
      <div class="title">群总人数</div>
      <div class="content">
        <ICountUp class="content new" :endVal="groupData.total_people" :options="options"/>
      </div>

    </div>
    <div class="cups">
      <div>
        <svg-icon class="icon" iconname="icon-qunliao"></svg-icon>
      </div>
      <div class="title">新增数量</div>
      <div class="content">
        <ICountUp class="content bad" :endVal="groupData.new" :options="options"/>
      </div>

    </div>
    <div class="cups">
      <div>
        <svg-icon class="icon" iconname="icon-kehugonghaiicon"></svg-icon>
      </div>
      <div class="title">新增群成员</div>
      <div class="content">
        <ICountUp class="content bad" :endVal="groupData.new_people" :options="options"/>
      </div>

    </div>
  </el-row>
</template>


<script>

import ICountUp from "vue-countup-v2";
import {getGroupDataApi} from "@/services/xingtuan";

export default {
  name: "groupData",
  components: {
    ICountUp
  },
  data() {
    return {
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },

      groupDataLoading: false,
      groupData: {
        total: 1000,
        total_people: 1000,
        new: 1000,
        new_people: 1000,
      },

    }
  },
  computed: {},
  created() {

  },
  mounted() {
    this.getGroupData()
  },
  methods: {
    getGroupData() {
      this.groupDataLoading = true
      getGroupDataApi({days: 1,}).then(res => {
        this.groupData = res.data.data
      }).finally(e => {
        this.groupDataLoading = false
      })
    },

  },
}
</script>


<style lang="scss" scoped>



  .main-cell {



    display: inline-block;
    width: 100%;
    margin: 0;

    .title {
      font-weight: bold;
      color: #00bc8c;
    }


  }




.icon {
  width: 40px;
  height: 40px;
}

.cups {
  display: inline-block;
  width: 160px;
  padding: 10px 10px;

  .title {
    //height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
    color: #00EAFF;
  }

  .content {
    font-size: 24px;
  }

  .total {
    color: #67C23A;
  }

  .new {
    color: #00bc8c;
  }

  .bad {
    color: #9a6e3a;
  }


}


.detail {
  text-align: center;
  margin: 10px 10px;
  background-color: rgba(130, 0, 150, 0.2);
}


</style>
