import Vue from 'vue';
import Router from 'vue-router';
import store from '@/store';
//组件模块
import Main from '@/views/Main';
import Login from "@/views/Login";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import Guide from "@/views/Guide";

import QrLoginBack from "@/views/wework/QrLoginBack";
import XingIndex from "@/views/xingtuan/XingIndex";
import companyAuth from "@/views/wework/companyAuth";
import PayForAuth from "@/views/xingtuan/PayForAuth";
import clientActiveIndex from "@/views/xingtuan/clientPages/actives/clientActiveIndex";
import clientHome from "@/views/xingtuan/clientPages/clientHome";
import clientRoot from "@/views/xingtuan/clientPages/clientRoot";


Vue.use(Router)


// 无须权限路由
const constantRoutes = [
    {
        path: '/',
        name: 'home',
        components: {

            default: Login,
            footer: Footer
        },
    },


    {
        path: '/login', name: 'Login', components: {

            default: Login,
            footer: Footer
        },
    },
    {
        path: '/company_auth', name: 'companyAuth', components: {

            default: companyAuth,
            footer: Footer
        },
    },
    {
        path: '/qr_login_back', name: 'QrLoginBack', components: {

            default: QrLoginBack,
            footer: Footer
        },
    },

    // client
    {
        path: '/client/home', name: 'client_home', components: {

            default: clientRoot,
            footer: Footer
        },
    },
    {
        path: '/client/activity', name: 'client_activity', components: {
            default: clientRoot,
            footer: Footer
        },
    },
    {
        path: '/client/table', name: 'client_table', components: {
            default: clientRoot,
            footer: Footer
        },
    },

    {
        path: '/client/wifi', name: 'client_wifi', components: {
            default: clientRoot,
            footer: Footer
        },
    },


]

// 权限限制路由
const asyncRoutes = [
    {
        path: "/my_profile",
        name: "my profile",

        components: {
            header: Header,
            default: Main,
            footer: Footer
        },
        meta: {
            title: 'my_profile',
            permissions: ['CLERK'],
        }
    },
    {
        path: "/guide",
        name: "guide",

        components: {

            default: Guide,
            footer: Footer
        },
        meta: {
            title: 'guide',
            permissions: ['TRAVEL'],
        }
    },
    {
        path: "/Wework",
        name: "Wework index",

        components: {
            // header: Header,
            default: XingIndex,
            footer: Footer
        },
        meta: {
            title: 'Wework index',
            permissions: ['Wework'],
        }
    },
    {
        path: "/pay_for_auth",
        name: "pay_for_auth",

        components: {

            default: PayForAuth,
            footer: Footer
        },
        meta: {
            title: 'PayForAuth',
            permissions: ['Wework'],
        }
    },

]

Vue.use(Router);

const router = new Router({
    linkExactActiveClass: "active",
    mode: 'history',
    routes: constantRoutes,
    scrollBehavior: to => {
        if (to.hash) {
            return {selector: to.hash};
        } else {
            return {x: 0, y: 0};
        }
    }
});


const whiteList = constantRoutes.map(rr => {
    return rr.path
})

router.beforeEach(async (to, from, next) => {
    let {token} = store.getters;

    // special path

    if (token) {
        if (to.path === '/') {
            next('/index');
        } else {
            store.dispatch('user/getGlobalInfo').then(userInfo => {
                if (store.getters.addRoutes.length === 0) {
                    store.dispatch('permission/generateRoute', userInfo)
                        .then(() => {
                            router.addRoutes(store.getters.addRoutes)
                            next({...to, replace: true});
                        })
                        .catch(err => {
                            Vue.notify({type: 'error', text: err})
                        })
                } else {
                    next();
                    return;
                }
            });
        }
    } else {
        if (whiteList.indexOf(to.path) !== -1) {
            next();
        } else {
            localStorage.setItem('to_path', to.fullPath);
            next('/login');
            return;
        }
    }
    next();
})

export {router, constantRoutes, asyncRoutes}

