<template>
  <span>
    <ww-open-data v-if="openid" :type='type' :openid='openid' key="openid"></ww-open-data>
  </span>
</template>
<script>
import {mapActions, mapGetters, mapState} from "vuex";
import {getOpenDataConfigApi} from "@/services/weworkApi";
import Vue from 'vue'

export default {
  name: "WeworkUserShow",
  data() {
    return {

    }
  },

  created() {

  },
  mounted() {
    // this.bindData()

    this.weworkInit()
  },
  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
    ...mapState("companys", ["companyInfo"]),
    ...mapState("wxClient", ["baseConfig", "agentConfig"]),
  },
  methods: {
    ...mapActions('wxClient', ['getWxBaseConfig', 'getWxAgentConfig']),
    bindData() {
      // WWOpenData.bind(document.querySelector('ww-open-data'))

    },


    async weworkInit() {


      if (/MicroMessenger/i.test(navigator.userAgent)) {
        if (!this.$wxBaseConfig) {
          getOpenDataConfigApi({
            url: window.location.href,
            corpid: this.userInfo.wework_corpid,
            type: 'base'
          }).then(res => {
            Vue.prototype.$wxBaseConfig = res.data

            getOpenDataConfigApi({
              url: window.location.href,
              corpid: this.userInfo.wework_corpid,
            }).then(res => {

              Vue.prototype.$wxAgentConfig = res.data
              this.processConfig()

            })
          })
        } else {
          this.processConfig()
        }

      } else {
        if (!this.$wxAgentConfig) {
          getOpenDataConfigApi({
            url: window.location.href,
            corpid: this.userInfo.wework_corpid,
          }).then(res => {

            Vue.prototype.$wxAgentConfig = res.data
            this.processConfig()

          })
        } else {
          this.processConfig()
        }

      }

    },
    async processConfig() {
      if (/MicroMessenger/i.test(navigator.userAgent)) {
        await this.config
      }
      await this.agentDoConfig()
      WWOpenData.bindAll(document.querySelectorAll('ww-open-data'))

    },
    async config() {
      return new Promise((resolve, reject) => {
        wx.config(this.$wxBaseConfig)
        wx.ready(resolve)
        wx.error(reject)
      }).then(() => {
        console.info('wx.ready')
      }, error => {
        console.error('wx.error', error)
        throw error
      })
    },
    async agentDoConfig() {
      return new Promise((success, fail) => {
        // wx.agentConfig({ ...config, success, fail })

        wx.agentConfig({
          corpid: this.userInfo.wework_corpid, // 必填，企业微信的corpid，必须与当前登录的企业一致
          agentid: this.$wxAgentConfig.agentid, // 必填，企业微信的应用id （e.g. 1000247）
          timestamp: this.$wxAgentConfig.timestamp, // 必填，生成签名的时间戳
          nonceStr: this.$wxAgentConfig.nonceStr, // 必填，生成签名的随机串
          signature: this.$wxAgentConfig.signature,// 必填，签名，见附录-JS-SDK使用权限签名算法
          jsApiList: this.$wxAgentConfig.jsApiList, //必填，传入需要使用的接口名称
          success: function (res) {
            // 回调
            console.log("agent config ok")
            // 注意: 在企业微信平台下，只有 agentConfig 成功回调后，WWOpenData 才会注入到 window 对象上面
            // WWOpenData.bindAll(document.querySelectorAll('ww-open-data'))
            WWOpenData.bind(document.querySelector('ww-open-data'))
          },
          fail: function (res) {
            console.log(res)
            console.log("agent config error")
            if (res.errMsg.indexOf('function not exist') > -1) {
              alert('版本过低请升级')
            }
          }
        });


      })
    },
  },
  props: {
    //类型：分为部门和人员姓名
    type: {
      type: String,
      default: undefined
    },
    //id:人员或部门的ID
    openid: {
      type: String,
      default: undefined
    }
  }
}
</script>

