<template>
  <div id="header">
    <div class="logo"><img src="/img/logo.png" alt="logo" id="logo"
                           @click="toIndex"></div>
    <div class="logo">U享付</div>


    <el-dropdown class="profile" v-if="userInfo" @command="handleClick">

      <div class="head">
        {{ userInfo.username }}
      </div>

      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="b">{{ userInfo.username }}</el-dropdown-item>

        <el-dropdown-item command="logout">退出</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <div class="profile" v-if="!userInfo">
      <a href="/login">请登录</a>
    </div>

    <div class=" main-menu main-menu-big" v-if="userInfo && c_path!=='/guide'">
      <el-menu :router="true" class="el-menu-demo" mode="horizontal">
        <el-menu-item
            style="border: none;"
            v-for="item in menuList[userInfo.role]"
            :key="menuList[userInfo.role].indexOf(item)"
            :index="item.url"
        >{{ item.label }}
        </el-menu-item>
      </el-menu>
    </div>
    <div class=" main-menu main-menu-small" v-if="userInfo && c_path!=='/guide'">
      <el-menu :router="true" class="el-menu-demo" mode="horizontal">
        <el-submenu index="1">
          <template slot="title">菜单</template>

          <el-menu-item
              v-for="item in menuList[userInfo.role]"
              :key="menuList[userInfo.role].indexOf(item)"
              :index="item.url"
          >
            {{ item.label }}
          </el-menu-item>

        </el-submenu>
      </el-menu>
    </div>


  </div>

</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "Header",
  data() {
    return {

      menuList: {
        Admin: [
          {label: '主页', url: '/admin'},
          {label: '公告', url: '/admin/notice'},
          {label: '代理商管理', url: '/admin/agent'},
          {label: '商户管理', url: '/admin/shop'},
          {label: '员工管理', url: '/admin/clerk'},
          {label: '数据中心', url: '/admin/data'},
          {label: '账户管理', url: '/admin/account'},
          {label: '我的信息', url: '/admin/profile'},
        ],
        Agent: [
          {label: '主页', url: '/agent'},
          {label: '公告', url: '/agent/notice'},
          {label: '商户管理', url: '/agent/shop'},
          {label: '作业指导', url: '/agent/work'},
          {label: '账户管理', url: '/agent/account'},
          {label: '我的信息', url: '/agent/profile'},
        ],
        Shop: [
          {label: '主页', url: '/shop'},
          {label: '数据中心', url: '/shop/data'},
          {label: '产品中心', url: '/shop/product'},
          {label: '我的信息', url: '/shop/profile'},
        ],
      }
    }
  },
  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
    c_path(){
      return this.$route.path
    }

  },
  mounted() {
    console.log(this.userInfo)
  },
  methods: {
    ...mapActions('user', ['logout']),

    handleClick(command) {
      if (command === 'logout') {
        this.logout();
      }

    },
    toIndex() {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="scss">
#header {
  background-color: rgb(245, 245, 245);
  border-bottom: 1px solid rgba(190, 190, 190, 0.2);
  margin: 0 auto;
  height: 60px;
  width: 100%;


  #logo {
    cursor: pointer;
    width: 40px;
    height: 40px;
    line-height: 60px;
  }

  .main-menu {
    display: inline-block;


    float: right;
    height: 60px;
    line-height: 60px;

    .el-menu-demo {
      background-color: #F5F5F5;
      border: none;
    }

    .menu-cell {
      cursor: pointer;

      color: #666;
      font-weight: bold;
      padding: 0 14px;
      border-left: 1px solid rgba(20, 20, 20, 0.2);


      &:hover {
        text-decoration: none;
        color: rgb(100, 50, 120);
      }
    }

    .menu-first {
      border-left: none;
    }


  }

  .logo {
    display: inline-block;
    float: left;
    height: 100%;
    line-height: 60px;
    text-align: center;
    width: 60px;
  }

  .wel {
    float: right;
    width: 160px;
    line-height: 60px;
    display: inline-block;
    height: 100%;
    text-align: right;
  }

  .profile {
    cursor: pointer;
    display: inline-block;
    float: right;
    height: 100%;
    line-height: 60px;
    text-align: center;
    //width: 60px;
    padding: 0 10px;


    .head {
      display: inline-block;
      //width: 60px;
      height: 100%;
      color: #444;

      .icon {
        width: 40px;
        height: 40px;
        padding-top: 10px;
      }
    }
  }

}

/* 手机等小屏幕手持设备 */
@media screen and (min-width: 30px) and (max-width: 800px) {
  .main-menu-small {
    display: block !important;
  }
  .main-menu-big {
    display: none !important;
  }

}

/* 手机等小屏幕手持设备 */
@media screen and (min-width: 800px) {
  .main-menu-small {
    display: none !important;
  }
  .main-menu-big {
    display: block !important;
  }

}

</style>
