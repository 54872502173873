<template>
  <div class="main-Guide">


    <div>尊敬的 <span class="role">{{ roleName }}</span> ,{{ companyMsg }}</div>


    <div class="btn-box">
      <el-button
          class="btn"
          type="primary"
          v-if="userInfo.wework_role===4"

          @click="toAuthPage"
      >立即授权
      </el-button>
    </div>

    <div class="btn-box">
      <el-button
          class="btn"
          type="primary"
          v-if="companyMsg === '当前企业授权已过期，请自助或联系您的服务人员续期。'"

          @click="toContinueAuth"
      >立即续期
      </el-button>
    </div>


  </div>
</template>


<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import {getWeworkCompanyInfo} from "@/services/weworkApi";

export default {
  name: "Guide",
  components: {},
  data() {
    return {
      roleName: null,
      menuList: [

        {title: '总裁办', to: 'Wework', role: 0},
        {title: '超级管理员', to: 'Wework', role: 1},
        {title: '管理员', to: 'Wework', role: 2},
        {title: '员工', to: 'Wework', role: 3},
      ],
      weworkMenu: {
        0: '总裁办',
        1: '超级管理员',
        2: '管理员',
        3: '员工',
        4: '未授权人员',
      },
      companyMsg: '正在检查权限...'

    }
  },
  computed: {
    ...mapGetters(['token', 'userInfo', 'isSupervisor']),
    ...mapGetters('user', ['hasAdminPermission']),
    ...mapState("companys", ["companyInfo"]),
  },
  created() {
    this.getCompanyInfo({corpid: this.userInfo.wework_corpid})
  },
  mounted() {
    console.log(this.userInfo)
    this.roleName = this.weworkMenu[this.userInfo.wework_role]
    if (!this.companyInfo) {
      getWeworkCompanyInfo({corpid: this.userInfo.wework_corpid}).then(res => {
        this.checkAuth()
      })
    } else {
      this.checkAuth()
    }

  },
  methods: {
    ...mapActions("companys", ["getCompanyInfo"]),
    toContinueAuth(){
      this.$router.push('/pay_for_auth')
    },
    toAuthPage() {
      this.$router.push('/company_auth')
    },
    checkAuth() {
      // 检查登陆者角色和公司状态赋予权限

      if (new Date(this.companyInfo.end_date) < new Date()) {
        this.companyMsg = '当前企业授权已过期，请自助或联系您的服务人员续期。'

      } else {
        if (this.userInfo.wework_role == 4) {
          this.companyMsg = '您的账号未获取企业超级管理员授权，请联系您所在企业超级管理员进行授权。'
        } else {
          this.companyMsg = '正在带您进入平台首页...'
          setTimeout(() => {
            this.$router.push('/Wework')
          }, 2000)
        }


      }


    }

  },
}
</script>


<style lang="scss" scoped>

.main-Guide {
  padding: 50px 10px 10px;
  //line-height: 400px;
  text-align: center;

  .btn-box {
    padding: 10px 10px;
    text-align: center;

    .btn {
      background-color: rgb(0, 39, 225);
      width: 300px;
    }
  }


  .role {
    font-size: 24px;
    font-weight: bold;
    color: mediumseagreen;
  }
}

</style>
