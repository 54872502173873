<template>
  <div class="main-DataCustomer">
    <div class="h2 title">
      活动数据
    </div>

    <div class="total">

      <el-row class="total-cell table-box" v-loading="activeDataLoading"
              element-loading-background="rgba(0, 80, 80, 0.5)">
        <div class="cups">
          <div>
            <svg-icon class="icon" iconname="icon-youhuiquan5"></svg-icon>
          </div>
          <div class="title">历史活动</div>
          <div class="content">
            <ICountUp class="content total" :endVal="activeData.all_total" :options="options"/>
          </div>

        </div>
        <div class="cups">
          <div>
            <svg-icon class="icon" iconname="icon-youhuiquan5"></svg-icon>
          </div>
          <div class="title">进行中活动总数</div>
          <div class="content">
            <ICountUp class="content total" :endVal="activeData.on_total" :options="options"/>
          </div>

        </div>

        <div class="cups">
          <div>
            <svg-icon class="icon" iconname="icon-tuiquan"></svg-icon>
          </div>
          <div class="title">访问总人次</div>
          <div class="content">
            <ICountUp class="content bad" :endVal="activeData.people_total" :options="options"/>
          </div>

        </div>

      </el-row>


    </div>

    <div class="total">
      <div class="btns">
        <el-button-group>
          <el-button type="primary" size="mini" @click="daysChange(7)" :disabled="days===7">最近7日</el-button>
          <el-button type="primary" size="mini" @click="daysChange(15)" :disabled="days===15">最近15日</el-button>
          <el-button type="primary" size="mini" @click="daysChange(30)" :disabled="days===30">最近30日</el-button>
        </el-button-group>

        <el-select
                style="padding-left: 10px;"
                v-model="currentActive"
                size="small"
                clearable placeholder="请选择活动"
                @change="getActiveDetailData">
              <el-option
                  v-for="item in activeOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
      </div>


      <el-row class="total-cell table-box chart-box">
        <activeChart
            :activeChartData="activeChartData"
            :loading="activeChartLoading"
        >

        </activeChart>

      </el-row>


    </div>

  </div>
</template>


<script>

import {getActiveChartDataApi, getActiveDataApi} from "@/services/xingtuan";
import ICountUp from "vue-countup-v2";
import activeChart from "@/views/xingtuan/pages/index/components/activeChart";
import {getWeworkShop} from "@/services/weworkApi";
import {getActivityApi} from "@/services/client";

export default {
  name: "DataActive",
  components: {
    ICountUp,
    activeChart,

  },
  data() {
    return {
      options: {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      },
      days: 7,
      activeDataLoading: false,
      activeData: {
        all_total: 1000,
        on_total: 1000,
        people_total: 1000,
      },

      currentActive: null,
      activeOptions: [],

      activeChartLoading: false,
      activeChartData: [],
    }
  },
  computed: {},
  created() {

  },
  mounted() {
    this.getActiveOptions()
    this.getActiveData()
    this.getActiveDetailData()

  },
  methods: {
     getActiveOptions() {
      getActivityApi().then(res => {
        this.activeOptions = res.data.data
      })

    },
    processTimeStamp(t) {
      const date = new Date(t * 1000);  // 参数需要毫秒数，所以这里将秒数乘于 1000
      const Y = date.getFullYear() + '-';
      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
      const D = date.getDate() + ' ';
      return Y + M + D

    },
    daysChange(d) {
      this.days = d
      this.getActiveDetailData(false)
    },
    getActiveDetailData() {
      this.activeChartLoading = true
      getActiveChartDataApi({days: this.days, activity_id: this.currentActive}).then(res => {
        let _ = res.data.data
        let __ = []
        _.forEach(item => {
          __.push({
            'name': item.time,
            'value': item.value,

          })

        })
        console.log(__)
        this.activeChartData = __


      }).finally(e => {
        this.activeChartLoading = false
      })
    },
    getActiveData() {
      this.activeDataLoading = true
      getActiveDataApi().then(res => {
        this.activeData = res.data.data
      }).finally(e => {
        this.activeDataLoading = false
      })
    }
  },
}
</script>


<style lang="scss" scoped>
.total {
  text-align: center;
  padding-top: 20px;

  .total-cell {
    margin: 10px 10px 0;


    display: inline-block;
    width: calc(90% - 20px);

    .title {
      font-weight: bold;
      color: #00bc8c;
    }


  }


}

.icon {
  width: 40px;
  height: 40px;
}

.cups {
  display: inline-block;
  width: 160px;
  padding: 10px 10px;

  .title {
    //height: 30px;
    line-height: 30px;
    font-size: 16px;
    font-weight: bold;
    color: #00EAFF;
  }

  .content {
    font-size: 24px;
  }

  .total {
    color: #67C23A;
  }

  .new {
    color: #00bc8c;
  }

  .bad {
    color: #9a6e3a;
  }


}

.btns {
  padding: 0 10px 10px;
  text-align: left;
}

.detail {
  text-align: center;
  margin: 10px 10px;
  background-color: rgba(130, 0, 150, 0.2);
}


@media only screen and (max-width: 767px) {
  /deep/ .total {
    .total-cell {
      width: calc(100% - 20px);

    }
  }

}
</style>
