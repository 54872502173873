<template>
  <div class="main-ticketCell ticket-body"
       :style="'background-image: url('+imgUrl+ticketInfo.back_img+');'"

  >
    <div class="ticket-logo" v-if="ticketInfo.logo">

      <img :src="imgUrl+ticketInfo.logo" alt="ticket logo">
    </div>
    <div class="ticket-name" :style="'color:'+ ticketInfo.font_color">
      {{ ticketInfo.name }}
    </div>

    <el-tag class="ticket-tag" size="mini" type="success" color="rgba(100,90,80,0.2)">
      {{ ticketStatus }}
    </el-tag>

    <div class="tip" v-if="deadTime">
      有效期:
      <span v-if="activeTime">{{activeTime}} ~ </span>
      {{ deadTime }}</div>


  </div>
</template>


<script>

export default {
  name: "ticketCell",
  components: {},
  data() {
    return {
      imgUrl: 'https://img.xingtuangroup.com/'
    }
  },
  props: {

    ticketInfo: {
      type: Object,
      required: true
    },
    ticketStatus: {
      type: String,
      default: ''
    },
    activeTime: {
      type: String,
      default: ''
    },
   deadTime: {
      type: String,
      default: ''
    },

  },
  computed: {

  },
  created() {

  },
  mounted() {
    if (this.ticketInfo.active_count === 0 && !this.ticketInfo.status) {
      this.ticketInfo.status = '已领完'
    }

  },
  methods: {

  },
}
</script>


<style lang="scss" scoped>
.ticket-body {
  position: relative;
  padding: 5px 5px;
  text-align: left;
  color: #cccccc;
  display: inline-block;
  border-radius: 5px;
  width: 100%;
  max-width: 360px;

  height: 80px;
  cursor: pointer;

  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.8);
  //background-image: url('https://img.xingtuangroup.com/dianpu.png');
  //background-image: url('https://img.xingtuangroup.com/xiaogoukuanping-animal-tupian-85399.jpg');
  background-repeat: no-repeat;
  background-size: cover;

  .ticket-logo {
    float: left;
    display: inline-block;
    margin: 5px 10px;
    border: none;

    width: 60px;
    height: 60px;
    overflow: hidden;
    border-radius: 50%;


    img {
      width: 100%;
      height: 100%;
    }

  }

  .ticket-name {
    display: inline-block;
    //height: 80px;
    //line-height: 80px;
    padding-top: 24px;
    text-align: left;
    font-weight: bold;
    font-size: 16px;


  }

  .ticket-tag {
    position: absolute;
    left: calc(100% - 54px);
    top: 6px;
    border: none;
    color: #0d8092;
    font-size: 8px;
  }

   .tip {
     position: absolute;
     width: 300px;
     text-align: right;
     top: 56px;
     left:calc(100% - 310px);
      font-weight: normal;
      font-size: 8px;
      color: #aaa;
    }


}

</style>
