<template>
  <div class="main-ticketPay">
    <el-row class="price">
      <span>￥</span>
      <span>{{ ticketInfo.price }}</span>
      <span class="old-price">{{ ticketInfo.old_price }}</span>
    </el-row>
    <el-row class="name">
      <span>{{ ticketInfo.name }}</span>
    </el-row>
    <el-row class="count">
      <span>已售：</span>
      <span>{{ ticketInfo.get_count }}</span>

      <span class="yu">剩余：</span>
      <span v-if="ticketInfo.countall">不限量</span>
      <span v-else>{{ ticketInfo.active_count }}</span>
    </el-row>

    <el-button v-if="exploreType==='js' && !disabled"
               class="buy-btn" type="primary" size="mini" @click="buyThis">立即购买
    </el-button>
    <el-button v-if="exploreType!=='js'  && !disabled"
               style="left: calc(100% - 120px);"
               class="buy-btn"
               type="primary"
               size="mini">请使用微信打开
    </el-button>

    <!--    成功对话框-->
    <el-dialog
        title="支付成功"
        :visible.sync="successShow"
        width="90%"
        custom-class="ticket-dialog"
    >
      <span>支付成功,请前往个人中心查看!立即前往？</span>
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="successShow = false">取 消</el-button>
        <el-button type="primary" size="mini" @click="toIndex">确 定</el-button>
      </span>
    </el-dialog>

    <!--重复购买确认-->
    <el-dialog
        title="重复购买确认"
        :visible.sync="rebuyShow"
        width="90%"
        custom-class="ticket-dialog"
    >
      <span>您已经持有该卡券，确认重复购买？</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" v-if="!disabled" @click="rebuyClick()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>


<script>

import {checkOrderApi, createWxPay} from "@/services/wxApi";
import {getWxPayConfigApi} from "@/services/weworkApi";

export default {
  name: "ticketPay",
  components: {},
  data() {
    return {
      timer: null,
      payId: null,
      successShow: false,
      rebuyShow: false,
    }
  },
  props: {

    ticketInfo: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    wxUserInfo: {
      type: Object,
      required: true
    },


  },
  computed: {


    buyDesc() {
      return this.ticketInfo.corpname + '-' + this.ticketInfo.name
    },
    exploreType() {
      let ua = window.navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return 'js'
      } else {
        return null
      }
    }
  },
  created() {

  },
  mounted() {

    console.log(10)
    console.log(this.ticketInfo)
    this.weworkInit()


  },
  methods: {

    async weworkInit() {
      getWxPayConfigApi({url: window.location.href}).then(res => {
        this.config(res.data)
      })

    },
    async config(config) {

      return new Promise((resolve, reject) => {
        console.info('wx.config', config)
        wx.config(config)
        wx.ready(resolve)
        wx.error(reject)
      }).then(() => {
        console.info('wx.ready')

        this.checkJsApi()
      }, error => {

        console.error('wx.error', error)
        throw error
      })
    },
    checkJsApi() {
      wx.checkJsApi({
        jsApiList: ['chooseWXPay'], // 需要检测的JS接口列表，所有JS接口列表见附录2,
        success: function (res) {

          // 以键值对的形式返回，可用的api值true，不可用为false
          // 如：{"checkResult":{"chooseImage":true},"errMsg":"checkJsApi:ok"}
        },
        fail: function (res) {
          this.$message.warning('当前系统不支持支付')
        },
      });
    },
    buyThis() {
      if (!this.wxUserInfo) {
        this.$parent.wxProcess();
      } else {
        this.createJsPay({
          type: 'js',
          corpid: this.ticketInfo.corpid,
          payment: this.ticketInfo.price,
          open_id: this.wxUserInfo.openid,
          wx_name: this.wxUserInfo.nickname,
          desc: this.buyDesc,
          ticket_id: this.ticketInfo.id,

        })
      }
    },
    rebuyClick() {
      this.rebuyShow = false
      this.createJsPay({
        rebuy: true,
        type: 'js',
        corpid: this.ticketInfo.corpid,
        payment: this.ticketInfo.price,
        open_id: this.wxUserInfo.openid,
        wx_name: this.wxUserInfo.nickname,
        wx_header_img: this.wxUserInfo.headimgurl,
        desc: this.buyDesc,
        ticket_id: this.ticketInfo.id,

      })
    },
    createJsPay(payInfo) {
      let payJson = null
      createWxPay(payInfo).then(res => {
        if (res.data.data.appId === 'exist') {
          this.rebuyShow = true
        } else if (res.data.data.appId === 'expire') {
          this.$message.warning('活动已过期')
        } else {
          payJson = res.data.data

          if (typeof WeixinJSBridge == "undefined") {
            this.$message.warning('请重试')

            if (document.addEventListener) {
              document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
            } else if (document.attachEvent) {
              document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
              document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
            }
          } else {

            this.onBridgeReady(payJson);
          }
        }


      })


    },
    onBridgeReady(payJson) {
      this.payId = payJson.order_id
      this.timer = setInterval(this.checkPay, 3000)
      wx.chooseWXPay({
        'timestamp': payJson.timeStamp,
        'nonceStr': payJson.nonceStr,
        'package': payJson.package,
        'signType': payJson.signType,
        'paySign': payJson.paySign,
        success: function (res) {
          // 支付成功后的回调函数
          // alert('支付成功')
          clearInterval(this.timer)

        },
        fail: function (res) {
          // alert('支付失败')
          clearInterval(this.timer)

        },
        cancel: function (res) {
          // alert('取消支付')
          clearInterval(this.timer)

        },
      });


      // WeixinJSBridge.invoke(
      //     'getBrandWCPayRequest', {
      //       'appId': payJson.appId,
      //       'timeStamp': payJson.timeStamp,
      //       'nonceStr': payJson.nonceStr,
      //       'package': payJson.package,
      //       'signType': payJson.signType,
      //       'paySign': payJson.paySign
      //     },
      //     function (res) {
      //       if (res.err_msg == "get_brand_wcpay_request:ok") {
      //         // 使用以上方式判断前端返回,微信团队郑重提示：
      //         //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
      //         // window.location.href="/my.html"
      //         this.$message.success('支付成功')
      //         this.$router.push({name: 'client_home'})
      //
      //       } else if (res.err_msg == "get_brand_wcpay_request:cancel") {
      //         this.$message.warning('已取消')
      //       } else if (res.err_msg == "get_brand_wcpay_request:fail") {
      //         this.$message.warning('支付失败')
      //       }
      //
      //     });
    },
    checkPay() {

      checkOrderApi({order_id: this.payId}).then(res => {
        if (res.data.data === 'SUCCESS') {
          // this.$message.success('支付成功，请前往个人中心查看')
          this.successShow = true
          clearInterval(this.timer)
        } else if (res.data.data === 'USERPAYING') {

        } else if (res.data.data === 'NOTPAY') {

        } else {
          this.$message.warning('订单已取消')
          clearInterval(this.timer)
        }
      }).catch(err => {
        this.$message.warning('系统错误,若您已完成支付，请前往个人中心查看')
        clearInterval(this.timer)
      })

    },
    toIndex() {
      this.successShow = false
      this.$router.push({name: 'client_home'})

    }
  },
}
</script>


<style lang="scss" scoped>
.main-ticketPay {
  margin: 6px 0;
  display: inline-block;
  width: 100%;
  max-width: 360px;
  padding: 10px 10px 0;
  color: #cccccc;
  position: relative;

  .buy-btn {
    position: absolute;
    left: calc(100% - 90px);
    top: 50px;

  }


  .price {
    text-align: left;
    font-size: 18px;
    color: #00bcd4;
    font-weight: bold;
  }

  .old-price {
    color: #0d8092;
    font-weight: normal;
    display: inline-block;
    padding-left: 10px;
    font-size: 10px;
    text-decoration: line-through;
  }

  .name {
    text-align: left;
    font-size: 14px;
  }

  .count {
    padding-top: 4px;
    text-align: left;
    font-size: 10px;

    .yu {
      display: inline-block;
      padding-left: 16px;
    }

  }
}

/deep/ .ticket-dialog {

  border-radius: 10px;
  background-color: rgba(40, 30, 45, 0.9);

}
</style>
