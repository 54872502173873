<template>
  <div class="main-GroupWelcome">
    由于企微功能调整，入群欢迎语请前往企微后台设置

  </div>
</template>


<script>

export default {
  name: "GroupWelcome",
  components: {

  },
  data() {
    return {

    }
  },
  computed: {

  },
  created() {

  },
  mounted() {

  },
  methods: {

  },
}
</script>


<style lang="scss" scoped>
.main-GroupWelcome{
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  color: #00ff80;
}
</style>
