<template>
  <div class="main-IndexGuide main">
    <el-card class="main-cell table-box">
      <div class="main-title">平台使用指引</div>


      <el-collapse accordion>
        <el-collapse-item>
          <template slot="title">
            <div class="guide-title">
              开始使用 <i class="el-icon-warning-outline"></i>
            </div>
          </template>
          <div>建议先在企业微信（以下均简称企微）创建专门为星团搭建的部门和专门的星团服务客服账号，建议名称：星团服务部，并需要将该部门授权企微星团应用可见！</div>
          <div>再根据企业规模创建以总部或门店为单位的子部门，拉入对应人员，建议单个门店拉入的人员不超过3人，</div>
          <div>因为星团服务部门无需占用过多人员时间，避免不必要浪费。</div>
          <div>重要：在企微中将整个星团服务部门配置为授权客户联系权限人员和客服人员，并将星团部门成员分配给创建的星团客服账号接待人员。</div>
          <div>完成以上操作后才能完整正常使用星团各项功能！</div>
        </el-collapse-item>
        <el-collapse-item>
          <template slot="title">
            <div class="guide-title">
              开始使用 <i class="el-icon-warning-outline"></i>
            </div>
          </template>
          <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>
          <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>
        </el-collapse-item>
      </el-collapse>
    </el-card>

  </div>
</template>


<script>

export default {
  name: "IndexGuide",
  components: {},
  data() {
    return {}
  },
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {},
}
</script>


<style lang="scss" scoped>
/deep/ .guide-title {

  font-size: 18px;
  font-weight: bold;
  color: #00bc8c;
}

.main {


  .main-cell {
    text-align: left;
    height: 500px;
    overflow: auto;
    margin: 10px 10px 0;
    border: none;


    display: inline-block;
    width: calc(100% - 20px);

    .main-title {
      padding:10px 10px;
      text-align: center;
      font-size: 20px;
      font-weight: bold;
      color: #00bcd4;
    }
  }
}

/deep/ .el-descriptions-row .el-descriptions-item__cell {
  border: none !important;
}

/deep/ .el-collapse-item__content {
  padding: 10px 10px;
}
</style>
