<template>
  <div class="main-changePwd">
    <el-form :model="changePwdForm" ref="changePwdForm" :rules="rules" size="mini">
      <el-form-item label="手机号" type="Number" :label-width="formLabelWidth" prop="username">
        <el-input v-model="changePwdForm.username"
                  autocomplete="off"
                  :disabled="usernameDis"
        ></el-input>
        <div class="img-code">
          <vCode class="img-box" :changeCode.sync='identifyCode'></vCode>
          <el-input class="img-code-input" v-model="imgCodeInput"
                    autocomplete="off"
          ></el-input>

          <el-button
              class="img-btn"

              type="text"
              @click="sendVCode"
          >发送验证码
          </el-button>
        </div>
      </el-form-item>


      <el-form-item label="验证码" :label-width="formLabelWidth" prop="v_code" style="text-align: left;">
        <el-input v-model="changePwdForm.v_code" autocomplete="off"></el-input>

      </el-form-item>
      <el-form-item label="新密码" :label-width="formLabelWidth" prop="new_pwd">
        <el-input type="password" v-model="changePwdForm.new_pwd" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="确认密码" :label-width="formLabelWidth" prop="new_pwd2">
        <el-input type="password" v-model="changePwdForm.new_pwd2" autocomplete="off"></el-input>
      </el-form-item>

    </el-form>

    <el-button type="primary" size="mini" @click="submitForm('changePwdForm')">修改密码</el-button>


  </div>
</template>


<script>

import {mapActions, mapGetters} from "vuex";
import {changePwd, sendVCodeApi} from "@/services/baseApi";
import vCode from "@/views/public/vCode";

export default {
  name: "changePwd",
  components: {
    vCode
  },
  data() {
    const checkPhone = (rule, value, callback) => {
      if (value) {
        let isPhone = /^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/;
        let reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
        // if () {
        //   cbfn("");
        // }
        if (!reg.test(value) && !isPhone.test(value) && value) {
          callback(new Error("请输入正确的手机号"));
        } else {
          callback();
        }
      } else {
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        if (this.changePwdForm.new_pwd2 !== '') {
          this.$refs.changePwdForm.validateField('new_pwd2');
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.changePwdForm.new_pwd) {
        callback(new Error('两次输入密码不一致!'));
      } else {
        callback();
      }
    };


    return {
      imgCodeInput: '',
      identifyCode: '',    //当前生成的验证码
      formLabelWidth: '80px',
      changePwdForm: {
        username: '',
        v_code: '',
        new_pwd: '',
        new_pwd2: '',
      },
      rules: {
        username: [
          {validator: checkPhone, trigger: 'blur'},
          {required: true, message: '请输入手机号', trigger: 'blur'},
        ],
        v_code: [{required: true, min: 6, max: 6, message: '请输入正确的验证码', trigger: 'blur'}],
        new_pwd: [
          {validator: validatePass, trigger: 'blur'},
          {required: true, message: '请输入密码', trigger: 'blur'},
        ],
        new_pwd2: [
          {validator: validatePass2, trigger: 'blur'},
          {required: true, message: '请输入确认密码', trigger: 'blur'},
        ],
      }
    }
  },
  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
    usernameDis() {
      return !!this.userInfo;

    }
  },
  created() {

  },
  mounted() {
    if (this.userInfo) {
      this.changePwdForm.username = this.userInfo.username
    }

  },
  methods: {
    ...mapActions('user', ['logout', 'getUserInfo']),
    submitForm(formName) {
      console.log(this.changePwdForm)
      this.$refs[formName].validate((valid) => {
        if (valid) {
          changePwd(this.changePwdForm).then(res => {
            this.$message.success(res.data.data)
            this.logout()
            // this.$router.push('/login')
          }).catch(err => {
            this.$message.error(err.response.data.data)
          })
        } else {
          this.$message.warning('请检查输入项')
          return false;
        }
      });
    },
    sendVCode() {

      if (this.changePwdForm.username) {
        let isPhone = /^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$/;
        let reg = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
        if (!reg.test(this.changePwdForm.username) && !isPhone.test(this.changePwdForm.username) && this.changePwdForm.username) {
          this.$message.warning('请输入正确的手机号')
        } else {
          console.log(this.imgCodeInput.toLowerCase())
          console.log(this.identifyCode.toLowerCase())
          if (this.imgCodeInput.toLowerCase() === this.identifyCode.toLowerCase()) {
            // send
            sendVCodeApi({phone: this.changePwdForm.username}).then(res => {
              this.v_code = res.data.data
              this.$message.success('手机验证码已发送')
            }).catch(err => {
              this.$message.error(err.response.data.data)
            })
          } else {
            this.$message.warning('验证码错误')
          }

        }
      }

    }
  },
}
</script>


<style lang="scss" scoped>
.img-code {
  margin-top: 10px;

  .img-box {
    display: inline-block;
    width: 40%;
    float: left;
  }

  .img-code-input {
    display: inline-block;
    width: 60%;
    float: right;
  }


  .img-btn {
    width: 60%;
    float: right;
    text-align: right;
  }
}
</style>
