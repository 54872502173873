<template>
  <div class="main-CustomerWelcome">
    <el-button v-if="!newShow" type="primary" size="mini" @click="newShow=true">新建</el-button>
    <el-button v-if="newShow" type="primary" size="mini" @click="newShow=false">返回</el-button>
    <NewCustomerWelcome v-if="newShow" @backIndex="backIndex"></NewCustomerWelcome>
    <CustomerWelcomeTable v-if="!newShow"></CustomerWelcomeTable>


  </div>
</template>


<script>

import CustomerWelcomeTable from "@/views/xingtuan/pages/we/components/CustomerWelcomeTable";
import NewCustomerWelcome from "@/views/xingtuan/pages/we/components/NewCustomerWelcome";

export default {
  name: "CustomerWelcome",
  components: {
    NewCustomerWelcome,
    CustomerWelcomeTable

  },
  data() {
    return {
      newShow: false
    }
  },
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {
    backIndex() {
      this.newShow = false
    }
  },
}
</script>


<style lang="scss" scoped>
.main-CustomerWelcome {
  text-align: left;
  padding: 10px 10px;
}
</style>
