<template>
  <div class="main-clientActivePingShouQi">
    <!--    banner-->
    <headBanner :imgList="activityInfo.imgs"></headBanner>
    <!--    ticket-->
    <div v-if="prizeTickets.length>0">
      <!--      只展示中奖了的券-->
      <div class="name">您的奖品：</div>

      <div v-for="(item,index) in prizeTickets" :key="index">
        <div @click="ticketClick(item)">
          <ticketCell :ticketInfo="item"></ticketCell>
        </div>
        <ticketTip :ticketInfo="item"></ticketTip>
        <ticketPay v-if="item.pay" :ticketInfo="item" :wxUserInfo="wxUserInfo"></ticketPay>
        <el-divider></el-divider>
      </div>
    </div>

    <!--    参加按钮-->
    <el-row class="ticket-box">

      <div>
        <el-button :disable="joined" type="primary" size="mini" @click="pingClick">
          {{ !joined ? '试 试 手 气' : '您已参与过该活动' }}
        </el-button>
      </div>


    </el-row>
    <el-divider></el-divider>
    <!--    领取人-->
    <getUserList :userList="activeUsers"></getUserList>
    <el-divider></el-divider>
    <!--    活动详情-->
    <activeDes :content="activityInfo.desc"></activeDes>


    <el-dialog
        :title="currentTicket.ticket_name"
        :visible.sync="ticketDetailShow"
        width="98%"
        custom-class="ticket-dialog"
    >
      <ticketPreview :ticket-info="currentTicket"  :wxUserInfo="wxUserInfo"></ticketPreview>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="ticketDetailShow = false">关 闭</el-button>
      </span>
    </el-dialog>

  </div>
</template>


<script>

import headBanner from "@/views/xingtuan/clientPages/actives/components/headBanner";
import activeDes from "@/views/xingtuan/clientPages/actives/components/activeDes";
import getUserList from "@/views/xingtuan/clientPages/actives/components/getUserList";
import ticketCell from "@/views/xingtuan/clientPages/clientComponents/ticketCell";
import ticketTip from "@/views/xingtuan/clientPages/actives/components/ticketTip";
import ticketPay from "@/views/xingtuan/clientPages/actives/components/ticketPay";
import ticketPreview from "@/views/xingtuan/clientPages/actives/components/ticketPreview";
import {mapState} from "vuex";
import {getActiveWxUserApi, getWxActiveStatusApi, postWxActiveStatusApi} from "@/services/client";

export default {
  name: "clientActivePingShouQi",
  components: {
    headBanner,
    activeDes,
    getUserList,
    ticketCell,
    ticketTip,
    ticketPay,
    ticketPreview,
  },
  data() {
    return {
      joined: false,
      ticketShow: false,
      ticketDetailShow: false,
      currentTicket: {},
      prizeTickets: [],
      activeUsers: [],

    }
  },
  props: {
    activityInfo: {
      type: Object,
      required: true
    },
    wxUserInfo: {
      type: Object,
      required: true
    },
  },
  computed: {

  },
  created() {

  },
  mounted() {
    // 获取该用户参与信息
    this.getWxActiveStatus()
    this.getActiveWxUser()

  },
  methods: {
    getActiveWxUser(){
      getActiveWxUserApi({activity_id: this.activityInfo.id}).then(res=>{
        this.activeUsers = res.data.data
      })


    },

    wxProcess() {
      console.log('wxProcess')
      this.$parent.wxProcess();
    },
    pingClick() {

        postWxActiveStatusApi({wx_info: this.wxUserInfo, activity_id: this.activityInfo.id}).then(res => {
          if (res.data.data.msg === 'joined') {
            this.$message.warning('您已参与过该活动')
          } else if (res.data.data.msg === 'success') {
            this.$message.success('参与成功')
            this.getWxActiveStatus()
          } else {
            this.$message.warning('参与失败')
          }
        }).catch(err => {
          this.$message.warning('参与失败')
        })



    },
    getWxActiveStatus() {

        getWxActiveStatusApi({wx_id: this.wxUserInfo.openid, activity_id: this.activityInfo.id}).then(res => {

          if (res.data.data) {
            this.joined = true
            if (res.data.data.prize_ticket_info!=={}) {
              this.prizeTickets.push(res.data.data.prize_ticket_info)
            }

          }
        })


    },
    ticketClick(val) {
      console.log(val)
      this.ticketDetailShow = true
      this.currentTicket = val
    },
  },
}
</script>


<style lang="scss" scoped>
.ticket-box {
  padding-top: 10px;

}

.name {
  color: #ccc;
  text-align: left;
  font-size: 12px;
  padding-left: 10px;
}


/deep/ .ticket-dialog {

  border-radius: 10px;
  background-color: rgba(40, 30, 45, 0.9);

}
</style>
