
import getMeApi from '@/services/GetMe'
import {router} from '@/router'
import {loginWithUserName, loginWithWeworkQrCode} from "@/services/Login";


const user = {
    namespaced: 'true',
    state: {
        username: sessionStorage.getItem("username"),
        token: sessionStorage.getItem("access_token"),
        refresh_token: sessionStorage.getItem("refresh_token"),
        userInfo: undefined,
    },
    mutations: {
        SET_USER: (state, data) => {
            state.username = data.username;
        },
        SET_TOKEN: (state, data) => {
            state.token = data.token;
        },
        SET_REFRESH_TOKEN: (state, data) => {
            state.refresh_token = data.refresh_token;
        },
        SET_USERINFO: (state, data) => {
            state.userInfo = data;
        },
        LOGOUT: (state, data) => {
            state.token = undefined;
            state.userInfo = undefined;
            state.username = undefined;
            sessionStorage.clear();
            localStorage.clear();
        }
    },
    getters: {
        isSupervisor: state => state.userInfo.is_supervisor,
        hasAdminPermission: state => state.userInfo.permissions.indexOf("ADMINISTER") >= 0,
        hasApprovePermission: state => state.userInfo.permissions.indexOf("APPROVE_BASES_TEST") >= 0,
    },
    actions: {
        async login({state, commit}, form) {
            console.log("1")
            let success = false;
            // relogin if already logged
            if (state.token) {
                commit('LOGOUT', []);
                commit('permission/RESET_ROUTES', [], {root: true})
            }
            await loginWithUserName(form)
                .then(response => {
                    const res = response.data;
                    const username = res.username;
                    sessionStorage.setItem("username", username);
                    sessionStorage.setItem("access_token", res.access_token);
                    sessionStorage.setItem("refresh_token", res.refresh_token);
                    localStorage.setItem("username", username);
                    localStorage.setItem("access_token", res.access_token);
                    localStorage.setItem("refresh_token", res.refresh_token);
                    commit('SET_TOKEN', {
                        token: res.access_token,
                    });
                     commit('SET_REFRESH_TOKEN', {
                        token: res.refresh_token,
                    });
                    commit('SET_USER', {
                        username: username,
                    });
                    success = true;
                }).catch(error => {
                    alert('用户名或密码错误')
                    success = false;
                });
            return success;
        },
        async weworkQrCodeLogin({state, commit}, form) {
            console.log("1")
            let success = false;
            // relogin if already logged
            if (state.token) {
                commit('LOGOUT', []);
                commit('permission/RESET_ROUTES', [], {root: true})
            }
            await loginWithWeworkQrCode(form)
                .then(response => {
                    const res = response.data;
                    const username = res.username;
                    sessionStorage.setItem("username", username);
                    sessionStorage.setItem("access_token", res.access_token);
                    sessionStorage.setItem("refresh_token", res.refresh_token);
                    localStorage.setItem("username", username);
                    localStorage.setItem("access_token", res.access_token);
                    localStorage.setItem("refresh_token", res.refresh_token);
                    commit('SET_TOKEN', {
                        token: res.access_token,
                    });
                     commit('SET_REFRESH_TOKEN', {
                        token: res.refresh_token,
                    });
                    commit('SET_USER', {
                        username: username,
                    });
                    success = true;
                }).catch(error => {
                    alert('授权登录出错')
                    success = false;
                });
            return success;
        },
        async logout({commit, state}) {
            console.log('LOGOUT')
            router.push({path: '/login'}).then(() => {
                commit('LOGOUT', []);
                commit('permission/RESET_ROUTES', [], {root: true})
                // AuthenticationContext.logout();
            })

        },
        getUserInfo({commit, state, rootGetters, rootState}) {
             getMeApi().then(response => {
                const res = response.data;
                commit('SET_USERINFO', res.data);
                if (!res.data) {
                    sessionStorage.removeItem("username")
                    sessionStorage.removeItem("access_token")
                    sessionStorage.removeItem("refresh_token")
                    localStorage.removeItem("username")
                    localStorage.removeItem("access_token")
                    localStorage.removeItem("refresh_token")
                    router.push({path: '/login'})
                }

            }).catch(err => {
                sessionStorage.removeItem("username")
                sessionStorage.removeItem("access_token")
                sessionStorage.removeItem("refresh_token")
                localStorage.removeItem("username")
                localStorage.removeItem("access_token")
                localStorage.removeItem("refresh_token")
                router.push({path: '/login'})
            });
        },
        async getGlobalInfo({commit, state, rootGetters, rootState}) {
            if ($.isEmptyObject(state.userInfo)) {
                await getMeApi().then(response => {
                    const res = response.data;
                    commit('SET_USERINFO', res.data);
                    if (!res.data) {
                        sessionStorage.removeItem("username")
                        sessionStorage.removeItem("access_token")
                        sessionStorage.removeItem("refresh_token")
                        localStorage.removeItem("username")
                        localStorage.removeItem("access_token")
                        localStorage.removeItem("refresh_token")
                        router.push({path: '/login'})
                    }


                }).catch(err => {

                    sessionStorage.removeItem("access_token")

                    localStorage.removeItem("access_token")

                    router.push({path: '/login'})
                });

            }
            return state.userInfo
        }
    }
}

export default user
