<template>
  <div class="main-getUserList">
    <el-row class="ac">
      <div class="ac-title">{{ title }}：</div>


      <div class="ac-list cell-box" v-for="(item,index) in userList" :key="index">
        <div class="cell head-image">
          <img :src="item.wx_header_img" alt="">
        </div>
        <div class="cell name">
          <span>{{ item.wx_name }}</span>

        </div>
        <div class="cell ticket" v-if="item.prize_ticket_info">
          <span>{{ item.prize_ticket_info.name }}</span>
        </div>
        <div class="cell time" v-if="!item.prize_ticket_info">
          <span>{{ formatDate(item.created_at) }}</span>
        </div>

      </div>
    </el-row>

  </div>
</template>


<script>

import moment from "moment";

export default {
  name: "getUserList",
  components: {},
  data() {
    return {}
  },
  props: {
    title: {
      type: String,
      default: '已领取用户'
    },
    userList: {
      type: Array,
      default: () => {
        return [
          {
            wx_header_img: 'https://img.xingtuangroup.com/logo.png',
            wx_name: 'vseb eve阿萨法',
            prize_ticket_info: {name: '海底捞100元抵用券'},
            created_at: '2023-04-01 23:45',
          },
          {
            wx_header_img: 'https://img.xingtuangroup.com/logo.png',
            wx_name: 'vseb eve阿萨法',

            created_at: '2023-04-01 23:45',
          },
        ]
      }
    }
  },
  computed: {},
  created() {

  },
  mounted() {

  },
  methods: {
    formatDate(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss')
    },
  },
}
</script>


<style lang="scss" scoped>
.ac {
  color: #eeeeee;


  text-align: left;
  padding: 0 10px 10px;


  .ac-title {
    font-weight: bold;
    font-size: 18px;
  }

  .ac-list {
    font-size: 14px;
    //height: 40px;

    .cell {
      display: inline-block;
      height: 40px;
    }

    .head-image {
      float: left;
      padding: 5px 5px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .name {
      padding-left: 10px;
      line-height: 40px;
      font-size: 12px;
    }
    .ticket{
      float: right;
      font-size: 10px;
      color: #bbb;
      padding-right: 10px;
      line-height: 40px
    }

    .time {
      float: right;
      font-size: 10px;
      color: #bbb;
      padding-right: 10px;
      line-height: 40px
    }


  }

  .cell-box {
    padding-bottom: 6px;
    border-bottom: 1px solid rgba(100, 100, 100, 0.3);
  }
}
</style>
