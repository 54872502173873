<template>
  <div class="main-NewTicket">

    <!--    新增窗口-->
    <div class="new-box">

      <el-form class="new-form table-box" :rules="rules" ref="form" size="mini" :model="form" label-width="80px">
        <div class="title">新增卡券</div>
        <el-form-item label="卡券名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item label="卡券类型">
          <el-select v-model="form.type" placeholder="请选择卡券类型">
            <el-option v-for="item in ticketTypes" :key="item.value" :label="item.label"
                       :value="item.value"></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="字体颜色">
          <el-color-picker v-model="form.font_color" show-alpha></el-color-picker>
        </el-form-item>
        <el-form-item label="Logo图">
          <el-upload

              class="upload-demo"
              :action="file_url"
              :data="{prefix: 'diy_logo'}"
              :limit="1"
              :on-success="logoSuccess"
              :on-progress="onProgress"
              :on-error="errFunc"
              :file-list="logo_pics"
              :accept="accept_type.join(',')"
              :show-file-list="false"
              list-type="picture">
            <el-button size="mini" :loading="uploading" type="primary">点击上传</el-button>
          </el-upload>
          <div class="img-box"
               v-if="form.logo"
          >
            <el-image
                class="img-cell"
                :src="'https://img.xingtuangroup.com/'+form.logo"
                fit="contain">
            </el-image>

            <el-button
                type="text"
                size="mini"
                class="img-btn"
                icon="el-icon-delete"
                @click="removeLogoImage"
            ></el-button>
          </div>
        </el-form-item>

        <el-form-item label="背景图">
          <el-upload

              class="upload-demo"
              :action="file_url"
              :data="{prefix: 'ticket_img'}"
              :limit="1"
              :on-success="special_license_picSuccess"
              :on-progress="onProgress"
              :on-error="errFunc"
              :file-list="special_license_pics"
              :accept="accept_type.join(',')"
              :show-file-list="false"
              list-type="picture">
            <el-button size="mini" :loading="uploading" type="primary">点击上传</el-button>
          </el-upload>
          <div class="img-box"
               v-if="form.back_img"
          >
            <el-image
                class="img-cell"
                :src="'https://img.xingtuangroup.com/'+form.back_img"
                fit="contain">
            </el-image>

            <el-button
                type="text"
                size="mini"
                class="img-btn"
                icon="el-icon-delete"
                @click="removeImage"
            ></el-button>
          </div>
        </el-form-item>


        <el-form-item label="需要购买?">
          <el-switch
              v-model="form.pay"
              active-color="#13ce66"
              inactive-color="gray">
          </el-switch>
        </el-form-item>
        <el-form-item v-if="form.pay" label="价格" style="color: #606266; text-align: center;">
          <el-col :span="4">优惠价：</el-col>
          <el-col :span="8">
            <el-input type="number" v-model="form.price" placeholder="请输入价格"></el-input>
          </el-col>
          <el-col :span="4">原价：</el-col>
          <el-col :span="8">
            <el-input type="number" v-model="form.old_price" placeholder="请输入原价"></el-input>
          </el-col>


        </el-form-item>


        <el-form-item label="是否适用所有门店">
          <el-switch
              v-model="form.shopall"
              active-color="#13ce66"
              inactive-color="gray">
          </el-switch>

          <div v-if="!form.shopall" style="color: #606266;">
            选择适用门店
            <el-checkbox-group v-model="checkCorpShops">
              <el-checkbox v-for="item in corpShops" :key="item.id" :label="item.id">{{ item.label }}</el-checkbox>
            </el-checkbox-group>
          </div>


        </el-form-item>
        <el-form-item label="发行数量">
          <el-switch
              v-model="form.countall"
              active-color="#13ce66"
              inactive-color="gray"
              active-text="无限制">
          </el-switch>
          <el-input v-if="!form.countall" v-model="form.count" type="number"></el-input>
        </el-form-item>
        <el-form-item label="单人最多可领取数">
          <el-input v-model="form.limit" type="number"></el-input>
        </el-form-item>

        <el-form-item label="有效期">
          <el-switch
              v-model="form.active_type"
              active-color="#13ce66"
              inactive-color="gray"
              active-text="领取后有效"
              inactive-text="指定时间段有效">
          </el-switch>
          <div v-if="form.active_type" style="color: #606266;">

            <el-radio-group v-model="form.active_time_default" @change="form.active_time=0">
              <el-radio :label="-1">长期</el-radio>
              <el-radio :label="-2">一年</el-radio>
              <el-radio :label="-3">半年</el-radio>
              <el-radio :label="-4">三个月</el-radio>
              <el-radio :label="-5">一个月</el-radio>
            </el-radio-group>
            <div>
              有效天数：
              <el-input style="display: inline-block;width: 160px" v-model="form.active_time" type="number"
                        placeholder="或者请输入有效天数"
                        @change="form.active_time_default=0"></el-input>
              天
            </div>

          </div>
          <div v-if="!form.active_type" style="color: #606266;">
            <div>
              <el-col :span="11">
                <el-date-picker
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择生效日期"
                    v-model="form.live_time"
                    style="width: 100%;"></el-date-picker>
              </el-col>
              <el-col class="line" :span="2">-</el-col>
              <el-col :span="11">
                <el-date-picker
                    type="date"
                    value-format="yyyy-MM-dd"
                    placeholder="选择失效日期"
                    v-model="form.dead_time"
                    style="width: 100%;"></el-date-picker>
              </el-col>
            </div>

          </div>

        </el-form-item>
        <el-form-item label="预览">

          <ticketCell :ticket-info="form"></ticketCell>
        </el-form-item>

        <el-form-item label="卡券描述">
          <FroalaEditor v-model="form.desc" @change="changetext"></FroalaEditor>
        </el-form-item>


        <el-form-item>
          <el-button type="primary" @click="onSubmit()">立即创建</el-button>
          <el-button>取消</el-button>
        </el-form-item>
      </el-form>
    </div>


  </div>
</template>


<script>

import FroalaEditor from "@/views/xingtuan/components/FroalaEditor";
import {mapGetters, mapState} from "vuex";
import {postCompanyLogo, postTicketApi} from "@/services/xingtuan";
import common from "@/views/xingtuan/pages/coupon/components/common";
import ticketCell from "@/views/xingtuan/clientPages/clientComponents/ticketCell";

export default {
  name: "NewTicket",
  components: {
    FroalaEditor,
    ticketCell
  },
  data() {
    return {
      uploading: false,
      accept_type: ['.jpg', '.png', '.bmp', '.gif', '.jpeg'],
      special_license_pics: [],
      logo_pics: [],
      common: common,
      editor: {
        p:
            '<p data-f-id="pbf" style="text-align: center; font-size: 14px; margin-top: 30px; opacity: 0.65; font-family: sans-serif;">Powered by <a href="https://www.froala.com/wysiwyg-editor?pb=1" title="Froala Editor">Froala Editor</a></p>',
      },
      form: {
        corpid: '',
        name: '在这里输入卡券名称',
        type: 'zhekou',
        pay: false,
        price: 0,
        old_price: 0,
        shopall: true,
        countall: true,
        count: 0,
        limit: 1,
        active_type: true,
        active_time_default: -1,
        active_time: null,
        live_time: null,
        dead_time: null,
        desc: '',
        font_color: '',
        logo: '',
        back_img: 'activity_img/1678193869_wallhaven-618958.jpg',
        shops: null,
        status: '已领取',
        time: '2022-01-01',
      },
      ticketTypes: common.ticketTypes,
      checkCorpShops: [],
      corpShops: [
        {label: '一店', id: 1},
      ],

      rules: {
        name: [
          {required: true, message: '请输入卡券名称', trigger: 'blur'},
          {min: 3, max: 30, message: '长度在 3 到 30 个字符', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '请选择卡券类型', trigger: 'blur'}
        ],
        shopall: [
          {required: true, message: '请选择是都使用所有门店', trigger: 'blur'}
        ],
        countall: [
          {required: true, message: '请选择是否使用所有门店', trigger: 'blur'}
        ],
        active_type: [
          {required: true, message: '请选择是否领取后有效', trigger: 'blur'}
        ],
        desc: [
          {required: true, message: '请填写卡券描述', trigger: 'blur'}
        ],

      }

    }
  },
  computed: {
    ...mapGetters([
      'userInfo', 'isSupervisor'
    ]),
    ...mapState("companys", ["companyInfo"]),
    file_url() {
      return process.env.VUE_APP_API_HOST + '/api/v1/oss_upload'
    },
    shopCheck() {
      if (!this.form.shopall) {
        return this.checkCorpShops.length > 0;
      } else {
        return true
      }

    },
    activeCheck() {
      if (!this.form.active_type) {
        return !!(this.form.live_time && this.form.dead_time);
      } else {
        return true
      }
    }

  },
  created() {

  },
  mounted() {

    this.form.logo = this.companyInfo.diy_logo

  },
  methods: {
    errFunc() {
      this.$message.error('上传错误');
      this.uploading = false
    },
    onProgress() {
      this.uploading = true
    },
    removeImage() {
      this.form.back_img = ''
    },
    removeLogoImage() {
      this.form.logo = ''
    },

    logoSuccess(res, file) {
      this.form.logo = res.data.key
      postCompanyLogo({logo: res.data.key, corpid: this.userInfo.wework_corpid})

      this.uploading = false
    },
    special_license_picSuccess(res, file) {
      this.form.back_img = res.data.key
      this.uploading = false

    },
    newTicket() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.form.shops = this.checkCorpShops
          postTicketApi(this.form).then(res => {
            this.$message.success('创建成功')
          }).catch(err => {
            this.$message.success('创建失败')
          })

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    onSubmit() {
      console.log('submit!');
      console.log(this.form)
      this.form.corpid = this.userInfo.wework_corpid

      // 验证

      if (this.shopCheck && this.activeCheck) {
        this.newTicket()
      } else {
        if (!this.shopCheck) {
          this.$message.error('请补全门店适用信息')
        } else if (!this.activeCheck) {
          this.$message.error('请补全有效日期信息')
        }

      }


    },

    changetext(val) {
      console.log(val)
      // if (val.seriesDetail.indexOf(this.editor.p) > -1) {
      //   val.seriesDetail = val.seriesDetail.replace(
      //       this.editor.p,
      //       ""
      //   );
      // }
      console.log(val.content)
      this.form.desc = val.content
    }

  },
}
</script>


<style lang="scss" scoped>

.new-box {
  width: 100%;
  padding: 10px 10px;


  .new-form {
    text-align: left;

    padding: 10px 10px;
    //border: 1px solid rgba(100, 10, 120, 0.8);
    max-width: 900px;

    .title {
      padding: 10px 10px;
      font-weight: bold;
      font-size: 20px;
    }
  }


}

.img-box {
  position: relative;
  display: inline-block;
  width: 120px;
  height: 120px;
  padding: 2px 2px;
  text-align: center;

  .img-cell {
    width: 100%;
    height: 100%;


  }

  .img-btn {
    position: absolute;
    left: calc(100% - 18px);
    top: calc(100% - 28px);

  }
}
</style>
